import React from 'react'

const MobDispensaryIcon = () => {
    return (
        <>
            <svg width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3883 1.21311C13.3202 1.05405 13.1688 0.95 12.9999 0.95C12.8311 0.95 12.6797 1.05406 12.6115 1.21312C11.5379 3.71659 10.9708 6.62507 10.9708 9.62418C10.9708 12.588 11.525 15.4631 12.5742 17.9464V24.6044C12.5742 24.848 12.7624 25.05 13 25.05C13.2375 25.05 13.4258 24.8481 13.4258 24.6044V17.9464C14.475 15.4631 15.0291 12.588 15.0291 9.62413C15.029 6.62502 14.462 3.71654 13.3883 1.21311ZM14.1775 9.62413C14.1775 12.1112 13.7721 14.5287 12.9999 16.6817C12.2277 14.5287 11.8224 12.1112 11.8224 9.62413C11.8224 7.1371 12.2277 4.71959 12.9999 2.56657C13.7722 4.71959 14.1775 7.13715 14.1775 9.62413Z" fill="white" stroke="white" strokeWidth="0.1" />
                <path d="M0.956272 17.6834L0.956272 17.6834C0.985366 17.5078 1.11233 17.3656 1.28078 17.3252C3.01095 16.9108 4.93731 16.874 6.85174 17.2181C8.76711 17.5622 10.557 18.2672 12.0279 19.2576M0.956272 17.6834L12.0279 19.2576M0.956272 17.6834C0.927206 17.859 1.00078 18.037 1.14544 18.1343C2.61635 19.1247 4.40625 19.8297 6.32162 20.1739C7.1881 20.3296 8.05689 20.4073 8.90882 20.4073C9.9393 20.4073 10.9454 20.2936 11.8925 20.0667C12.061 20.0264 12.1879 19.8842 12.217 19.7085M0.956272 17.6834L12.217 19.7085M12.0279 19.2576C12.1726 19.355 12.2462 19.533 12.217 19.7085M12.0279 19.2576L12.217 19.7085M6.46582 19.2956C5.07857 19.0463 3.76477 18.5981 2.60695 17.9838C3.91688 17.8091 5.31389 17.846 6.70768 18.0964C8.09488 18.3457 9.40872 18.7939 10.5665 19.4083C9.25648 19.5829 7.85957 19.5461 6.46582 19.2956Z" fill="white" stroke="white" strokeWidth="0.1" />
                <path d="M12.1819 21.0773L12.1819 21.0772C12.1192 20.9393 11.9928 20.8435 11.846 20.8258L11.846 20.8258C10.7354 20.692 9.61964 20.8698 8.61953 21.3403C7.61316 21.8137 6.78547 22.5541 6.2263 23.4819C6.14842 23.6111 6.13925 23.773 6.20179 23.9109L6.2018 23.9109C6.26447 24.0489 6.39089 24.1447 6.53774 24.1624L6.53775 24.1624C6.7777 24.1912 7.01787 24.2056 7.25711 24.2056C8.12505 24.2056 8.9802 24.0166 9.76417 23.6479C10.7705 23.1745 11.5982 22.434 12.1574 21.5062L12.1574 21.5062C12.2353 21.377 12.2445 21.2151 12.1819 21.0773ZM9.41499 22.835C8.78974 23.1292 8.11099 23.2903 7.41869 23.3119C7.8407 22.8309 8.36616 22.4367 8.96875 22.1532C9.59401 21.8591 10.2728 21.6979 10.965 21.6763C10.543 22.1573 10.0176 22.5515 9.41499 22.835Z" fill="white" stroke="white" strokeWidth="0.1" />
                <path d="M12.1579 18.1619L12.206 18.1483C11.5142 15.7071 10.26 13.2786 8.57949 11.1248C6.89838 8.97023 4.8917 7.22023 2.77562 6.06457C2.6231 5.98122 2.43804 6.00227 2.30628 6.11703L12.1579 18.1619ZM12.1579 18.1619L12.206 18.1483C12.2542 18.3184 12.2015 18.5032 12.0702 18.6175C12.0702 18.6175 12.0702 18.6175 12.0701 18.6175L12.0373 18.5798L12.1579 18.1619ZM6.45656 13.0461C5.09789 11.3049 4.03145 9.37993 3.33514 7.42267C4.99703 8.49631 6.56648 9.95391 7.91986 11.6884C9.27853 13.4297 10.3449 15.3546 11.0413 17.3119C9.37936 16.2383 7.80995 14.7806 6.45656 13.0461Z" fill="white" stroke="white" strokeWidth="0.1" />
                <path d="M25.0437 17.6844L25.0437 17.6844C25.0727 17.8599 24.9992 18.0379 24.8544 18.1353C23.3835 19.1257 21.5936 19.8307 19.6783 20.1749C18.8119 20.3306 17.9429 20.4083 17.0911 20.4083C16.0605 20.4083 15.0547 20.2946 14.1074 20.0677C14.1074 20.0677 14.1074 20.0677 14.1074 20.0677L14.119 20.019L25.0437 17.6844ZM25.0437 17.6844C25.0145 17.5087 24.8876 17.3665 24.7191 17.3262L25.0437 17.6844ZM19.5343 19.2965C18.1405 19.547 16.7435 19.5839 15.4336 19.4092C16.5914 18.7948 17.9051 18.3466 19.2924 18.0973C20.6861 17.8468 22.0831 17.81 23.3931 17.9846C22.2352 18.5991 20.9215 19.0472 19.5343 19.2965Z" fill="white" stroke="white" strokeWidth="0.1" />
                <path d="M17.3802 21.3403C18.3866 21.8137 19.2143 22.5542 19.7734 23.4819C19.8514 23.6112 19.8605 23.773 19.7979 23.9109C19.7352 24.0489 19.6088 24.1447 19.4619 24.1624M17.3802 21.3403L13.8423 21.5062C14.4015 22.434 15.2292 23.1744 16.2355 23.6479C17.0196 24.0167 17.8745 24.2057 18.7426 24.2056C18.9817 24.2056 19.222 24.1912 19.4619 24.1624M17.3802 21.3403L17.3589 21.3855M17.3802 21.3403C16.3801 20.8699 15.2645 20.692 14.1537 20.8258C14.1537 20.8258 14.1537 20.8258 14.1537 20.8258L19.7306 23.5077M17.3802 21.3403C17.3802 21.3403 17.3802 21.3403 17.3802 21.3403L17.3589 21.3855M17.3802 21.3403L17.3589 21.3855M19.4619 24.1624L19.456 24.1127M19.4619 24.1624C19.462 24.1624 19.462 24.1624 19.462 24.1624L19.456 24.1127M19.456 24.1127C19.5846 24.0973 19.6965 24.0132 19.7524 23.8902C19.8082 23.7673 19.8 23.6228 19.7306 23.5077M19.456 24.1127C19.218 24.1414 18.9797 24.1556 18.7426 24.1556C17.8818 24.1557 17.0341 23.9683 16.2568 23.6026L19.7306 23.5077M19.7306 23.5077C19.1767 22.5887 18.3566 21.8548 17.3589 21.3855M13.8178 21.0773C13.7552 21.2152 13.7644 21.377 13.8423 21.5062L13.8178 21.0773ZM16.5848 22.8349C15.9822 22.5514 15.4568 22.1573 15.0347 21.6762C15.727 21.6979 16.4058 21.859 17.031 22.1531C17.6336 22.4366 18.1591 22.8308 18.5811 23.3119C17.8889 23.2902 17.21 23.129 16.5848 22.8349Z" fill="white" stroke="white" strokeWidth="0.1" />
                <path d="M13.7942 18.1483L13.7942 18.1483L14.3994 18.6701C14.3994 18.6701 14.3994 18.6701 14.3994 18.6701C16.5155 17.5145 18.5221 15.7645 20.2033 13.6099C21.8838 11.456 23.138 9.02758 23.8298 6.58633L23.8298 6.58632C23.878 6.41615 23.8253 6.23137 23.6939 6.11701L23.6939 6.11701C23.5622 6.00232 23.3771 5.9812 23.2246 6.06456V6.06455C21.1085 7.22021 19.1019 8.97016 17.4207 11.1248C15.7402 13.2786 14.486 15.707 13.7942 18.1483ZM19.5436 13.0461C18.1902 14.7806 16.6208 16.2383 14.9589 17.3118C15.6552 15.3545 16.7217 13.4296 18.0803 11.6883C19.4338 9.9538 21.0032 8.49615 22.6651 7.4226C21.9687 9.37991 20.9023 11.3048 19.5436 13.0461Z" fill="white" stroke="white" strokeWidth="0.1" />
            </svg>
        </>
    )
}

export default MobDispensaryIcon