import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Rating from "react-rating";
import { PostReview } from "../../Api";
import { toast } from "react-toastify";
import ImageDisplay from "../Social App/ImageDisplay";
import ratingEmpty from "../../assets/Images/ratingEmpty.svg";
import ratingFull from "../../assets/Images/ratingFull.svg";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";

const RatingModal = ({ show, handleClose, checkUserReview, type, GetData }) => {
  const routeParams = useParams();
  // console.log(routeParams.id)

  const [ratingData, setRatingData] = useState({
    ProductId: routeParams.id,
    LoginUserId: JSON.parse(localStorage.getItem("userdata"))._id,
    rating: 0,
    review: "",
    type,
  });

  const ratingHandler = (rating) => {
    setRatingData((prevState) => ({
      ...prevState,
      rating: rating,
    }));
  };

  const submitHandler = () => {
    if (ratingData.rating === 0) {
      return toast.error("Rating is required");
    }
    const data = {
      ProductId: routeParams.id,
      LoginUserId: ratingData.LoginUserId,
      rating: ratingData.rating,
      review: ratingData.review,
      type,
    };

    const currentUser = localStorage.getItem("userdata");
    let userData = JSON.parse(currentUser);
    let GetUserItemUrl = `${process.env.REACT_APP_API_URI}${type}/${routeParams.id}?latlang=${userData.location?.coordinates[0]},${userData.location?.coordinates[1]}`;
    PostReview(data, handleClose, checkUserReview, GetUserItemUrl, GetData);
    // GetData(GetUserItemUrl);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="d-flex align-items-center justify-content-center w-100"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Review</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="gap-2">
          <div className="d-flex align-items-center">
            <p className="fw-bold me-3">Rating</p>
            <Rating
              start={0}
              stop={5}
              step={1}
              direction="ltr"
              readonly={false}
              initialRating={ratingData.rating}
              emptySymbol={<img src={ratingEmpty} alt="" className="me-2" />}
              fullSymbol={<img src={ratingFull} alt="" className="me-2" />}
              onChange={ratingHandler}
            />
          </div>
          <div>
            <Form.Group
              className="my-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Review"
                onChange={(e) =>
                  setRatingData((prev) => ({
                    ...prev,
                    review: e.target.value,
                  }))
                }
              />
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="success" className="ms-3" onClick={submitHandler}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RatingModal;
