import React from 'react'

const CalendarIcon = () => {
    return (
        <>
            <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M6.60098 0C7.20849 0 7.70098 0.488417 7.70098 1.09091V4.36364C7.70098 4.96613 7.20849 5.45455 6.60098 5.45455C5.99346 5.45455 5.50098 4.96613 5.50098 4.36364V1.09091C5.50098 0.488417 5.99346 0 6.60098 0Z" fill="#5D8B2F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M15.3998 0C16.0073 0 16.4998 0.488417 16.4998 1.09091V4.36364C16.4998 4.96613 16.0073 5.45455 15.3998 5.45455C14.7923 5.45455 14.2998 4.96613 14.2998 4.36364V1.09091C14.2998 0.488417 14.7923 0 15.3998 0Z" fill="#5D8B2F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0.549805 8.82528C0.549805 8.22279 1.04229 7.73438 1.6498 7.73438H20.3498C20.9573 7.73438 21.4498 8.22279 21.4498 8.82528C21.4498 9.42778 20.9573 9.91619 20.3498 9.91619H1.6498C1.04229 9.91619 0.549805 9.42778 0.549805 8.82528Z" fill="#5D8B2F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M3.21232 4.96994C2.5705 5.65542 2.2 6.71304 2.2 8.18217V17.4549C2.2 18.924 2.5705 19.9817 3.21232 20.6671C3.84044 21.338 4.88466 21.8185 6.6 21.8185H15.4C17.1153 21.8185 18.1596 21.338 18.7877 20.6671C19.4295 19.9817 19.8 18.924 19.8 17.4549V8.18217C19.8 6.71304 19.4295 5.65542 18.7877 4.96994C18.1596 4.2991 17.1153 3.81854 15.4 3.81854H6.6C4.88466 3.81854 3.84044 4.2991 3.21232 4.96994ZM1.60018 3.48532C2.75956 2.24707 4.46534 1.63672 6.6 1.63672H15.4C17.5347 1.63672 19.2404 2.24707 20.3998 3.48532C21.5455 4.70893 22 6.37858 22 8.18217V17.4549C22 19.2585 21.5455 20.9281 20.3998 22.1518C19.2404 23.39 17.5347 24.0004 15.4 24.0004H6.6C4.46534 24.0004 2.75956 23.39 1.60018 22.1518C0.454496 20.9281 0 19.2585 0 17.4549V8.18217C0 6.37858 0.454496 4.70893 1.60018 3.48532Z" fill="#5D8B2F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.61914 13.6371C9.61914 12.884 10.2347 12.2734 10.9941 12.2734H11.004C11.7634 12.2734 12.379 12.884 12.379 13.6371C12.379 14.3902 11.7634 15.0007 11.004 15.0007H10.9941C10.2347 15.0007 9.61914 14.3902 9.61914 13.6371Z" fill="#5D8B2F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M5.54883 13.6371C5.54883 12.884 6.16444 12.2734 6.92383 12.2734H6.93371C7.6931 12.2734 8.30871 12.884 8.30871 13.6371C8.30871 14.3902 7.6931 15.0007 6.93371 15.0007H6.92383C6.16444 15.0007 5.54883 14.3902 5.54883 13.6371Z" fill="#5D8B2F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M5.54883 18.0004C5.54883 17.2472 6.16444 16.6367 6.92383 16.6367H6.93371C7.6931 16.6367 8.30871 17.2472 8.30871 18.0004C8.30871 18.7535 7.6931 19.364 6.93371 19.364H6.92383C6.16444 19.364 5.54883 18.7535 5.54883 18.0004Z" fill="#5D8B2F" />
            </svg></>
    )
}

export default CalendarIcon