import React, { useEffect } from "react";
import PanicSection from "../../Components/Dashboard Components/PanicSection";
import AllProductsRetailer from "./AllProductRetailer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserById } from "../../Api";

const AllDataRetailer = () => {

  const navigate = useNavigate();

  useEffect(() => {
    const currentUser = localStorage.getItem("userdata");
    let data = JSON.parse(currentUser);
    if (data && !data.verified) {
      localStorage.clear();
      navigate('/');
      navigate(-1);
      toast.error('Email not verified! Check your email');
    }
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("userdata");
    const data = JSON.parse(user);
    if (!data?.location?.address) {
      navigate("/address");
    }
}, [])

  return (
    <div>
      <PanicSection />
      <AllProductsRetailer />
    </div>
  );
};

export default AllDataRetailer;
