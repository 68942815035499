import React from "react";

const EmptyDataImageUser = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={248}
        height={200}
        viewBox="0 0 248 200"
        fill="none"
      >
        <path
          d="M57.8274 19.0656L0 57.6733L56.9597 142.658L114.787 104.05L57.8274 19.0656Z"
          fill="#F5F5F5"
        />
        <path
          d="M84.9083 100.024L84.2486 104.077L55.5 99.9465L56.1597 95.8931L84.9083 100.024Z"
          fill="#E0E0E0"
        />
        <path
          d="M70.427 85.564L74.5553 86.1555L69.9786 114.406L65.8555 113.814L70.427 85.564Z"
          fill="#E0E0E0"
        />
        <path
          d="M43.9506 49.6599C43.9919 49.7216 38.1989 53.6619 31.0091 58.4611C23.8194 63.2604 17.9594 67.1029 17.9182 67.0412C17.877 66.9795 23.67 63.0392 30.8597 58.24C38.0494 53.4407 43.9094 49.5982 43.9506 49.6599Z"
          fill="#E0E0E0"
        />
        <path
          d="M65.2147 44.3308C65.2559 44.3925 55.6181 50.8996 43.6919 58.8572C31.7606 66.82 22.0609 73.2241 22.0197 73.1624C21.9784 73.1007 31.6111 66.5988 43.5424 58.6309C55.4686 50.6681 65.1734 44.2639 65.2147 44.3256V44.3308Z"
          fill="#E0E0E0"
        />
        <path
          d="M69.4803 50.6941C69.5215 50.7558 59.8837 57.2628 47.9575 65.2205C36.0262 73.1833 26.3265 79.5874 26.2853 79.5257C26.2441 79.464 35.8767 72.9621 47.8081 64.9941C59.7342 57.0314 69.4391 50.6272 69.4803 50.6889V50.6941Z"
          fill="#E0E0E0"
        />
        <path
          d="M73.742 57.052C73.7832 57.1137 64.1454 63.6207 52.2192 71.5784C40.2879 79.5412 30.5882 85.9453 30.547 85.8836C30.5058 85.8219 40.1385 79.32 52.0698 71.3521C63.9959 63.3893 73.7008 56.9851 73.742 57.0468V57.052Z"
          fill="#E0E0E0"
        />
        <path
          d="M78.0076 63.4143C78.0489 63.476 68.411 69.9831 56.4849 77.9407C44.5535 85.9035 34.8539 92.3076 34.8126 92.2459C34.7714 92.1842 44.4041 85.6823 56.3354 77.7144C68.2616 69.7516 77.9664 63.3474 78.0076 63.4091V63.4143Z"
          fill="#E0E0E0"
        />
        <path
          d="M248.002 51.6256L202.293 15.2988L148.7 82.4713L194.409 118.798L248.002 51.6256Z"
          fill="#F5F5F5"
        />
        <path
          d="M190.028 93.9029L186.611 94.4173L182.586 70.4055L186.003 69.8911L190.028 93.9029Z"
          fill="#E0E0E0"
        />
        <path
          d="M197.92 78.6514L198.503 82.0978L174.687 85.6574L174.109 82.211L197.92 78.6514Z"
          fill="#E0E0E0"
        />
        <path
          d="M219.943 48.338C219.897 48.3946 215.253 44.7836 209.568 40.2672C203.884 35.7509 199.317 32.0421 199.364 31.9855C199.41 31.9289 204.054 35.54 209.738 40.0563C215.418 44.5727 219.99 48.2814 219.943 48.338Z"
          fill="#E0E0E0"
        />
        <path
          d="M229.648 63.9597C229.601 64.0163 221.922 57.9928 212.495 50.4981C203.064 43.0034 195.462 36.8822 195.508 36.8256C195.554 36.769 203.234 42.7925 212.665 50.2872C222.092 57.7767 229.699 63.898 229.653 63.9597H229.648Z"
          fill="#E0E0E0"
        />
        <path
          d="M225.636 68.9905C225.589 69.0471 217.91 63.0236 208.484 55.5289C199.052 48.0342 191.45 41.9129 191.496 41.8564C191.543 41.7998 199.222 47.8233 208.654 55.318C218.08 62.8075 225.687 68.9288 225.641 68.9854L225.636 68.9905Z"
          fill="#E0E0E0"
        />
        <path
          d="M221.624 74.0159C221.578 74.0725 213.898 68.0489 204.472 60.5543C195.04 53.0596 187.438 46.9383 187.485 46.8817C187.531 46.8252 195.21 52.8487 204.642 60.3434C214.069 67.8329 221.676 73.9542 221.629 74.0159H221.624Z"
          fill="#E0E0E0"
        />
        <path
          d="M217.612 79.0466C217.566 79.1032 209.887 73.0797 200.46 65.585C191.029 58.0903 183.426 51.9691 183.473 51.9125C183.519 51.8559 191.199 57.8794 200.63 65.3741C210.057 72.8637 217.664 78.9849 217.618 79.0466H217.612Z"
          fill="#E0E0E0"
        />
        <path
          d="M154.933 5.9621e-05L94.9375 3.45264L100.031 91.622L160.026 88.1695L154.933 5.9621e-05Z"
          fill="#F5F5F5"
        />
        <path
          d="M139.552 72.0975L137.284 74.8289L117.699 59.1811L119.967 56.4497L139.552 72.0975Z"
          fill="#E0E0E0"
        />
        <path
          d="M135.123 54.999L137.932 57.2469L122.13 76.2742L119.316 74.0315L135.123 54.999Z"
          fill="#E0E0E0"
        />
        <path
          d="M131.171 16.6977C131.171 16.7852 125.135 17.207 117.678 17.6339C110.22 18.066 104.169 18.3386 104.164 18.2512C104.164 18.1638 110.199 17.742 117.662 17.315C125.12 16.8881 131.171 16.6103 131.176 16.6977H131.171Z"
          fill="#E0E0E0"
        />
        <path
          d="M149.339 22.0265C149.339 22.1139 139.315 22.762 126.946 23.477C114.566 24.192 104.531 24.6961 104.531 24.6087C104.531 24.5213 114.55 23.8731 126.93 23.1581C139.305 22.4431 149.339 21.939 149.345 22.0265H149.339Z"
          fill="#E0E0E0"
        />
        <path
          d="M149.722 28.6266C149.722 28.714 139.698 29.3621 127.328 30.0771C114.949 30.7921 104.919 31.2962 104.914 31.2088C104.914 31.1214 114.933 30.4732 127.313 29.7582C139.688 29.0432 149.722 28.5391 149.727 28.6266H149.722Z"
          fill="#E0E0E0"
        />
        <path
          d="M150.105 35.2257C150.105 35.3131 140.081 35.9613 127.711 36.6763C115.332 37.3913 105.297 37.8954 105.297 37.8079C105.297 37.7205 115.316 37.0723 127.696 36.3573C140.07 35.6423 150.105 35.1382 150.11 35.2257H150.105Z"
          fill="#E0E0E0"
        />
        <path
          d="M150.488 41.8253C150.488 41.9127 140.463 42.5609 128.094 43.2759C115.714 43.9909 105.685 44.495 105.68 44.4075C105.68 44.3201 115.699 43.6719 128.079 42.9569C140.453 42.2419 150.488 41.7378 150.493 41.8253H150.488Z"
          fill="#E0E0E0"
        />
        <path
          d="M241.428 182.415C241.428 182.487 188.925 182.549 124.176 182.549C59.4273 182.549 6.91406 182.487 6.91406 182.415C6.91406 182.343 59.4067 182.281 124.176 182.281C188.945 182.281 241.428 182.343 241.428 182.415Z"
          fill="#263238"
        />
        <path
          d="M98.5793 76.8199L80.355 170.336L77.9688 182.579L199.632 181.982C205.09 181.956 209.93 177.116 211.687 169.93L233.261 81.6397C234.596 76.182 231.669 70.5083 227.525 70.5083L104.388 70.6369C101.666 70.6369 99.2905 73.1728 98.5793 76.8199Z"
          fill="#707070"
        />
        <g opacity="0.5">
          <path
            d="M98.5793 76.8199L80.355 170.336L77.9688 182.579L199.632 181.982C205.09 181.956 209.93 177.116 211.687 169.93L233.261 81.6397C234.596 76.182 231.669 70.5083 227.525 70.5083L104.388 70.6369C101.666 70.6369 99.2905 73.1728 98.5793 76.8199Z"
            fill="black"
          />
        </g>
        <path
          d="M187.965 163.999L175.085 60.3955C174.56 56.1518 171.168 52.9832 167.169 52.9986L138.92 53.0912C136.828 53.0963 134.823 53.9862 133.339 55.5603L119.928 69.7883L41.367 70.1998C36.5687 70.2256 32.8785 74.7573 33.5124 79.855L45.4747 175.609C46.0055 179.847 49.3865 183.011 53.3808 183.001L190.295 182.733C205.545 183.047 206.999 178.556 206.999 178.556C189.522 182.419 187.965 164.015 187.965 164.004V163.999Z"
          fill="#707070"
        />
        <g opacity="0.5">
          <path
            d="M128.93 143.263L124.312 148.212L88.75 116.284L93.3679 111.33L128.93 143.263Z"
            fill="white"
          />
          <path
            d="M122.371 110.235L127.479 114.818L95.2977 149.313L90.1953 144.73L122.371 110.235Z"
            fill="white"
          />
        </g>
      </svg>
    </>
  );
};

export default EmptyDataImageUser;
