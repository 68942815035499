import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
function SocialTerms() {
    const [terms] = useState(false);
    const [privacy] = useState(false);

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    const [userToken, setuserToken] = useState();
    useEffect(() => {
        const currentUser = localStorage.getItem("user-token-signup");
        setuserToken(currentUser);
    }, []);

    const submitHandler = (e) => {
        e.preventDefault();
        if (terms && privacy) {
            localStorage.setItem("user-token", userToken);
            navigate("/home");
        }
        if (terms && !privacy) {
        }
        if (!terms && privacy) {
        }
    };
    return (
        <div className="max-width-792">
            <h2 className="auth-model-heading mb-4 pb-3">
                Smokin'Singles Terms & Conditions
            </h2>
            <form onSubmit={(e) => submitHandler(e)}>
                <p className="text-grey font-16 text-justify">
                    <strong className="text-white"> User Conduct Protocols</strong> <br />
                    <br /><strong>1.1 Conduct Expectations</strong> <br />
                    Users are mandated to adhere to a defined set of behavioral protocols designed to foster a congenial and respectful community environment. This includes the prohibition of dissemination of obscene content, utilization of discriminatory language, or facilitation of unlawful engagements through the platform.
                    <br /><strong>1.2 Consequences of Violations</strong> <br />
                    Any breach of the established norms may result in punitive measures including, but not limited to, account suspension or termination, and legal actions where necessary.
                    <br /><strong className="text-white"> Intellectual Proprietorship & Data Ownership</strong> <br />
                    <br /><strong>2.1 Intellectual Assets</strong> <br />
                    All content, data, and intellectual assets created, distributed, or displayed within the platform's ecosystem are the exclusive property of The Entity. The Entity reserves all rights pertaining to these assets, guarding against unauthorized usage.
                    <br /><strong>2.2 Anti-Piracy</strong> <br />
                    Any unauthorized dissemination, reproduction, or misappropriation of intellectual property belonging to The Entity will be pursued rigorously through legal channels, ensuring the safeguarding of proprietary rights.
                    <br /><strong className="text-white">Profile Elimination and Data Retention</strong> <br />
                    <br /><strong>3.1 Termination Procedures</strong> <br />
                    Upon receipt of a termination request, The Entity initiates a structured data eradication procedure aimed at removing all user-specific data within a one-month timeframe. This period may be subject to modifications in light of administrative or logistical considerations.
                    <br /><strong>3.2 Data Retention</strong> <br />
                    The Entity reserves the right to retain specific data fragments for analytical, operational, or legal compliance purposes, adhering to the governing legal stipulations and industry best practices.
                </p>
                <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-5">
                    <button
                        className="green-btn-outline custom-w min-width-208"
                        onClick={() => goBack()}
                    >
                        Back
                    </button>
                </div>
            </form>
            <p className="text-center text-grey mt-5 pt-sm-0 pt-3 font-16">
                <Link className="font-16 text-grey" to="/social/socialterms">Terms of use</Link> | <Link className="font-16 text-grey" to="/social/socialpolicy">Privacy Policy</Link>
            </p>
        </div>
    )
}

export default SocialTerms
