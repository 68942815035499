import React from 'react'

const MailIcon = () => {
    return (
        <>
            <svg width={16} height={14} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M12 14H4C1.6 14 0 12.7647 0 9.88235V4.11765C0 1.23529 1.6 0 4 0H12C14.4 0 16 1.23529 16 4.11765V9.88235C16 12.7647 14.4 14 12 14Z" fill="white" />
                <path d="M8.0006 8.07224C7.3286 8.07224 6.64861 7.84825 6.12861 7.39163L3.6246 5.23778C3.3686 5.01378 3.32061 4.60886 3.52861 4.33317C3.73661 4.05747 4.11261 4.00579 4.36861 4.22979L6.8726 6.38363C7.4806 6.90917 8.5126 6.90917 9.1206 6.38363L11.6246 4.22979C11.8806 4.00579 12.2646 4.04886 12.4646 4.33317C12.6726 4.60886 12.6326 5.0224 12.3686 5.23778L9.8646 7.39163C9.3526 7.84825 8.6726 8.07224 8.0006 8.07224Z" fill="white" />
            </svg>
        </>
    )
}

export default MailIcon