import React from 'react'

const MobSettingsIcon = () => {
    return (
        <>
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M20.8067 7.62337L20.1842 6.54328C19.6577 5.62936 18.4907 5.31408 17.5755 5.83847V5.83847C17.1399 6.0951 16.6201 6.16791 16.1307 6.04084C15.6413 5.91378 15.2226 5.59727 14.9668 5.16113C14.8023 4.8839 14.7139 4.56815 14.7105 4.2458V4.2458C14.7254 3.72898 14.5304 3.22816 14.17 2.85743C13.8096 2.48669 13.3145 2.27762 12.7975 2.27783H11.5435C11.037 2.27783 10.5513 2.47967 10.194 2.8387C9.83669 3.19773 9.63717 3.68435 9.63961 4.19088V4.19088C9.6246 5.23668 8.77248 6.07657 7.72657 6.07646C7.40421 6.07311 7.08846 5.9847 6.81123 5.82017V5.82017C5.89606 5.29577 4.72911 5.61105 4.20254 6.52497L3.53435 7.62337C3.00841 8.53615 3.3194 9.70236 4.23 10.2321V10.2321C4.8219 10.5738 5.18653 11.2053 5.18653 11.8888C5.18653 12.5723 4.8219 13.2038 4.23 13.5456V13.5456C3.32056 14.0717 3.00923 15.2351 3.53435 16.1451V16.1451L4.16593 17.2344C4.41265 17.6795 4.8266 18.0081 5.31619 18.1472C5.80578 18.2863 6.33064 18.2247 6.77462 17.9758V17.9758C7.21108 17.7211 7.73119 17.6513 8.21934 17.782C8.70749 17.9126 9.12324 18.2328 9.37416 18.6714C9.5387 18.9486 9.62711 19.2644 9.63046 19.5868V19.5868C9.63046 20.6433 10.487 21.4998 11.5435 21.4998H12.7975C13.8505 21.4998 14.7055 20.6489 14.7105 19.5959V19.5959C14.7081 19.0878 14.9089 18.5998 15.2682 18.2405C15.6275 17.8812 16.1155 17.6804 16.6236 17.6829C16.9452 17.6915 17.2596 17.7795 17.5389 17.9392V17.9392C18.4517 18.4651 19.6179 18.1541 20.1476 17.2435V17.2435L20.8067 16.1451C21.0618 15.7073 21.1318 15.1858 21.0012 14.6961C20.8706 14.2065 20.5502 13.7891 20.111 13.5364V13.5364C19.6718 13.2837 19.3514 12.8663 19.2208 12.3767C19.0902 11.8871 19.1603 11.3656 19.4154 10.9277C19.5812 10.6381 19.8214 10.398 20.111 10.2321V10.2321C21.0161 9.70265 21.3264 8.54325 20.8067 7.63252V7.63252V7.62337Z" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                <circle cx="12.1751" cy="11.8886" r="2.63616" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}

export default MobSettingsIcon