import React from "react";

const DispensaryFlower = () => {
  return (
    <>
      <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M15.289 6.34812C15.289 7.66308 14.9028 8.86924 14.2381 9.87587C13.2681 11.3269 11.7323 12.3516 9.94496 12.6146C9.63959 12.669 9.32524 12.6962 9.0019 12.6962C8.67857 12.6962 8.36421 12.669 8.05884 12.6146C6.27152 12.3516 4.73568 11.3269 3.76568 9.87587C3.10105 8.86924 2.71484 7.66308 2.71484 6.34812C2.71484 2.83852 5.52606 0 9.0019 0C12.4777 0 15.289 2.83852 15.289 6.34812Z"
          fill="#5D8B2F"
        />
        <path
          d="M17.3079 14.9358L15.8259 15.2895C15.4936 15.3711 15.2332 15.625 15.1613 15.9606L14.847 17.2937C14.6763 18.0192 13.7602 18.2368 13.2842 17.6655L9 12.6958L4.71582 17.6746C4.2398 18.2459 3.32369 18.0282 3.15304 17.3027L2.83868 15.9696C2.75785 15.6341 2.49739 15.3711 2.17405 15.2986L0.692103 14.9449C0.00950828 14.7817 -0.232993 13.9201 0.260991 13.4213L3.76378 9.88452C4.73378 11.3355 6.26962 12.3603 8.05694 12.6233C8.36231 12.6777 8.67667 12.7049 9 12.7049C9.32333 12.7049 9.63769 12.6777 9.94306 12.6233C11.7304 12.3603 13.2662 11.3355 14.2362 9.88452L17.739 13.4213C18.233 13.911 17.9905 14.7726 17.3079 14.9358Z"
          fill="#5D8B2F"
        />
        <path
          d="M9.51731 3.60962L10.0472 4.67972C10.1191 4.82482 10.3077 4.96993 10.4783 4.99713L11.4394 5.16036C12.0501 5.26012 12.1938 5.71356 11.7537 6.15793L11.0082 6.91062C10.8825 7.03758 10.8107 7.28245 10.8556 7.46383L11.0711 8.39791C11.2418 9.13248 10.8556 9.42266 10.2089 9.0327L9.31074 8.49765C9.14907 8.39789 8.87963 8.39789 8.71796 8.49765L7.81981 9.0327C7.17314 9.41359 6.78693 9.13248 6.95758 8.39791L7.17314 7.46383C7.20907 7.29152 7.1462 7.03758 7.02045 6.91062L6.27499 6.15793C5.83489 5.71356 5.9786 5.26919 6.58934 5.16036L7.55036 4.99713C7.71203 4.96993 7.90064 4.82482 7.97249 4.67972L8.5024 3.60962C8.76287 3.02922 9.22991 3.02922 9.51731 3.60962Z"
          fill="#5D8B2F"
        />
      </svg>
    </>
  );
};

export default DispensaryFlower;
