import React from "react";

const RejectIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 44 44"
        enableBackground="new 0 0 44 44"
        width={44}
        height={44}
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#5D8B2F"
            d="M25.748,9.077h-7.495c-0.879,0-2.119,0.517-2.74,1.137   l-5.298,5.298c-0.62,0.62-1.137,1.861-1.137,2.74v7.495c0,0.879,0.517,2.119,1.137,2.74l5.298,5.298   c0.62,0.62,1.861,1.137,2.74,1.137h7.495c0.879,0,2.119-0.517,2.74-1.137l5.298-5.298c0.62-0.62,1.137-1.861,1.137-2.74v-7.495   c0-0.879-0.517-2.119-1.137-2.74l-5.298-5.298C27.867,9.594,26.626,9.077,25.748,9.077z M27.439,16.565   c0.505,0.505,0.505,1.323,0,1.827l-3.609,3.609l3.609,3.609c0.505,0.505,0.505,1.323,0,1.827c-0.505,0.505-1.323,0.505-1.827,0   l-3.609-3.609l-3.609,3.609c-0.505,0.505-1.323,0.505-1.827,0c-0.505-0.505-0.505-1.323,0-1.827l3.609-3.609l-3.609-3.609   c-0.505-0.505-0.505-1.323,0-1.827c0.505-0.505,1.323-0.505,1.827,0l3.609,3.609l3.609-3.609   C26.116,16.06,26.934,16.06,27.439,16.565z"
          />
          <circle
            fill="none"
            stroke="#5D8B2F"
            strokeWidth={2}
            cx={22}
            cy={22}
            r={21}
          />
        </g>
      </svg>
    </>
  );
};

export default RejectIcon;
