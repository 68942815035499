import React from "react";

const SmokinSingles = () => {
  return (
    <>
      <svg
        width={30}
        height={30}
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1474.000000 1102.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <metadata>
          Created by potrace 1.16, written by Peter Selinger 2001-2019
        </metadata>
        <g
          transform="translate(0.000000,1102.000000) scale(0.100000,-0.100000)"
          fill="#ffffff"
          stroke="none"
        >
          <path
            d="M6182 10802 c-18 -20 -37 -55 -42 -78 -6 -24 -33 -87 -61 -141 -40
-78 -66 -114 -127 -173 -42 -42 -86 -87 -97 -101 -11 -15 -35 -34 -52 -43 -18
-9 -40 -29 -50 -44 -9 -15 -28 -40 -42 -55 -14 -15 -32 -46 -41 -69 -11 -32
-22 -43 -44 -49 -37 -10 -46 -24 -46 -72 0 -56 -15 -77 -54 -77 -40 0 -50 -23
-41 -98 3 -26 -1 -62 -8 -82 -12 -33 -13 -34 -72 -36 -59 -1 -61 0 -88 37 -32
44 -34 54 -46 221 -10 135 -20 168 -50 168 -29 0 -39 -85 -51 -435 -11 -298
-12 -311 -32 -328 -14 -12 -29 -16 -42 -12 -12 4 -41 10 -66 15 -24 4 -46 13
-48 19 -10 30 -55 69 -91 80 -23 6 -53 23 -68 37 -16 14 -40 32 -55 40 -51 27
-158 131 -253 246 -91 111 -135 153 -135 130 0 -10 7 -27 15 -38 8 -10 15 -26
15 -34 0 -12 82 -191 126 -275 9 -16 22 -59 29 -95 7 -36 21 -90 31 -120 9
-30 17 -85 18 -122 1 -50 6 -73 22 -95 30 -42 34 -58 18 -82 -14 -19 -13 -27
6 -78 11 -31 35 -70 51 -87 48 -48 53 -62 29 -86 -11 -11 -20 -31 -20 -44 0
-35 88 -149 170 -219 17 -15 19 -21 9 -33 -6 -8 -17 -14 -23 -14 -7 0 -20 -6
-30 -13 -18 -13 -17 -15 7 -53 14 -21 52 -68 86 -103 57 -61 92 -86 184 -127
21 -10 36 -22 34 -28 -2 -7 -57 -15 -135 -20 l-131 -9 -36 32 c-20 17 -42 31
-49 31 -14 0 -36 -49 -36 -79 0 -9 -7 -29 -16 -46 -9 -16 -23 -42 -31 -57 -23
-44 -46 -35 -76 30 -14 31 -28 73 -32 93 -4 25 -20 50 -55 85 -27 27 -50 53
-50 58 0 34 -111 160 -188 215 -18 13 -44 41 -58 62 -22 35 -54 53 -54 30 0
-5 -7 -12 -16 -15 -23 -9 -72 42 -85 87 -19 72 -103 177 -152 193 -16 5 -28
-1 -47 -25 -15 -17 -30 -31 -34 -31 -4 0 -13 15 -21 34 -23 54 -59 103 -105
141 -50 42 -86 45 -102 10 -6 -14 -15 -25 -20 -25 -5 0 -43 33 -84 74 -88 85
-115 97 -150 65 l-23 -22 -41 27 c-22 14 -40 30 -40 35 0 13 -68 51 -92 51
-11 0 -38 16 -59 35 -22 19 -45 35 -52 35 -8 0 -38 24 -69 53 -30 28 -73 68
-94 88 -38 35 -270 278 -296 310 -7 8 -26 23 -42 33 -36 23 -57 7 -27 -20 11
-11 24 -31 30 -46 5 -14 27 -50 48 -79 41 -56 92 -141 169 -279 26 -47 55 -96
66 -109 10 -13 18 -32 18 -42 0 -9 11 -30 24 -46 13 -15 27 -44 31 -63 4 -19
16 -45 28 -57 17 -19 19 -27 9 -48 -7 -14 -12 -35 -12 -47 0 -24 80 -160 118
-200 21 -23 23 -30 12 -43 -7 -8 -20 -15 -29 -15 -10 0 -25 -6 -34 -13 -16
-11 -17 -15 -2 -44 17 -34 102 -123 172 -180 24 -20 43 -41 43 -48 0 -8 -29
-27 -65 -44 -36 -17 -65 -37 -65 -45 0 -8 15 -32 32 -54 18 -22 45 -56 60 -75
15 -19 64 -56 108 -83 l82 -48 -39 -32 c-21 -18 -52 -35 -69 -38 -17 -4 -39
-12 -49 -20 -16 -12 -17 -17 -6 -45 8 -17 17 -31 22 -31 4 0 34 -21 64 -46 66
-53 159 -110 197 -119 29 -7 44 -30 26 -41 -6 -4 -29 -12 -51 -19 -23 -7 -42
-16 -44 -22 -5 -13 41 -59 103 -103 27 -19 65 -50 84 -68 42 -40 135 -97 173
-106 26 -6 36 -29 18 -41 -6 -3 -36 -10 -68 -16 -101 -18 -133 -57 -70 -84 17
-8 55 -30 82 -50 103 -73 153 -103 184 -110 63 -14 80 -63 36 -105 -43 -40
-32 -67 37 -89 20 -7 49 -21 63 -33 17 -13 50 -23 93 -28 81 -10 92 -14 116
-47 l19 -26 -43 -36 c-24 -20 -60 -41 -79 -46 -48 -14 -45 -27 9 -45 40 -13
101 -48 207 -118 21 -13 62 -26 106 -32 122 -17 127 -22 72 -64 -37 -29 -67
-41 -128 -51 -21 -3 -41 -13 -44 -21 -8 -22 25 -53 71 -65 23 -5 52 -18 66
-28 14 -10 59 -28 100 -40 89 -27 100 -35 100 -71 0 -42 -22 -55 -69 -41 -50
14 -114 14 -129 -1 -7 -7 -12 -22 -12 -35 0 -42 -71 -59 -91 -22 -28 53 -94
110 -168 145 -42 20 -93 44 -113 55 -53 27 -64 24 -81 -23 -33 -93 -44 -119
-55 -130 -7 -7 -30 9 -79 58 -77 77 -138 124 -162 124 -9 0 -31 9 -49 20 -18
11 -44 20 -57 20 -27 0 -30 -7 -40 -104 -9 -90 -17 -94 -82 -39 -56 48 -87 61
-242 104 -85 23 -86 22 -96 -56 -13 -105 -29 -114 -112 -59 -32 21 -73 43 -93
50 -38 14 -158 18 -177 6 -7 -4 -13 -36 -15 -72 -3 -60 -5 -65 -27 -68 -12 -2
-49 6 -80 18 -42 15 -75 20 -121 18 l-64 -3 -9 -45 c-11 -59 -30 -79 -57 -62
-11 7 -54 12 -100 12 -83 0 -84 0 -122 -52 -11 -14 -21 -15 -55 -9 -29 6 -53
4 -80 -6 -72 -27 -128 -32 -413 -33 -372 -2 -458 -16 -235 -39 50 -5 107 -17
127 -25 20 -9 79 -22 130 -30 51 -8 111 -21 133 -29 22 -8 59 -18 82 -22 47
-7 153 -43 198 -67 17 -8 55 -21 85 -28 30 -6 63 -14 72 -16 9 -3 25 -20 36
-38 17 -28 32 -37 103 -59 46 -14 101 -29 122 -33 52 -9 67 -26 60 -68 -5 -34
-5 -34 46 -45 28 -6 62 -17 74 -26 12 -8 58 -18 101 -22 100 -10 112 -24 72
-82 -36 -52 -26 -66 52 -76 31 -4 64 -13 72 -20 8 -7 31 -18 51 -24 39 -13 64
-50 54 -82 -8 -25 -41 -24 -84 2 -24 15 -56 21 -120 25 -47 2 -110 6 -138 9
-52 4 -53 3 -53 -24 0 -16 7 -34 15 -41 17 -14 30 -89 20 -119 -8 -27 -43 -27
-90 0 -34 20 -46 22 -162 16 -69 -3 -130 -10 -135 -15 -6 -6 -4 -22 7 -42 9
-17 15 -43 13 -58 l-3 -27 -121 -6 c-151 -8 -164 -12 -164 -49 0 -16 -4 -40
-10 -54 -9 -26 -11 -26 -80 -20 -83 8 -135 -10 -161 -55 -15 -26 -22 -29 -93
-33 -66 -4 -81 -9 -105 -32 -16 -16 -44 -29 -67 -32 -21 -4 -57 -12 -79 -19
-22 -7 -94 -16 -160 -20 -135 -8 -161 -16 -145 -44 7 -14 33 -23 97 -35 49 -9
102 -16 119 -16 17 0 48 -7 67 -15 20 -8 56 -15 79 -15 24 0 72 -9 106 -20 35
-11 81 -20 101 -20 30 0 43 -7 69 -36 l32 -36 107 3 c98 4 107 2 113 -16 4
-11 6 -32 3 -47 -4 -19 0 -29 15 -37 25 -13 191 -14 246 -2 68 16 85 -5 61
-76 -11 -31 -12 -49 -5 -58 16 -20 185 -17 250 5 30 10 74 21 97 25 39 6 44 5
52 -17 9 -24 -3 -72 -24 -98 -19 -22 -27 -57 -20 -80 6 -19 15 -20 133 -20
120 0 130 2 191 30 35 17 77 42 94 56 39 33 71 29 75 -10 3 -19 -4 -37 -20
-55 -46 -49 -60 -79 -48 -101 6 -11 15 -20 20 -20 5 0 15 -18 21 -40 10 -33 9
-43 -5 -65 -19 -29 -28 -31 -59 -11 -28 19 -146 46 -197 46 -44 0 -50 -10 -50
-80 0 -62 -15 -75 -67 -60 -21 6 -69 10 -108 8 l-70 -3 -14 -44 c-15 -48 -6
-46 -119 -25 -21 4 -46 1 -70 -10 -31 -14 -49 -15 -112 -7 -96 13 -241 45
-266 60 -21 12 -125 49 -164 58 l-25 6 25 -27 c14 -15 30 -32 35 -37 16 -15
146 -99 179 -116 17 -9 52 -35 78 -59 26 -24 56 -44 66 -44 22 0 47 -25 61
-62 10 -26 91 -73 165 -95 31 -10 42 -23 50 -63 8 -35 100 -79 183 -87 83 -8
92 -20 59 -79 -15 -26 -23 -51 -18 -56 4 -4 46 -13 93 -18 46 -6 92 -13 102
-16 26 -8 22 -56 -8 -84 -29 -28 -32 -48 -7 -57 29 -10 41 -24 35 -39 -9 -23
-31 -16 -47 15 -19 36 -52 48 -153 55 -92 6 -103 -1 -103 -61 0 -43 -16 -54
-55 -37 -19 7 -65 17 -102 20 -67 6 -70 6 -86 -20 -20 -30 -45 -33 -90 -10
-17 9 -49 18 -72 19 -96 7 -141 21 -252 77 -65 32 -122 63 -128 69 -12 11 -65
12 -65 0 0 -12 226 -223 256 -239 13 -7 31 -26 39 -42 9 -16 33 -39 55 -50 31
-16 42 -29 51 -61 13 -43 41 -68 106 -94 50 -20 55 -30 37 -80 -20 -59 -18
-63 36 -79 63 -19 130 -56 130 -72 0 -18 -68 -74 -91 -74 -11 0 -41 9 -66 21
-46 21 -46 21 -117 2 -60 -16 -72 -23 -74 -42 -2 -13 1 -28 7 -34 12 -12 14
-58 5 -106 -9 -42 -46 -52 -78 -22 -12 11 -29 21 -37 21 -8 0 -30 11 -47 24
-27 19 -50 25 -128 31 -109 8 -124 2 -124 -54 0 -66 -40 -79 -105 -36 -41 29
-105 41 -160 31 -29 -6 -39 -14 -51 -42 -16 -38 -26 -41 -78 -19 -20 9 -60 16
-89 16 -29 0 -127 0 -218 0 -117 0 -183 4 -220 14 -30 8 -95 22 -145 30 -98
15 -167 44 -199 82 -17 21 -28 24 -70 21 -41 -2 -50 -6 -50 -22 0 -15 20 -27
84 -53 46 -18 95 -42 110 -51 14 -10 58 -33 96 -52 39 -19 120 -62 180 -96 61
-34 127 -69 148 -77 21 -9 49 -30 64 -47 14 -17 46 -42 72 -54 59 -31 101 -70
101 -95 0 -11 11 -28 24 -39 34 -27 123 -69 176 -84 37 -11 46 -17 48 -40 2
-15 -4 -38 -13 -51 -10 -16 -12 -28 -6 -31 59 -37 138 -70 198 -83 94 -21 106
-38 68 -93 -34 -50 -30 -73 15 -81 19 -3 55 -16 80 -29 32 -16 68 -25 122 -28
100 -6 108 -16 63 -75 -19 -25 -35 -50 -35 -56 0 -15 32 -22 150 -31 101 -7
131 -5 197 15 37 12 47 -24 22 -81 -18 -44 -19 -49 -4 -60 9 -7 56 -12 110
-13 73 0 108 4 150 20 70 26 99 23 103 -13 2 -15 -4 -41 -14 -59 -26 -51 -4
-61 126 -57 93 3 110 7 152 32 47 27 98 32 105 10 2 -6 -17 -33 -42 -59 -65
-70 -62 -74 39 -54 52 11 63 10 142 -16 l85 -29 29 32 c38 42 63 40 109 -8 34
-35 43 -40 82 -39 83 2 101 11 126 58 13 25 26 47 28 50 3 3 36 8 72 12 53 5
70 11 85 30 21 28 98 177 98 191 0 10 102 228 121 258 5 8 18 32 29 52 54 101
154 216 220 256 49 29 142 67 205 84 59 15 294 3 395 -21 47 -11 123 -23 170
-26 47 -4 114 -15 150 -25 36 -10 106 -21 155 -25 50 -4 119 -17 155 -28 56
-18 91 -21 245 -21 168 -1 183 1 223 21 23 13 53 23 65 23 42 0 206 90 271
149 15 14 50 59 77 99 66 98 103 239 96 364 -5 89 -29 188 -64 259 -12 24 -29
72 -38 105 -17 60 -17 63 5 130 27 87 74 132 203 197 151 75 182 124 165 257
-6 51 -10 58 -64 105 -32 28 -83 74 -113 102 -35 33 -74 59 -104 69 -75 25
-87 77 -21 90 44 8 109 -2 139 -22 29 -19 126 -29 189 -20 87 13 170 120 182
233 8 78 -6 120 -62 182 -24 27 -44 52 -44 56 0 4 -11 20 -25 37 -22 27 -25
38 -25 116 0 58 5 95 15 112 8 14 23 43 34 65 27 55 82 96 171 127 68 24 92
27 251 30 168 4 178 5 232 32 68 33 143 114 158 171 6 22 9 78 6 126 -4 79
-10 100 -44 164 -6 11 -16 31 -22 45 -15 32 -55 103 -118 210 -66 112 -151
257 -174 298 -11 18 -27 45 -36 59 -24 37 -51 83 -73 128 -11 22 -33 61 -48
87 -15 26 -55 98 -90 160 -35 62 -68 122 -75 133 -6 11 -33 62 -59 114 -27 52
-53 100 -58 106 -5 6 -14 32 -19 57 -6 26 -20 63 -32 83 -31 52 -23 175 17
260 50 108 76 136 255 277 105 83 141 124 187 213 78 152 77 149 76 380 0 172
-3 219 -17 260 -10 28 -29 120 -42 205 -23 144 -29 163 -79 265 -29 61 -59
134 -66 163 -14 60 -29 72 -94 72 -51 0 -76 19 -76 56 0 21 -5 24 -32 24 -18
0 -42 -7 -52 -15 -11 -8 -34 -15 -52 -15 -26 0 -36 7 -53 36 -12 19 -21 38
-21 42 0 4 19 16 43 27 35 17 43 26 47 54 4 30 11 36 58 54 59 22 80 53 61 88
-15 29 -6 45 39 68 58 29 70 73 27 93 -34 15 -31 32 8 43 31 10 33 13 69 145
59 211 88 289 190 497 57 117 69 183 33 183 -15 0 -76 -81 -100 -132 -42 -91
-75 -139 -135 -197 -87 -85 -104 -91 -223 -84 -98 6 -115 12 -100 36 4 6 8 42
8 79 0 56 4 70 23 88 15 14 22 32 22 58 0 21 9 55 20 77 11 22 20 48 20 59 0
11 16 48 35 83 19 35 35 69 35 76 0 7 11 33 25 59 43 81 28 134 -23 81 -55
-57 -185 -168 -210 -182 -15 -7 -61 -34 -102 -59 -60 -36 -87 -46 -140 -52
-36 -4 -80 -15 -97 -24 -31 -15 -33 -15 -43 4 -6 12 -17 38 -25 59 -13 37 -12
41 21 97 19 32 40 67 46 76 16 22 8 29 -41 35 -57 8 -66 22 -52 80 11 43 10
49 -18 103 -16 31 -35 78 -41 104 -6 27 -22 62 -35 78 -14 16 -25 39 -25 52 0
12 -8 32 -17 46 -9 13 -20 42 -24 64 -4 23 -17 77 -29 121 -20 69 -23 106 -24
265 -1 156 2 191 17 224 18 41 21 73 9 93 -5 8 -18 1 -40 -25z"
          />
          <path
            d="M9369 9512 c-47 -48 -104 -112 -126 -142 -51 -70 -187 -195 -261
-240 -18 -11 -42 -36 -53 -55 -16 -27 -27 -35 -50 -35 -39 0 -149 -105 -149
-142 0 -22 -22 -58 -34 -58 -15 0 -66 24 -66 31 0 5 -12 14 -27 20 -20 7 -30
6 -41 -5 -13 -13 -12 -18 5 -48 14 -23 19 -45 16 -74 -3 -29 1 -53 15 -81 13
-24 25 -78 32 -140 6 -55 15 -116 20 -134 22 -75 -3 -326 -46 -460 -13 -42
-24 -86 -24 -98 0 -11 -11 -49 -24 -83 -35 -91 -65 -184 -81 -256 -8 -34 -21
-75 -29 -90 -7 -15 -17 -54 -21 -86 -4 -33 -15 -91 -26 -130 -29 -111 -26
-276 7 -380 63 -196 138 -300 304 -422 75 -56 182 -171 208 -225 12 -24 29
-79 38 -121 17 -76 17 -79 -6 -155 -20 -71 -35 -106 -83 -198 -67 -130 -101
-193 -118 -213 -10 -14 -19 -29 -19 -35 0 -5 -17 -37 -39 -71 -21 -34 -50 -83
-65 -111 -26 -50 -32 -60 -72 -125 -12 -19 -41 -71 -64 -115 -23 -44 -55 -99
-71 -123 -16 -24 -36 -58 -44 -75 -8 -18 -32 -60 -54 -94 -22 -35 -42 -71 -46
-81 -3 -11 -14 -27 -25 -37 -11 -10 -20 -24 -20 -31 0 -7 -11 -26 -25 -43 -14
-16 -51 -78 -81 -137 l-56 -108 4 -101 c4 -95 6 -103 41 -157 75 -116 162
-148 393 -147 267 2 351 -48 398 -234 14 -53 13 -63 -4 -130 -11 -39 -20 -75
-20 -79 0 -4 -22 -33 -48 -65 -104 -125 -114 -172 -63 -284 39 -85 101 -145
165 -159 63 -14 177 -4 235 21 52 23 124 25 141 4 23 -28 7 -47 -57 -69 -53
-18 -77 -35 -160 -115 -100 -97 -144 -156 -142 -195 2 -60 80 -195 113 -196 6
0 38 -14 71 -30 33 -17 64 -30 70 -30 6 0 36 -22 67 -50 58 -52 88 -111 88
-173 0 -40 -27 -143 -50 -186 -19 -38 -56 -155 -64 -206 -12 -66 37 -319 69
-355 7 -8 23 -35 35 -60 13 -25 51 -72 85 -106 59 -58 177 -134 209 -134 8 0
32 -10 53 -22 36 -22 48 -23 238 -23 175 1 209 3 270 22 39 12 114 26 168 32
53 6 114 18 135 25 20 8 71 17 112 21 41 3 109 14 150 23 108 23 239 20 320
-8 36 -12 72 -28 80 -35 20 -18 66 -29 73 -17 4 5 -12 35 -33 68 -42 63 -49
94 -25 114 17 14 70 7 121 -17 31 -15 36 -15 73 2 31 13 52 16 88 11 132 -20
125 -20 148 8 l20 26 93 -11 c118 -14 126 -9 88 61 -15 27 -24 54 -21 60 9 15
42 12 76 -6 19 -9 49 -14 82 -12 l52 3 -3 36 -3 37 103 7 c85 6 110 11 141 31
44 29 46 43 12 72 -15 13 -25 33 -25 48 0 25 1 26 50 19 58 -8 170 23 208 59
13 11 49 28 80 36 49 13 57 19 60 40 2 17 -6 34 -23 50 -14 13 -25 29 -25 36
0 20 34 34 82 34 29 0 59 10 93 30 28 16 69 38 93 49 40 19 54 43 32 56 -5 3
-10 15 -10 25 0 14 13 23 52 35 73 21 92 37 112 89 14 35 24 47 44 52 63 14
78 23 105 62 21 31 38 44 67 52 22 6 60 28 85 50 26 22 51 40 57 40 11 0 117
47 188 84 25 13 62 26 83 29 41 6 67 22 67 41 0 16 -138 3 -279 -25 -76 -15
-152 -22 -275 -24 -167 -2 -172 -2 -199 21 -35 29 -71 30 -116 3 -49 -30 -81
-19 -81 27 0 44 -17 51 -84 35 -93 -23 -136 -25 -137 -7 -1 9 -3 21 -4 26 -1
6 -3 14 -4 19 -3 23 -43 19 -122 -10 -118 -44 -126 -45 -134 -20 -3 12 -4 34
0 50 5 25 -13 61 -31 61 -12 0 -153 -73 -177 -92 -44 -35 -62 -16 -62 63 0 67
-8 89 -29 89 -15 0 -56 42 -56 57 0 5 -9 18 -21 29 -12 10 -28 32 -36 48 -26
50 -3 52 91 7 84 -40 89 -41 191 -41 60 0 106 4 110 10 4 6 -5 31 -20 56 -14
24 -25 55 -23 67 3 21 6 22 115 19 64 -2 114 1 117 7 4 5 1 21 -5 35 -21 46
-5 52 162 59 84 3 156 10 162 16 7 7 -4 29 -37 71 -31 40 -46 68 -44 83 3 21
8 22 98 24 121 2 175 10 190 29 7 8 30 17 52 21 51 8 57 26 20 61 -28 27 -49
68 -39 79 3 2 41 9 84 14 45 6 99 20 128 34 28 13 62 27 76 30 35 7 40 22 22
74 -17 51 -13 55 54 65 25 3 57 15 72 25 15 11 46 27 69 36 47 19 62 50 44 89
-14 31 -4 43 43 54 64 14 128 54 147 92 18 35 50 59 80 60 9 0 43 23 74 50 31
28 62 50 67 50 6 0 24 11 41 23 17 13 63 41 101 62 39 21 81 47 94 57 13 10
31 18 40 18 9 0 50 23 91 51 41 27 85 52 98 55 13 4 21 11 19 18 -6 20 -57 18
-93 -3 -19 -12 -61 -24 -92 -27 -31 -3 -81 -17 -110 -31 -30 -13 -82 -26 -120
-30 -37 -3 -77 -13 -90 -21 -14 -9 -33 -12 -53 -8 -17 3 -62 -1 -100 -10 -67
-15 -69 -15 -100 7 l-31 22 -77 -27 c-76 -27 -111 -27 -111 0 0 20 -41 44 -76
44 -17 0 -53 -11 -79 -26 -51 -27 -94 -29 -105 -4 -36 78 -32 75 -91 72 -57
-4 -117 -32 -177 -84 -19 -16 -39 -27 -45 -25 -15 5 -25 42 -26 99 -1 26 -5
49 -11 53 -5 3 -26 -4 -47 -15 -21 -11 -54 -20 -74 -20 -41 0 -92 -33 -152
-98 -21 -24 -43 -39 -51 -36 -16 6 -26 48 -26 111 0 52 -15 61 -59 39 -16 -9
-37 -16 -45 -16 -25 0 -107 -64 -160 -124 -70 -81 -76 -78 -91 54 -3 30 -10
61 -14 69 -12 19 -63 5 -92 -25 -12 -13 -43 -36 -68 -50 -66 -38 -101 -73
-122 -121 -10 -24 -23 -43 -29 -43 -15 0 -30 45 -30 93 0 54 -9 87 -24 87 -7
0 -29 -18 -51 -40 -21 -22 -54 -45 -72 -51 -39 -12 -103 -83 -134 -146 -23
-48 -46 -64 -60 -42 -5 8 -9 55 -9 104 0 50 -4 95 -8 101 -4 6 -21 18 -38 27
-17 9 -46 33 -64 53 -31 36 -31 38 -14 51 12 9 45 13 94 12 46 0 92 5 119 15
24 9 65 16 91 16 72 0 91 40 34 70 -28 15 -31 36 -8 61 12 14 26 16 68 12 65
-7 86 10 86 68 0 45 10 52 99 64 70 10 114 48 105 92 -7 34 4 45 58 62 24 8
52 29 74 57 20 24 43 44 50 44 8 0 33 17 57 38 36 32 165 127 232 170 11 7 44
24 73 37 28 14 52 28 52 33 0 5 16 16 35 25 19 9 35 22 35 28 0 15 -82 6 -140
-15 -162 -58 -421 -100 -480 -78 -23 9 -83 4 -155 -12 -27 -7 -32 -4 -50 31
-20 37 -22 38 -72 38 -38 -1 -61 -7 -83 -24 -21 -15 -42 -21 -63 -19 -30 3
-32 5 -35 48 -5 62 -16 73 -75 67 -34 -3 -56 -12 -75 -30 -52 -50 -130 -44
-84 6 11 12 27 36 35 52 16 30 16 30 -36 82 -28 29 -48 55 -45 58 10 10 122
25 191 25 55 0 77 5 111 25 23 14 67 29 96 35 29 6 55 12 58 15 12 11 -12 55
-53 99 -35 36 -45 54 -43 74 2 15 10 28 18 29 8 1 54 7 103 13 76 11 95 18
153 56 37 23 79 46 95 49 50 11 51 27 3 75 -24 24 -44 51 -44 60 0 22 20 40
45 40 33 0 136 38 160 58 11 10 39 24 63 31 47 13 51 20 23 45 -24 22 -35 64
-21 81 6 7 27 16 48 20 20 4 65 23 100 42 49 27 62 40 62 58 0 25 10 34 97 89
29 18 72 50 95 71 24 20 90 65 148 99 58 33 141 86 185 115 44 30 99 68 121
83 23 15 44 34 47 42 3 8 16 16 28 18 26 4 38 28 14 28 -9 0 -28 -9 -42 -20
-14 -11 -40 -20 -57 -20 -17 0 -52 -11 -78 -25 -26 -14 -55 -25 -66 -25 -10 0
-33 -11 -52 -25 -19 -14 -44 -25 -57 -25 -13 0 -40 -7 -60 -15 -53 -22 -163
-48 -200 -46 -18 1 -51 -8 -74 -20 -41 -20 -43 -20 -81 -3 -36 16 -41 16 -96
-1 -31 -9 -72 -24 -90 -31 -43 -18 -41 -19 -58 21 -8 21 -21 35 -31 35 -29 0
-157 -51 -179 -72 -22 -19 -22 -19 -38 3 -9 13 -16 29 -16 37 0 25 -30 71 -50
77 -27 9 -146 -40 -188 -76 -41 -36 -87 -39 -96 -6 -3 12 -6 38 -6 58 0 53
-15 61 -83 45 -68 -16 -83 -26 -170 -111 -39 -37 -75 -65 -84 -63 -12 2 -20
24 -30 83 -7 44 -15 86 -18 93 -10 29 -90 6 -129 -37 -11 -12 -25 -21 -32 -21
-31 0 -62 -25 -94 -77 -19 -31 -50 -72 -69 -92 -32 -34 -34 -34 -47 -16 -8 10
-14 26 -14 35 0 9 -9 33 -19 54 -11 21 -23 51 -26 68 -9 38 -33 47 -54 21 -9
-11 -30 -28 -46 -38 -97 -62 -128 -102 -177 -229 -27 -70 -52 -91 -74 -62 -8
11 -14 28 -14 39 0 26 -49 107 -65 107 -7 0 -27 -13 -43 -30 -60 -60 -107 -18
-62 56 l23 36 -28 26 -28 26 29 21 c55 38 94 83 94 106 0 17 44 68 157 181
150 150 156 158 127 158 -18 0 -37 -7 -44 -15 -7 -8 -18 -15 -25 -15 -6 0 -34
-11 -61 -25 -27 -14 -55 -25 -62 -25 -7 0 -30 -9 -52 -20 -22 -11 -55 -20 -75
-20 -20 0 -65 -12 -101 -26 -59 -23 -65 -24 -86 -10 -34 24 -89 20 -128 -9
-42 -31 -75 -32 -103 -2 -12 13 -32 29 -44 36 -24 12 -31 45 -13 56 18 11 10
33 -25 71 -20 21 -34 43 -31 50 3 7 26 16 53 20 67 10 233 102 233 129 0 7
-13 18 -29 24 -15 7 -43 28 -61 46 l-33 34 27 11 c15 5 35 10 45 10 27 0 206
114 209 134 2 10 -8 35 -23 56 -30 43 -28 55 12 65 22 5 88 49 183 123 3 2 -7
14 -22 26 -16 12 -28 29 -28 39 0 15 62 68 145 124 45 30 51 61 18 96 l-27 27
56 53 c60 55 78 83 78 117 0 31 214 264 290 315 45 30 194 163 199 178 2 7 13
18 23 26 14 9 17 16 9 24 -7 7 -16 6 -28 -5 -10 -8 -35 -21 -55 -27 -21 -7
-40 -17 -44 -22 -3 -5 -40 -28 -82 -50 -42 -22 -93 -49 -112 -59 -62 -33 -144
-68 -197 -85 -28 -9 -64 -26 -79 -37 -18 -15 -39 -21 -64 -20 -35 3 -120 -29
-153 -57 -10 -8 -22 -9 -41 -1 -23 8 -34 5 -77 -26 l-51 -36 -22 21 c-33 31
-52 26 -105 -29 -50 -51 -93 -66 -105 -35 -11 29 -35 15 -101 -60 -37 -41 -72
-75 -77 -75 -5 0 -18 15 -29 34 -23 39 -39 38 -99 -3 -32 -21 -50 -42 -109
-120 -18 -24 -18 -24 -44 -5 -16 11 -31 36 -37 59 -6 22 -18 59 -26 83 -9 23
-24 82 -35 130 -10 48 -26 112 -34 142 -8 30 -15 80 -15 112 0 94 -27 137 -52
83 -13 -29 -44 -33 -52 -7 -15 48 30 271 74 367 10 22 22 51 25 65 4 14 13 32
21 40 7 9 20 31 29 50 9 19 36 64 61 100 24 36 43 72 41 80 -5 33 -36 15 -118
-68z m1983 -5200 c16 -16 -46 -89 -82 -97 -31 -7 -50 -30 -50 -61 0 -24 -70
-126 -83 -121 -7 2 -18 26 -25 53 -11 43 -11 53 4 81 15 29 15 33 -2 62 -10
17 -22 31 -26 31 -4 0 -12 9 -18 19 -8 15 -6 24 7 37 15 15 33 16 141 11 68
-3 129 -10 134 -15z"
          />
        </g>
      </svg>
    </>
  );
};

export default SmokinSingles;
