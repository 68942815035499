import React from 'react'

const UploadIcon = () => {
    return (
        <>
            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M13.2795 6.96641C13.3246 6.44238 13.7853 6.05415 14.3086 6.09927C16.1624 6.25913 17.6593 6.83428 18.6601 8.06945C19.6396 9.27832 19.9996 10.9465 19.9996 12.9623V13.0861C19.9996 15.3088 19.561 17.1126 18.3385 18.3368C17.1161 19.561 15.3148 20.0002 13.0953 20.0002H6.90427C4.68473 20.0002 2.8835 19.561 1.66106 18.3368C0.438618 17.1126 0 15.3088 0 13.0861V12.9623C0 10.9611 0.355143 9.30335 1.31869 8.0972C2.30422 6.86352 3.77983 6.28008 5.60823 6.10939C6.13119 6.06057 6.59465 6.44554 6.6434 6.96925C6.69215 7.49295 6.30773 7.95707 5.78477 8.00589C4.24663 8.14948 3.34948 8.60412 2.8039 9.28707C2.23634 9.99754 1.902 11.135 1.902 12.9623V13.0861C1.902 15.1204 2.31453 16.2975 3.00598 16.9899C3.69743 17.6824 4.87284 18.0955 6.90427 18.0955H13.0953C15.1267 18.0955 16.3021 17.6824 16.9936 16.9899C17.685 16.2975 18.0976 15.1204 18.0976 13.0861V12.9623C18.0976 11.121 17.7585 9.97971 17.1832 9.26958C16.6291 8.58574 15.7152 8.13234 14.1454 7.99697C13.6221 7.95184 13.2344 7.49045 13.2795 6.96641Z" fill="#404040" />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.0047 1.06641C10.5299 1.06641 10.9557 1.49279 10.9557 2.01877V12.8566C10.9557 13.3826 10.5299 13.809 10.0047 13.809C9.47949 13.809 9.05371 13.3826 9.05371 12.8566V2.01877C9.05371 1.49279 9.47949 1.06641 10.0047 1.06641Z" fill="#404040" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.33207 0.27894C9.70346 -0.09298 10.3056 -0.09298 10.677 0.27894L13.8629 3.46935C14.2342 3.84127 14.2342 4.44427 13.8629 4.81619C13.4915 5.18811 12.8893 5.18811 12.5179 4.81619L10.0045 2.2992L7.49114 4.81619C7.11975 5.18811 6.51761 5.18811 6.14622 4.81619C5.77483 4.44427 5.77483 3.84127 6.14622 3.46935L9.33207 0.27894Z" fill="#404040" />
            </svg>
        </>
    )
}

export default UploadIcon