import React from "react";

const CountIcon = () => {
  return (
    <>
      <svg
        width={16}
        height={18}
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.2025 0C8.55369 0 6.4043 2.11712 6.4043 4.72618C6.4043 7.33524 8.55369 9.45235 11.2025 9.45235C13.8514 9.45235 16.0008 7.33524 16.0008 4.72618C16.0008 2.11712 13.8514 0 11.2025 0Z"
          fill="#5D8B2F"
        />
        <path
          opacity="0.6"
          d="M2.9699 9.90918C1.33779 9.90918 0 11.2181 0 12.8345C0 14.4509 1.32887 15.7598 2.9699 15.7598C4.60201 15.7598 5.93982 14.4509 5.93982 12.8345C5.93982 11.2181 4.60201 9.90918 2.9699 9.90918Z"
          fill="#5D8B2F"
        />
        <path
          opacity="0.4"
          d="M12.1214 13.0625C10.739 13.0625 9.61523 14.1694 9.61523 15.531C9.61523 16.8926 10.739 17.9995 12.1214 17.9995C13.5038 17.9995 14.6275 16.8926 14.6275 15.531C14.6275 14.1694 13.5038 13.0625 12.1214 13.0625Z"
          fill="#5D8B2F"
        />
      </svg>
    </>
  );
};

export default CountIcon;
