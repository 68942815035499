import React from "react";

const LikeIconSocial = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width={44}
        height={44}
        viewBox="0 0 44 44"
        enableBackground="new 0 0 44 44"
        xmlSpace="preserve"
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#5D8B2F"
            d="M34.251,23.615c0.426-1.481,0.672-3.078,0.672-4.806   c0-4.487-3.218-8.117-7.185-8.117c-2.339,0-4.433,1.278-5.738,3.238c-1.305-1.96-3.399-3.238-5.738-3.238   c-3.967,0-7.185,3.63-7.185,8.117c0,1.728,0.246,3.325,0.672,4.806H34.251z M22.798,36.408c3.114-1.191,9.408-5.532,11.45-12.792   H9.746c2.042,7.26,8.335,11.602,11.45,12.792C21.635,36.582,22.359,36.582,22.798,36.408z"
          />
          <circle
            fill="none"
            stroke="#5D8B2F"
            strokeWidth={2}
            cx={22}
            cy={22}
            r={21}
          />
        </g>
      </svg>
    </>
  );
};

export default LikeIconSocial;
