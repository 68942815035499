import React from "react";

const SendMailIcon = () => {
  return (
    <>
      <svg
        width={40}
        height={40}
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_0_1)">
          <rect x={8} y={6} width={42} height={42} rx={12} fill="#464646" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.4821 19.513C36.0331 19.0634 35.0284 18.7576 33.2567 19.3482L24.6665 22.2116C24.6663 22.2117 24.6667 22.2115 24.6665 22.2116C23.287 22.6731 22.3307 23.1829 21.7399 23.6711C21.1456 24.1623 21.0238 24.5381 21.0238 24.757C21.0238 24.9756 21.1452 25.3505 21.7391 25.8403C22.3297 26.3273 23.2852 26.8352 24.665 27.2943L27.2155 28.1445C27.5177 28.2452 27.7548 28.4823 27.8555 28.7845L28.7055 31.3345C29.1646 32.714 29.6731 33.6699 30.1607 34.2604C30.651 34.8542 31.027 34.9762 31.2468 34.9762C31.4666 34.9762 31.8426 34.8542 32.3329 34.2604C32.8205 33.6699 33.3289 32.7145 33.7879 31.335L36.651 22.7456C36.6511 22.7453 36.651 22.7458 36.651 22.7456C37.2369 20.9735 36.9323 19.9639 36.4821 19.513ZM37.9142 18.083C39.1564 19.327 39.2614 21.2981 38.5725 23.381L35.7082 31.974C35.7082 31.9742 35.7083 31.9738 35.7082 31.974C35.206 33.4833 34.5986 34.6951 33.8934 35.549C33.191 36.3997 32.297 37 31.2468 37C30.1966 37 29.3026 36.3997 28.6001 35.549C27.895 34.6951 27.2878 33.4838 26.7856 31.9745C26.7855 31.9743 26.7856 31.9747 26.7856 31.9745L26.0955 29.9045L24.026 29.2146C24.0258 29.2146 24.0262 29.2147 24.026 29.2146C22.517 28.7125 21.3054 28.1059 20.4515 27.4017C19.6009 26.7002 19 25.8071 19 24.757C19 23.7072 19.6006 22.8136 20.4507 22.1111C21.3043 21.4056 22.5153 20.7971 24.0245 20.2923L32.6167 17.4282C34.7005 16.7336 36.6708 16.8377 37.9142 18.083Z"
          fill="#0C0C0C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.8491 24.3198C32.2449 24.7144 32.2458 25.3551 31.8511 25.7509L28.2285 29.3837C27.8338 29.7794 27.1931 29.7803 26.7974 29.3857C26.4017 28.991 26.4008 28.3503 26.7954 27.9546L30.4181 24.3218C30.8127 23.9261 31.4534 23.9252 31.8491 24.3198Z"
          fill="#0C0C0C"
        />
        <defs>
          <filter
            id="filter0_d_0_1"
            x={0}
            y={0}
            width={54}
            height={54}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx={-2} />
            <feGaussianBlur stdDeviation={3} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_0_1"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_0_1"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default SendMailIcon;
