import React from "react";
import Modal from "react-bootstrap/Modal";
import InstalAppIcon from "../../assets/Images/InstalAppIcon";

const AppStartModel = ({ show, handleClose,handleInstallClick }) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={true}
            className="d-flex align-items-center justify-content-center w-100"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Install Application
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div onClick={handleInstallClick} className="d-flex align-items-center justify-content-center">
                   <InstalAppIcon/>
                </div>
            </Modal.Body>
        </Modal>

    );
};

export default AppStartModel;
