import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
    const [terms] = useState(false);
    const [privacy] = useState(false);

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    const [userToken, setuserToken] = useState();
    useEffect(() => {
        const currentUser = localStorage.getItem("user-token-signup");
        setuserToken(currentUser);
    }, []);

    const submitHandler = (e) => {
        e.preventDefault();
        if (terms && privacy) {
            localStorage.setItem("user-token", userToken);
            navigate("/home");
            toast.success("Welcome");
        }
        if (terms && !privacy) {
            toast.error("Plase Check Privacy Policy");
        }
        if (!terms && privacy) {
            toast.error("Plase Check Terms & Conditions");
        }
    };
    return (
      <div className="max-width-792">
        <h2 className="auth-model-heading mb-4 pb-3">Privacy Policy</h2>
        <form onSubmit={(e) => submitHandler(e)}>
          <p className="text-grey font-16 text-justify overflow p-2 overflow-privacy-terms">
            We, at growandshare, accord the utmost importance to your privacy
            and are firmly dedicated to safeguarding your personal data. This
            policy delineates the mechanisms through which we gather, process,
            and disseminate your personal information, as well as the rights at
            your disposal concerning your data. We encourage you to peruse this
            privacy policy in its entirety prior to utilizing our services. In
            this privacy policy: "Services" refer to the digital platforms
            encompassing the growandshare web and mobile applications, the
            website located at growandshare.ca, and the entire spectrum of
            content, technology, functionalities, and features, along with other
            applications and services extended to you, the consumer, by
            growandshare. The term "Platform" is indicative of the websites, web
            and mobile applications, or any other medium provided by us to our
            consumers.
            <br />
            <strong className="text-white">TABLE OF CONTENTS</strong>
            <ol>
              <li>Our Identity</li>
              <li>Personal Data: Description and Source of Data Collection</li>
              <li>Rationale Behind Personal Data Collection</li>
              <li>Duration of Personal Data Retention</li>
              <li>Links to External Websites</li>
              <li>Personal Data Disclosure</li>
              <li>Communications and Marketing</li>
              <li>Your Entitlements</li>
              <li>Security and Administrative Measures</li>
              <li>User-Generated Content</li>
              <li>Privacy Rights</li>
              <li>Modifications to the Privacy Policy</li>
              <li>Contact Details</li>
            </ol>
            Within this document, "growandshare," "we," "us," or "our," are used
            interchangeably to represent Grow and Share. The website user may be
            referred to as "Community Member," "Member," "User," "Consumer,"
            "Cannabis Enthusiast," or "you."
            <br />
            <strong className="text-white">OUR IDENTITY</strong>
            <br />
            Growandshare, officially registered in Canada as Grow and Share
            Inc., is stationed at 1 King St W, Suite 4800-246, Toronto, Ontario,
            Canada. We act as the principal data custodian, holding
            responsibility over the personal data accumulated via our services,
            excluding credit card information which is processed through our
            third-party merchant: PayPal.
            <br />
            <strong className="text-white">
              PERSONAL DATA: DESCRIPTION AND SOURCE OF DATA COLLECTION
            </strong>
            <br />
            To facilitate the seamless functioning and enhancement of our
            services, we necessitate the collection of personal data from our
            community members. Subsequently, we provide an exhaustive list of
            data types collected by Grow and Share to better serve its members.
            <br />
            <strong>
              2.1 User Information Collected During Service Utilization
            </strong>
            <br />
            During your registration or interaction with our platform, we may
            solicit the following pieces of information:
            <ul>
              <li>Email Address</li>
              <li>Mobile Phone Number</li>
              <li>User Identification</li>
              <li>
                Social Media Account Details (e.g., Google, Facebook, Instagram)
              </li>
              <li>Geographic Location</li>
              <li>Age Verification (All users must be above the age of 19)</li>
              <li>Details regarding cannabis sharing and usage</li>
              <li>Government-issued Identification (e.g., Driver's License)</li>
              <li>Additional voluntary account-related information</li>
            </ul>
            Note: Certain disclosed information will be accessible to other
            community members.
            <br />
            <strong>2.2 Automatically Collected Information</strong>
            <br />
            Interactions with our platform result in the automatic collection of
            various data types including but not limited to Device Information,
            Location Information, Cookie Data, Log Data, and Analytics.
            <br />
            <strong>2.3 Third-Party Sources</strong>
            <br />
            We may obtain personal data from third-party entities to promote
            safety and deter fraudulent activities through our chat feature.
            Potentially unlawful communications will be documented and might be
            reported to law enforcement agencies if deemed necessary.
            <br />
            <strong>
              2.4 Data Collected from Postings, Messages, and Blog Posts
            </strong>
            <br />
            All textual contributions via postings, chat messages, or blog posts
            are retained in our system, encompassing any voluntarily disclosed
            information.
            <br />
            <strong className="text-white">
              RATIONALE BEHIND PERSONAL DATA COLLECTION
            </strong>
            <br />
            We utilize your personal data solely as delineated in this policy,
            primarily focusing on enhancing platform access and service
            delivery, improving user experience, and ensuring the safety and
            security of our platform.
            <br />
            <strong className="text-white">
              DURATION OF PERSONAL DATA RETENTION
            </strong>
            <br />
            We retain your personal data only for the requisite period to
            fulfill the intended purposes, including meeting legal, reporting,
            or accounting obligations.
            <br />
            <strong className="text-white">LINKS TO EXTERNAL WEBSITES</strong>
            <br />
            Our platforms might contain links to third-party websites or apps.
            We bear no responsibility or control over these external platforms
            and encourage users to familiarize themselves with the policies of
            these platforms.
            <br />
            <strong className="text-white">PERSONAL DATA DISCLOSURE</strong>
            <br />
            We may share your personal data with various third parties for
            reasons outlined in this section, encompassing engagements with
            advertisers, corporate affiliates, legal and government entities,
            third-party service providers, analytics providers, and in the event
            of business transfers or reorganizations.
            <br />
            <strong className="text-white">Communications and Marketing</strong>
            <br />
            <ul>
              <li>
                Communication mediums include emails and in-app notifications.
              </li>
              <li>
                Users may receive direct marketing messages with an option to
                unsubscribe.
              </li>
              <li>
                Even if users opt out of marketing communications, the platform
                reserves the right to send non-marketing related information and
                messages.
              </li>
              <li>
                Occasionally runs ad campaigns that don't rely on users'
                personal information.
              </li>
            </ul>
            <br />
            <strong className="text-white">Your Rights</strong>
            <br />
            <ul>
              <li>Users have rights as per local data protection laws.</li>
              <li>
                Users in Canada can access their personal information held by
                organizations within stipulated timeframes.
              </li>
            </ul>
            <br />
            <strong>
              8.1 Access to Specific Information and Data Portability Rights
            </strong>
            <br />
            <ul>
              <li>
                Users can request information about the data collected over the
                past 12 months by emailing{" "}
                <a href="mailto:info@growandshare.ca">info@growandshare.ca</a>
              </li>
              <li>The request should be verifiable.</li>
              <li>
                Details about collected personal information, its sources,
                purposes, and third-party shares will be disclosed.
              </li>
            </ul>
            <br />
            <strong> 8.2 Deletion Request Rights</strong>
            <br />
            <ul>
              <li>
                Users can request data deletion, with some exceptions, by
                emailing{" "}
                <a href="mailto:info@growandshare.ca">
                  info@growandshare.ca
                </a>{" "}
              </li>
              <li>
                Certain circumstances might delay or deny the deletion request.
              </li>
            </ul>
            <br />
            <strong>8.3 Access and Deletion Rights</strong>
            <br />
            <ul>
              <li>Users can submit a verifiable request via email.</li>
              <li>
                The request should contain sufficient personal information to
                verify the identity of the requester.
              </li>
            </ul>
            <br />
            <strong>8.4 Response Timing and Format</strong>
            <br />
            <ul>
              <li>
                The platform aims to respond to verifiable requests within 30
                days, exceptions may apply.
              </li>
            </ul>
            <br />
            <strong>8.5 Right to Request Correction</strong>
            <br />
            <ul>
              <li>
                Users have the right to request corrections to their personal
                information by emailing{" "}
                <a href="mailto:info@growandshare.ca">
                  info@growandshare.ca
                </a>
              </li>
            </ul>
            <br />
            <strong>8.6 Canada's Anti-Spam Legislation (CASL)</strong>
            <br />
            <ul>
              <li>
                CASL mandates an unsubscribe mechanism in emails and SMS
                messages.
              </li>
              <li>
                Unsubscribe links will be included in all emails and SMS
                messages.
              </li>
            </ul>
            <br />
            <strong className="text-white">
              Technical, Organizational, and Security Measures
            </strong>
            <br />
            <ul>
              <li>
                The platform implements measures to protect user data from
                unauthorized access.
              </li>
              <li>
                Users are responsible for maintaining the confidentiality of
                their passwords.
              </li>
              <li>
                Note: Data transfer over the internet cannot be guaranteed to be
                completely secure.
              </li>
            </ul>
            <br />
            <strong className="text-white">User Submitted Content</strong>
            <br />
            <ul>
              <li>
                Users can post content on the platform and social media
                platforms.
              </li>
              <li>
                Users should exercise caution while sharing personal
                information.
              </li>
              <li>
                {" "}
                The platform may use user-submitted ads and content for
                marketing and advertising purposes.
              </li>
            </ul>
            <br />
            <strong className="text-white">
              Privacy Rights (Canada - PIPEDA)
            </strong>
            <br />
            <ul>
              <li>
                PIPEDA governs access to personal information held by
                organizations in Canada.
              </li>
              <li>
                Users can request access to their personal data as per PIPEDA
                provisions.
              </li>
              <li>
                Requests for personal information can be made detailing specific
                information and must be responded to generally within 30 days.
              </li>
            </ul>
            <br />
            <strong>
              {" "}
              11.1 What Type of Personal Information Can I Ask For?
            </strong>
            <br />
            Under PIPEDA, "personal information" refers to data about an
            identifiable individual, encompassing both factual and subjective
            data. You can request details such as:
            <ul>
              <li>Name</li>
              <li>Opinions about the individual</li>
              <li>Birth date</li>
              <li>Income</li>
              <li>Physical description</li>
              <li>Medical history</li>
              <li>Gender</li>
              <li>Religion</li>
              <li>Address</li>
              <li>Political affiliations and beliefs</li>
              <li>Education</li>
              <li>Employment</li>
              <li>Visual images where individuals may be identified</li>
            </ul>
            <br />
            <strong>11.2 Accessing Information</strong>
            <br />
            According to PIPEDA, users can request to view their personal
            information in person. If in-person viewing is not possible, users
            can make special requests to receive an electronic copy of the
            information.
            <br />
            <strong>
              11.3 Can We Deny Your Request to Access Your Personal Information?
            </strong>
            <br />
            Yes, as per Canadian law and PIPEDA stipulations, Grow and Share can
            deny access to some or all of your personal information under
            certain circumstances, where the law forbids the release of such
            data.
            <br />
            <strong className="text-white"> Privacy Policy Changes</strong>
            <br />
            The privacy policy might undergo updates occasionally. Updates will
            be announced on the website's top header and notified through the
            email provided to us at
            <a href="mailto:info@growandshare.ca">
              {" "}
              info@growandshare.ca
            </a>{" "}
            Changes generally won't be implemented until the new year.
            Disagreeing users can close their accounts by accessing account
            settings or by contacting{" "}
            <a href="mailto:info@growandshare.ca">info@growandshare.ca</a>
            <br />
            <strong className="text-white">Contact Details</strong>
            <br />
            For queries regarding this privacy policy, contact the team at{" "}
            <a href="mailto:info@growandshare.ca">
              info@growandshare.ca
            </a>{" "}
            Exercise caution when sharing sensitive information via email.
            <br />
            For a detailed and comprehensive understanding, we encourage a
            thorough review of each section of the policy. Please feel free to
            reach out to us through the mentioned contact details for any
            queries or clarifications.
          </p>
          <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-5">
            <button
              className="green-btn-outline custom-w min-width-208"
              onClick={() => goBack()}
            >
              Back
            </button>
          </div>
        </form>
        <p className="text-center text-grey mt-5 pt-sm-0 pt-3 font-16">
          <Link className="font-16 text-grey" to="/terms">
            Terms of use
          </Link>{" "}
          |{" "}
          <Link className="font-16 text-grey" to="/privacypolicy">
            Privacy Policy
          </Link>
        </p>
      </div>
    );
};

export default PrivacyPolicy
