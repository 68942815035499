import React from "react";

const SelectAfterIcon = () => {
  return (
    <>
      <svg
        width={20}
        height={10}
        viewBox="0 0 20 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.328356 0.321949C0.766163 -0.107316 1.47599 -0.107316 1.9138 0.321949L9.22321 7.48875C9.64864 7.90587 10.3514 7.90587 10.7768 7.48875L18.0862 0.321949C18.524 -0.107316 19.2338 -0.107316 19.6716 0.321949C20.1095 0.751215 20.1095 1.44719 19.6716 1.87646L12.3622 9.04326C11.0612 10.3189 8.93881 10.3189 7.63777 9.04326L0.328356 1.87646C-0.109452 1.44719 -0.109452 0.751215 0.328356 0.321949Z"
          fill="#6B6B6B"
        />
      </svg>
    </>
  );
};

export default SelectAfterIcon;
