import React from "react";

const BudsType = () => {
  return (
    <>
      <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.4" d="M9.01101 0C9.67221 0.38157 10.2144 0.898813 10.5894 1.50576C10.9645 2.11271 11.1607 2.79056 11.1607 3.47907C11.1607 4.16759 10.9645 4.84544 10.5894 5.45238C10.2144 6.05933 9.67221 6.57657 9.01101 6.95814C8.34982 6.57657 7.80761 6.05933 7.43259 5.45238C7.05756 4.84544 6.86133 4.16759 6.86133 3.47907C6.86133 2.79056 7.05756 2.11271 7.43259 1.50576C7.80761 0.898813 8.34982 0.38157 9.01101 0Z" fill="#5D8B2F" />
  <path d="M2.40141 7.85023C3.84226 8.58371 5.55755 8.56388 6.95266 7.96917C6.90895 7.28092 6.66606 6.61328 6.24639 6.02779C5.82671 5.4423 5.24376 4.9578 4.55125 4.61895C3.86129 4.27905 3.08591 4.09052 2.29266 4.06979C1.4994 4.04906 0.712332 4.19675 0 4.50001C0.114353 5.84803 0.960565 7.13657 2.40141 7.85023Z" fill="#5D8B2F" />
  <path opacity="0.4" d="M4.57273 13.3808C6.01358 12.6473 6.88266 11.3786 6.97414 10.0306C6.26499 9.7177 5.47636 9.56458 4.68096 9.58536C3.88557 9.60615 3.10901 9.80018 2.4229 10.1496C0.982049 10.883 0.112967 12.1518 0.0214844 13.4998C1.41659 14.0945 3.10901 14.1143 4.57273 13.3808Z" fill="#5D8B2F" />
  <path d="M11.1611 14.5307C11.1598 13.8437 10.9628 13.1677 10.5878 12.5626C10.2128 11.9574 9.67127 11.4418 9.01128 11.0615C8.35029 11.4409 7.808 11.9563 7.43284 12.5617C7.05768 13.167 6.86133 13.8435 6.86133 14.5307C6.86133 15.2179 7.05768 15.8943 7.43284 16.4997C7.808 17.105 8.35029 17.6204 9.01128 17.9998C9.67286 17.621 10.2156 17.1057 10.5908 16.5002C10.966 15.8947 11.162 15.218 11.1611 14.5307Z" fill="#5D8B2F" />
  <path opacity="0.4" d="M15.5981 10.1495C14.1573 9.41607 12.442 9.43589 11.0469 10.0306C11.0906 10.7189 11.3335 11.3865 11.7531 11.972C12.1728 12.5575 12.7558 13.042 13.4483 13.3808C14.8891 14.1143 16.6044 14.0945 17.9995 13.4998C17.9558 12.8115 17.7129 12.1439 17.2933 11.5584C16.8736 10.9729 16.2906 10.4884 15.5981 10.1495Z" fill="#5D8B2F" />
  <path d="M13.4483 4.61879C12.7641 4.96604 12.1878 5.45261 11.7694 6.03636C11.3509 6.62012 11.1029 7.28347 11.0469 7.969C11.756 8.28191 12.5447 8.43504 13.3401 8.41425C14.1354 8.39346 14.912 8.19944 15.5981 7.85006C17.039 7.11658 17.9081 5.84786 17.9995 4.49984C16.6044 3.90513 14.912 3.88531 13.4483 4.61879Z" fill="#5D8B2F" />
</svg>

    </>
  );
};

export default BudsType;
