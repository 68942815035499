import React from "react";
import { useNavigate } from "react-router-dom";
import userprofile from "../../assets/Images/social-user.svg";
import { useEffect } from "react";
import { useState } from "react";
import Axios from "../../axios/Axios";
import { toast } from "react-toastify";
import ImageDisplay from "./ImageDisplay";
import ImageGallery from "react-image-gallery";

const SocialProfile = () => {
  const navigate = useNavigate();
  const [currentuserData, setcurrentuserData] = useState();
  useEffect(() => {
    const currentUser = localStorage.getItem("userdata");
    let data = JSON.parse(currentUser);
    setcurrentuserData(data);
    let GetUserUrl = `${process.env.REACT_APP_API_URI}users/${data?._id}`;
    GetUser(GetUserUrl);
  }, []);

  const GetUser = async (GetUserUrl) => {
    try {
      const fetchData = await Axios.get(GetUserUrl);
      localStorage.setItem(
        "userdata",
        JSON.stringify(fetchData?.data?.data?.doc)
      );
      setcurrentuserData(fetchData?.data?.data?.doc);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    navigate("/social/lookingfor");
  };
  const socialPhotos = currentuserData?.socialPhotos || [];
  const items = socialPhotos.map((img) => {
    if (img) {
      const imageUrl = `${process.env.REACT_APP_PORT}/${img}`;
      return {
        original: imageUrl,
        thumbnail: imageUrl,
      };
    } else {
      return {
        original: userprofile,
        thumbnail: userprofile,
      };
    }
  });

  return (
    <div className=" pb-4 px-0">
      <div className="self-summary text-white m-4 mb-5 p-3 d-flex flex-column align-items-center gap-4 position-relative">
        {/* <ImageDisplay
          src={authSettings}
          alt=""
          className="settings-authprofile cr-p"
          onClick={() => navigate("/social/setting")}
        /> */}
        {currentuserData?.socialPhotos?.length <= 1 ? (
          <ImageDisplay
            src={
              currentuserData?.socialPhotos
                ? `${process.env.REACT_APP_PORT}/${currentuserData?.socialPhotos[0]}`
                : userprofile
            }
            alt=""
            className=" w-50 rounded-3 user-profile-image"
          />
        ) : (
          <ImageGallery
            items={items}
            showFullscreenButton={false}
            showPlayButton={false}
            autoPlay={false}
            additionalClass="rounded-5"
            showIndex={true}
            renderRightNav={(onClick, disabled) => (
              <RightNav onClick={onClick} disabled={disabled} />
            )}
            renderLeftNav={(onClick, disabled) => (
              <LeftNav onClick={onClick} disabled={disabled} />
            )}
          />
        )}

        <h2 className="auth-model-heading px-4 text-left ">
          {currentuserData?.fullName}
        </h2>
        <div className="d-flex gap-4">
          <span>Age: {currentuserData?.age}</span>
          <span className="text-capitalize">
            Smoker: {currentuserData?.smoking}
          </span>
        </div>
        {/* <p className="font-14 font-weight-500 cut-text text-center">
          {currentuserData?.location.address}
        </p> */}
      </div>
      <div className="self-summary rounded-0 py-3 px-4">
        <p className="font-18-100 font-weight-700 pb-2 text-white">
          My Self- summary
        </p>
        <p className="font-14 font-weight-400 text-grey pt-1">
          {currentuserData?.selfSummary}
        </p>
      </div>
      <form onSubmit={(e) => submitHandler(e)} className="px-4 mt-4 pt-3">
        {/* <label className="text-white mb-2 font-weight-600 font-18-100">
          My Bio
        </label>
        <div className="form-control h-auto p-0 bg-transparent border-0 mb-4">
          <textarea
            readOnly={currentuserData?.bio ? true : false}
            value={currentuserData?.bio}
            required
            className="auth-input-textarea border-grey rounded-3 h-auto"
            placeholder="Tell me about yourself!"
            name="bio"
          />
        </div>
        <input
          className="auth-input mb-4 text-capitalize"
          readOnly
          type="text"
          required
          placeholder="I’ve been kinky for"
          value={currentuserData?.gender}
        />
        <input
          className="auth-input mb-4"
          type="text"
          required
          placeholder="Height"
          readOnly
          value={currentuserData?.height}
        /> */}
        <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-4 pt-3">
          <button
            onClick={() => navigate("/social/lookingfor")}
            className="green-btn-outline text-primary-green custom-w min-width-208"
          >
            Settings
          </button>
        </div>
        <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-4 pt-3">
          <button
            onClick={() => navigate("/social/uploadpicture")}
            className="green-btn-outline text-primary-green custom-w min-width-208"
          >
            Add Media
          </button>
        </div>
        <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-4 pt-3">
          <button
            onClick={() => navigate("/social/setting")}
            className="green-btn-outline text-primary-green custom-w min-width-208"
          >
            Edit Info
          </button>
        </div>
      </form>
    </div>
  );
};

export default SocialProfile;

const RightNav = React.memo(({ disabled, onClick }) => {
  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-right-nav"
      disabled={disabled}
      onClick={onClick}
      aria-label="Next Slide"
    >
      <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.73023 0.29552C9.14137 -0.0985067 9.80795 -0.0985067 10.2191 0.29552L17.0833 6.874C18.305 8.04493 18.305 9.95507 17.0833 11.126L10.2191 17.7045C9.80795 18.0985 9.14137 18.0985 8.73023 17.7045C8.31909 17.3105 8.31909 16.6716 8.73023 16.2776L15.5944 9.69911C15.9939 9.31623 15.9939 8.68377 15.5944 8.30089L8.73023 1.72242C8.31909 1.32839 8.31909 0.689547 8.73023 0.29552Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.308354 0.29552C0.719492 -0.0985067 1.38608 -0.0985067 1.79722 0.29552L8.66138 6.874C9.88316 8.04493 9.88316 9.95507 8.66138 11.126L1.79722 17.7045C1.38608 18.0985 0.719492 18.0985 0.308354 17.7045C-0.102785 17.3105 -0.102785 16.6716 0.308354 16.2776L7.17252 9.69911C7.57202 9.31623 7.57202 8.68377 7.17252 8.30089L0.308354 1.72242C-0.102785 1.32839 -0.102785 0.689547 0.308354 0.29552Z"
          fill="white"
        />
      </svg>
    </button>
  );
});

const LeftNav = React.memo(({ disabled, onClick }) => {
  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-left-nav"
      disabled={disabled}
      onClick={onClick}
      aria-label="Previous Slide"
    >
      <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.26977 0.29552C8.85863 -0.0985067 8.19205 -0.0985067 7.78091 0.29552L0.916746 6.874C-0.305037 8.04493 -0.305037 9.95507 0.916746 11.126L7.78091 17.7045C8.19205 18.0985 8.85863 18.0985 9.26977 17.7045C9.68091 17.3105 9.68091 16.6716 9.26977 16.2776L2.40561 9.69911C2.0061 9.31623 2.0061 8.68377 2.40561 8.30089L9.26977 1.72242C9.68091 1.32839 9.68091 0.689547 9.26977 0.29552Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6916 0.29552C17.2805 -0.0985067 16.6139 -0.0985067 16.2028 0.29552L9.33862 6.874C8.11684 8.04493 8.11684 9.95507 9.33862 11.126L16.2028 17.7045C16.6139 18.0985 17.2805 18.0985 17.6916 17.7045C18.1028 17.3105 18.1028 16.6716 17.6916 16.2776L10.8275 9.69911C10.428 9.31623 10.428 8.68377 10.8275 8.30089L17.6916 1.72242C18.1028 1.32839 18.1028 0.689547 17.6916 0.29552Z"
          fill="white"
        />
      </svg>
    </button>
  );
});
