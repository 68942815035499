import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Axios from "../../axios/Axios";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardPaymentForm from "./CardPaymentForm";
import FurthurCardPaymentForm from "./FurthurPlansCardPaymentForm ";
import { toast } from "react-toastify";
import { createSubscription } from "../../Api";
import ReactCardFlip from "react-card-flip";
import CrossBorderIcon from "../../assets/Images/CrossBorder";
import { useRef } from "react";
import Slider from "react-slick";
import EmptyDataImage from "../../assets/Images/EmptyData";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const SocialSubscription = () => {
  const navigate = useNavigate();
  const [flip, setFlip] = useState(false);
  const [planDescription, setplanDescription] = useState([]);
  const [modalCheck, setModalCheck] = useState(false);
  const [type, setType] = useState();
  const [messagePlanType, setMessagePlanType] = useState("");
  const [currentUserData, setcurrentUserData] = useState("");
  const [substype, setsubsType] = useState([]);
  const [termsAndPrivacyCheck, settermsAndPrivacyCheck] = useState(false);
  const [allPlans, setAllPlans] = useState([]);
  const [freeModalCheck, setFreeModalCheck] = useState(true);
  const route = "/social/lookingfor";
  const [modalShow, setModalShow] = useState(false);
  const [isSelectAlreadySubscribed, setIsSelectAlreadySubscribed] =
    useState(false);
  const [messageModalShow, setMessageModalShow] = useState(false);
  const [subscribedPlan, setsubscribedPlan] = useState();
  const [planTab, setPlanTab] = useState(1);

  useEffect(() => {
    const handleKeyDown = (event) => {
      event.preventDefault();
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  

  const sliderRef = useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    onSwipe: (direction) => {
      console.log("Swiped:", direction);
    },
    beforeChange: (current, next) => {
      setplanDescription(allPlans[next]);
      if (allPlans[next]?.free) {
        setFlip(false);
      }
      if (!allPlans[next]?.free) {
        setFlip(true);
      }
      const planIds = substype?.map((obj) => obj.planId);
      const includesMatchingPlanId = planIds?.includes(allPlans[next]._id);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (!includesMatchingPlanId) {
        if (allPlans[next]?.free) {
          setType(allPlans[next]._id);
          setModalCheck(false);
          setFreeModalCheck(true);
        } else {
          setModalCheck(true);
          setFreeModalCheck(false);
          setType(allPlans[next]._id);
        }
      } else {
        setModalCheck(false);
      }
      // Check if sliderRef.current is defined before calling slickPrev/slickNext
      if (sliderRef.current) {
        sliderRef.current.slickPrev();
      }
    },
  };
  const submitHandler = (e) => {
    e.preventDefault();
    navigate("/social/lookingfor");
  };
  useEffect(() => {
    const currentUser = localStorage.getItem("userdata");
    let data = JSON.parse(currentUser);
    setcurrentUserData(data);
    let GetUserUrl = `${process.env.REACT_APP_API_URI}users/${data?._id}`;
    GetUser(GetUserUrl);
    Axios.get(
      `${process.env.REACT_APP_API_URI}subscription/user-subscription/${data?._id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user-token")}`,
        },
      }
    )
      .then((response1) => {
        setsubsType(response1.data.userSubscriptions);
        Axios.get(`${process.env.REACT_APP_API_URI}plan/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user-token")}`,
          },
        })
          .then((response2) => {
            const filteredPlans = response2.data.plans.filter(
              (plan) => plan.type === "social" || plan.type === "combine"
            );
            const sorting = filteredPlans.sort((a, b) => a.price - b.price);
            setType(sorting[0]._id);
            setAllPlans(sorting);
            if (sorting[0]) {
              setplanDescription(sorting[0]);
            } else {
              setPlanTab(2);
            }

            let latestSubscriptionDate = null;
            let latestSubscriptionIndex = -1;
            response1.data.userSubscriptions.forEach((subscription, index) => {
              const subscriptionDate = new Date(subscription.createdAt);
              if (
                !latestSubscriptionDate ||
                subscriptionDate > latestSubscriptionDate
              ) {
                latestSubscriptionDate = subscriptionDate;
                latestSubscriptionIndex = index;
              }
            });

            response2.data.plans.map((data) => {
              if (
                data._id ===
                response1.data.userSubscriptions[latestSubscriptionIndex].planId
              ) {
                setsubscribedPlan(data);
              }
            });
          })
          .catch((error) => {
            console.log(error.response?.data);
          });
      })
      .catch((error) => {
        console.log(error?.response?.data);
      });

    Axios.get(`${process.env.REACT_APP_API_URI}pricing/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user-token")}`,
      },
    })
      .then((response) => {})
      .catch((error) => {
        console.log(error.response?.data);
      });
  }, []);
  const GetUser = async (GetUserUrl) => {
    try {
      const fetchData = await Axios.get(GetUserUrl);
      localStorage.setItem(
        "userdata",
        JSON.stringify(fetchData?.data?.data?.doc)
      );
      setcurrentUserData(fetchData?.data?.data?.doc);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
  };

  useEffect(() => {
    const planIds = substype && substype?.map((obj) => obj.planId);
    let filteredPlans = allPlans?.filter((plan) => !planIds?.includes(plan._id));
    filteredPlans = filteredPlans?.filter((plan) => plan?.price > 0);
    setAllPlans(filteredPlans);
  }, [substype, planDescription]);

  const handleChange = (event, plan) => {
    if (plan?.free) {
      setFlip(false);
    }
    if (!plan?.free) {
      setFlip(true);
    }
    setplanDescription(plan);
    const planIds = substype?.map((obj) => obj.planId);
    const includesMatchingPlanId = planIds?.includes(event.target.value);
    if (!includesMatchingPlanId) {
      if (plan?.free) {
        const route = "/social/lookingfor";
        const data = {
          planId: event.target.value,
          userId: currentUserData._id,
        };
        setModalCheck(false);
        setFreeModalCheck(true);

        createSubscription(data, route, navigate, setModalShow, setsubsType);
      } else {
        setModalCheck(true);
        setFreeModalCheck(false);
        setType(event.target.value);
      }
    } else {
      setModalCheck(false);
      setIsSelectAlreadySubscribed(true);
    }
  };

  const handleFreeSubscription = () => {
    const route = "/social/lookingfor";
    const data = {
      planId: type,
      userId: currentUserData._id,
    };
    const planIds = substype?.map((obj) => obj.planId);
    const includesMatchingPlanId = planIds?.includes(type);
    if (!includesMatchingPlanId) {
      createSubscription(data, route, navigate, setModalShow, setsubsType);
    }
  };
  useEffect(() => {
    const planIds = substype?.map((obj) => obj.planId);
    const includesMatchingPlanId = planIds?.includes(type);
    if (includesMatchingPlanId) {
      setIsSelectAlreadySubscribed(true);
    } else {
      setIsSelectAlreadySubscribed(false);
    }
  }, [type, substype]);

  return (
    <React.Fragment>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="rounded-10 subscription-modal payment-modal"
        backdrop="static"
      >
        <Modal.Header className="px-4 justify-content-end">
          <button
            type="button"
            className="outline-0 border-0 bg-transparent"
            onClick={() => setModalShow(false)}
          >
            <CrossBorderIcon />
          </button>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="font-weight-bold mb-3"
          >
            Payment Method
          </Modal.Title>
          <div className="auth-input h-auto bg-white text-dark mb-3 p-3">
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between text-grey gap-3 align-items-center border-bottom border-grey pb-3 flex-wrap">
                <span className="font-16-social">Subscription Type</span>
                <h4 className="font-18-social">{planDescription.name}</h4>
              </div>
              <div className="d-flex justify-content-between text-grey gap-3 align-items-center pt-3">
                <span className="font-16-social">Total</span>
                <h4 className="font-18-social">${planDescription.price}</h4>
              </div>
            </div>
          </div>
          <Elements stripe={stripePromise}>
            <CardPaymentForm
              type={type}
              setModalShow={setModalShow}
              route={route}
              setsubsType={setsubsType}
            />
          </Elements>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={messageModalShow}
        onHide={() => setMessageModalShow(false)}
        className="rounded-10"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="font-weight-bold"
          >
            Payment Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Elements stripe={stripePromise}>
            <FurthurCardPaymentForm
              type={messagePlanType}
              setModalShow={setMessageModalShow}
              route={route}
            />
          </Elements>
        </Modal.Body>
      </Modal>

      <div className="max-width-521 min-width-521 my-4 mx-3 px-0 subscription social">
        <Tabs
          defaultActiveKey={planTab}
          activeKey={planTab}
          onSelect={(k) => setPlanTab(k)}
          id="uncontrolled-tab-example"
          className="justify-content-around"
        >
          <Tab eventKey={1} title="Subscribed Plan" className="bg-transparent">
            <div className="px-4">
              {subscribedPlan !== "" && subscribedPlan !== undefined ? (
                <div className="self-summary  border-0 rounded-4 p-4 d-flex flex-column mb-4 mt-4">
                  <p className="font-weight-700 text-white">
                    Subscription Benefits:
                  </p>
                  <div className="auth-subscription mt-0">
                    <ul>
                      {(subscribedPlan?.description || []).map(
                        (data, index) => {
                          return (
                            <li key={index}>
                              <h4 className="font-16-social text-grey font-weight-400 line-height-20px">
                                {data}
                              </h4>
                            </li>
                          );
                        }
                      )}
                    </ul>
                    <div className="auth-input h-auto bg-transparent text-dark mb-3 p-3">
                      <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between text-grey gap-3 align-items-center border-bottom border-grey pb-3 flex-wrap">
                          <span className="font-16-social">
                            Subscription Type
                          </span>
                          <h4 className="font-18-social">
                            {subscribedPlan?.name}
                          </h4>
                        </div>
                        <div className="d-flex justify-content-between text-grey gap-3 align-items-center pt-3">
                          <span className="font-16-social">Total</span>
                          <h4 className="font-18-social">
                            ${subscribedPlan?.price}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center w-100 mt-5">
                  <EmptyDataImage />
                </div>
              )}
            </div>
          </Tab>
          <Tab eventKey={2} title="Upgrade Plan" className="bg-transparent">
            <form onSubmit={(e) => submitHandler(e)} className="px-4 mt-4 pt-3">
              <div className="subscription-scroll">
                <ReactCardFlip isFlipped={flip} flipDirection="vertical">
                  <div className="self-summary  border-0 rounded-4 p-4 d-flex flex-column">
                    <p className="font-weight-700 text-white">
                      Basic Subscription Benefits: Grow and Share
                    </p>
                    <div className="auth-subscription mt-0">
                      <ul>
                        {(planDescription.description || []).map(
                          (data, index) => {
                            return (
                              <li key={index}>
                                <h4 className="font-16-social text-grey font-weight-400 line-height-20px">
                                  {data}
                                </h4>
                              </li>
                            );
                          }
                        )}
                      </ul>
                      <div className="auth-input h-auto bg-transparent text-dark mb-3 p-3">
                        <div className="d-flex flex-column">
                          <div className="d-flex justify-content-between text-grey gap-3 align-items-center border-bottom border-grey pb-3">
                            <span className="font-16-social">
                              Subscription Type
                            </span>
                            <h4 className="font-18-social">
                              {planDescription.name}
                            </h4>
                          </div>
                          <div className="d-flex justify-content-between text-grey gap-3 align-items-center pt-3">
                            <span className="font-16-social">Total</span>
                            <h4 className="font-18-social">
                              ${planDescription.price}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="self-summary  border-0 rounded-4 p-4 d-flex flex-column">
                    <p className="font-weight-700 text-white">
                      Plus Subscription Benefits: Smokin’Singles
                    </p>
                    <div className="auth-subscription mt-0">
                      <ul>
                        {(planDescription.description || []).map(
                          (data, index) => {
                            return (
                              <li key={index}>
                                <h4 className="font-16-social text-grey font-weight-400 line-height-20px">
                                  {data}
                                </h4>
                              </li>
                            );
                          }
                        )}
                      </ul>
                      <div className="auth-input h-auto bg-transparent text-dark mb-3 p-3">
                        <div className="d-flex flex-column">
                          <div className="d-flex justify-content-between text-grey gap-3 align-items-center border-bottom border-grey pb-3 flex-wrap">
                            <span className="font-16-social">
                              Subscription Type
                            </span>
                            <h4 className="font-18-social">
                              {planDescription.name}
                            </h4>
                          </div>
                          <div className="d-flex justify-content-between mt-2 text-grey gap-3 align-items-center border-bottom border-grey pb-3 flex-wrap">
                            <span className="font-16-social">
                            type
                            </span>
                            <h4 className="font-18-social">
                              {planDescription.type}
                            </h4>
                          </div>
                          <div className="d-flex justify-content-between text-grey gap-3 align-items-center pt-3">
                            <span className="font-16-social">Total</span>
                            <h4 className="font-18-social">
                              ${planDescription.price}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ReactCardFlip>
              </div>
              {/* {modalCheck && (
            <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-4 pt-3">
              <button
                className="green-btn w-max-content px-5"
                onClick={() => setModalShow(true)}
                type="button"
              >
                Subscribe Now
              </button>
            </div>
          )} */}
              {!isSelectAlreadySubscribed && (
                <>
                  <div className="checkbox-container mt-4">
                    <input
                      type="checkbox"
                      id="head-checkbox"
                      onChange={() =>
                        settermsAndPrivacyCheck(!termsAndPrivacyCheck)
                      }
                    />
                    <label htmlFor="head-checkbox">
                      {/* <span className="ps-2 font-16-100 font-weight-600">
                I have read and understand the terms and conditions as well as
                the privacy policy.
              </span> */}
                      <span className="ps-2 font-14 text-grey font-weight-500">
                        I have read and understand the
                        <span className="text-primary-green font-weight-700 px-1">
                          <Link
                            className="text-primary-green font-weight-700"
                            to="/social/socialterms"
                          >
                            terms and conditions
                          </Link>
                        </span>
                        as well as the
                        <span className="text-primary-green font-weight-700">
                          <Link
                            className="text-primary-green font-weight-700"
                            to="/social/socialpolicy"
                          >
                            {" "}
                            Privacy policy.
                          </Link>
                        </span>
                      </span>
                    </label>
                  </div>
                  {modalCheck && termsAndPrivacyCheck && (
                    <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-4 pt-3">
                      <button
                        className="green-btn w-max-content px-5"
                        onClick={() => setModalShow(true)}
                        type="button"
                      >
                        Subscribe Now
                      </button>
                    </div>
                  )}
                  {freeModalCheck && termsAndPrivacyCheck && (
                    <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-4 pt-3">
                      <button
                        className="green-btn w-max-content px-5"
                        onClick={() => handleFreeSubscription()}
                        type="button"
                      >
                        Subscribe Now
                      </button>
                    </div>
                  )}
                </>
              )}
              {isSelectAlreadySubscribed && (
                <p className="font-14 font-weight-400 text-light-grey mt-3 text-center">
                  You have already subscribed to this plan
                </p>
              )}
              <Slider className="my-3" ref={sliderRef} {...settings}>
                {allPlans.length > 0 &&
                  allPlans.map((plan, index) => {
                    if (plan.type === "social" || plan.type === "combine") {
                      const planIds = (substype || [])?.map(
                        (obj) => obj.planId
                      );
                      return (
                        <label
                          key={index}
                          className={`btn subscription-offer p-0 w-100 font-14 bg-grey d-flex align-items-center ${
                            planIds?.includes(plan._id) && "active"
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            id={plan._id}
                            autoComplete="off"
                            readOnly
                            checked={
                              type === plan._id || plan._id === substype?.planId
                            }
                            onClick={(e) => handleChange(e, plan)}
                            value={plan._id}
                          />
                          <div
                            className={`subs p-4 w-100 d-flex justify-content-center align-items-center text-white position-relative ${
                              plan._id === substype?.planId
                                ? "active text-dark-black"
                                : "border-grey"
                            }`}
                          >
                            <div
                              className="slider-arrow-btn start-10"
                              onClick={() => sliderRef.current.slickPrev()}
                            >
                              <i className="fa-solid fa-arrow-left"></i>
                            </div>
                            <div
                              className="slider-arrow-btn end-10"
                              onClick={() => sliderRef.current.slickNext()}
                            >
                              <i className="fa-solid fa-arrow-right"></i>
                            </div>
                            <p className="font-32-social font-sm-24 font-weight-700 border-bottom border-grey pb-3 text-capitalize">
                              {`$${plan.price}${
                                plan?.interval ? ` / ${plan?.interval}` : ""
                              }`}
                            </p>
                            <div className="px-3 pt-3">
                              <p className="font-18-social font-weight-700">
                                {plan.name}
                              </p>
                              {/* <p className="font-16-social font-weight-400">
                              {`SAVE ${plan.discount}`}
                            </p> */}
                            </div>
                          </div>
                        </label>
                      );
                    }
                  })}
              </Slider>

              {/* <div
            className="btn-group btn-group-toggle my-4 flex-column gap-4 w-100"
            data-toggle="buttons"
          >
            <h3 className="font-weight-700 font-24 mb-4 ">
              Additional Direct Message Plans:
            </h3>
            {messagesPlans?.length > 0 &&
              messagesPlans?.map((plan, index) => {
                if (plan.pricingType === "message") {
                  return (
                    <label
                      key={index}
                      className={`btn subscription-offer p-0 w-100 font-14 bg-grey d-flex align-items-center `}
                    >
                      <input
                        type="radio"
                        name="options"
                        id={plan._id}
                        autoComplete="off"
                        readOnly
                        checked={
                          type === plan._id || plan._id === substype?.planId
                        }
                        onChange={handleMessagesPlansChange}
                        value={plan._id}
                      />
                      <div className=" p-4 w-100 d-flex justify-content-center align-items-center text-white">
                        <p className="font-32-social font-weight-700 border-right border-grey pr-3">
                          {`$${plan.price}`}
                        </p>
                        <div className="px-3">
                          <p className="font-18-social font-weight-700">
                            {plan.details}
                          </p>

                        </div>
                      </div>{" "}
                    </label>
                  );
                }
              })}
          </div> */}
            </form>
          </Tab>
        </Tabs>

        {
          <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-4 pt-3">
            <p className="font-14 font-weight-400 text-light-grey mt-3 text-center">
              Continue with existing plan for now 
            </p>
            <button
              className="green-btn w-max-content px-5"
              onClick={submitHandler}
            >
              Next
            </button>
          </div>
        }
        {currentUserData.isPremium && (
          <p className="font-14 font-weight-400 text-light-grey mt-3 text-center">
            Recurring billing, cancel anytime
          </p>
        )}
      </div>
    </React.Fragment>
  );
};

export default SocialSubscription;
