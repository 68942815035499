import { toast } from 'react-toastify';
import Axios from '../axios/Axios';
import axios from 'axios';
const VerifyAgeUrl = `${process.env.REACT_APP_API_URI}users/ageVerify`;

const LoginUrl = `${process.env.REACT_APP_API_URI}users/login`;
const PostResponseUrl = `${process.env.REACT_APP_API_URI}userItem`;
const PostDispensaryUrl = `${process.env.REACT_APP_API_URI}dispensary`;
const PostCannabisUrl = `${process.env.REACT_APP_API_URI}cannabisLounge`;
const PostHeadShopUrl = `${process.env.REACT_APP_API_URI}headShop`;
const PostGrowndepotUrl = `${process.env.REACT_APP_API_URI}growDepot`;
const PostSeedStoreUrl = `${process.env.REACT_APP_API_URI}seedStore`;
const PatchDispensaryUrl = `${process.env.REACT_APP_API_URI}dispensary`;
const PatchCannabisUrl = `${process.env.REACT_APP_API_URI}cannabis`;
const PatchHeadShopUrl = `${process.env.REACT_APP_API_URI}headshop`;
const PatchGrowndepotUrl = `${process.env.REACT_APP_API_URI}growdepot`;
const PatchSeedStoreUrl = `${process.env.REACT_APP_API_URI}seedstore`;

const FavouriteUrl = `${process.env.REACT_APP_API_URI}users/markFavourite`;
const PostReviewUrl = `${process.env.REACT_APP_API_URI}users/createReview`;
const CreateChatUrl = `${process.env.REACT_APP_API_URI}conversations`;
const CreatePromptMessageUrl = `${process.env.REACT_APP_API_URI}conversations/prompt-message`;

const PostMessageUrl = `${process.env.REACT_APP_API_URI}messages`;
const PostMediaUrl = `${process.env.REACT_APP_API_URI}messages/media_message`;

const SocialSignUpUrl = `${process.env.REACT_APP_API_URI}users/socialSignup`;

export const VerifyAge = async (data, navigate, googleEmail = null) => {
  try {
    localStorage.clear();
    const postData = await Axios.post(VerifyAgeUrl, data);
    sessionStorage.setItem('remember-age', JSON.stringify(postData.data));
    localStorage.setItem('signupData', JSON.stringify(data));
    navigate('/signup', {
      state: {
        googleEmail
      }
    });
    toast.success('Age Verified Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};

export const PostLoginData = async (loginDetails, rememberCheck, navigate) => {
  try {
    const fetchData = await Axios.post(LoginUrl, loginDetails);
    localStorage.clear();

    if (rememberCheck) {
      sessionStorage.setItem('remember-user', rememberCheck ? JSON.stringify(fetchData.data?.data?.user) : '');
    } else {
      sessionStorage.removeItem('remember-user');
    }

    localStorage.setItem('user-token', fetchData?.data?.token);
    localStorage.setItem('userdata', JSON.stringify(fetchData?.data?.data?.user));
    toast.success('Welcome');
    console.log(fetchData?.data?.data?.user?.userType);
    if (fetchData?.data?.data?.user?.userType === 'retailer') {
      navigate('/home/retailer-dashboard');
    } else {
      navigate('/home');
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    console.log(error);
  }
};

export const PostSignUp = async (signInDetails, formData, navigate, googleEmail = null) => {
  try {
    const SignUpUrl = !googleEmail
      ? `${process.env.REACT_APP_API_URI}users/signup`
      : `${process.env.REACT_APP_API_URI}users/googleSignUp`;
    const fetchData = !googleEmail ? await Axios.post(SignUpUrl, formData) : await Axios.patch(SignUpUrl, formData);
    localStorage.setItem('user-token', fetchData.data.token);
    localStorage.setItem('userdata', JSON.stringify(fetchData?.data.data.user));
    const dataSignup = localStorage.getItem('signupData') && JSON.parse(localStorage.getItem('signupData'));
    const finalData = {
      ...dataSignup,
      ...signInDetails
    };
    localStorage.setItem('signupData', JSON.stringify(finalData));
    if (signInDetails.userType === 'retailer') {
      navigate('/retailer');
    } else {
      //navigate("/subscription");
      navigate('/address');
    }
    if (!googleEmail) {
      toast.success('Signup successful, verification email has been sent');
    }
    sessionStorage.removeItem('remember-age');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
    localStorage.clear();
  }
};

export const PostRetailerType = async (RetailerTypeUrl, retailerType, navigate) => {
  try {
    const fetchData = await Axios.patch(RetailerTypeUrl, retailerType);
    localStorage.setItem('userdata', JSON.stringify(fetchData?.data?.user));
    const dataSignup = localStorage.getItem('signupData') && JSON.parse(localStorage.getItem('signupData'));
    const finalData = {
      ...dataSignup,
      ...retailerType
    };
    localStorage.setItem('signupData', JSON.stringify(finalData));
    navigate(`/address`);
    toast.success('Retailer Type Added Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};

export const PostAddress = async (adressUrl, address, navigate) => {
  console.log({ adressUrl });
  try {
    const fetchData = await Axios.patch(adressUrl, address);
    localStorage.setItem('userdata', JSON.stringify(fetchData?.data?.user));
    const dataSignup = localStorage.getItem('signupData') && JSON.parse(localStorage.getItem('signupData'));
    const finalData = {
      ...dataSignup,
      ...address,
      ...fetchData?.data?.user
    };
    localStorage.setItem('signupData', JSON.stringify(finalData));
    navigate('/subscription');
    // if (!finalData?.verified) {
    //   navigate('/');
    //   toast.error('Email not verified! Check your email');
    //   localStorage.clear();
    // } else if (finalData.userType === 'retailer') {
    //   navigate('/home/retailer-dashboard');
    // } else {
    //   navigate('/home');
    // }
    toast.success('Address Added Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};

export const PostResponse = async (newArray, navigate) => {
  try {
    await Axios.post(PostResponseUrl, newArray);
    navigate('/home');
    toast.success('Response Added Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};

export const PostSeedStore = async (data, navigate) => {
  try {
    await Axios.post(PostSeedStoreUrl, data);
    navigate('/address');
    toast.success('Seed Added Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};
export const PostSeedStoreform = async data => {
  console.log('seedBank Added');

  try {
    await Axios.post(PostSeedStoreUrl, data);
    toast.success('Seed Added Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};

export const PostDispensary = async (data, navigate) => {
  try {
    await Axios.post(PostDispensaryUrl, data);
    navigate('/address');
    toast.success('Strain Added Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};

export const getUserById = async id => {
  try {
    const data = await Axios.get(`${process.env.REACT_APP_API_URI}users/${id}`);
    localStorage.setItem('userdata', JSON.stringify(data?.data?.data?.doc));
    const res = data?.data?.data?.doc;
    return res;
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};
export const PostDispensaryform = async data => {
  try {
    await Axios.post(PostDispensaryUrl, data);
    toast.success('Strain Added Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};
export const PostHeadShop = async (data, navigate) => {
  try {
    await Axios.post(PostHeadShopUrl, data);
    navigate('/address');
    toast.success('Accessory Added Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};
export const PostHeadShopform = async data => {
  try {
    await Axios.post(PostHeadShopUrl, data);
    toast.success('Accessory Added Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};
export const PostGrowndepotform = async data => {
  try {
    await Axios.post(PostGrowndepotUrl, data);
    toast.success('Equipment Added Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};
export const PostCannabis = async (data, navigate) => {
  try {
    await Axios.post(PostCannabisUrl, data);
    navigate('/address');
    toast.success('Cannabis Added Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};
export const PostCannabisform = async (data, navigate) => {
  try {
    await Axios.post(PostCannabisUrl, data);
    navigate('/myaccount');
    toast.success('Cannabis Added Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};

export const EditUser = async (EditProfileUrl, editedData, navigate, location) => {
  try {
    const fetchData = await Axios.patch(EditProfileUrl, editedData);
    localStorage.setItem('userdata', JSON.stringify(fetchData?.data?.updateUser));
    toast.success('User Edited Successfully');
    if (location?.pathname?.includes('/social/setting')) {
      navigate(-1);
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    console.log(error);
  }
};

export const MarkFavourite = async (userId, pId, category) => {
  try {
    const data = {
      userId: userId,
      pId: pId,
      category: category
    };
    const fetchData = await Axios.post(FavouriteUrl, data);
    toast.success(fetchData.data.messgae);
  } catch (error) {
    toast.error(error?.response?.data?.message);
    console.log(error);
  }
};

export const PostReview = async (ratingData, onHide, checkUserReview, GetUserItemUrl, GetData) => {
  // console.log({ ratingData });
  try {
    await Axios.post(PostReviewUrl, ratingData);
    checkUserReview();
    GetData(GetUserItemUrl);

    onHide();
    toast.success('Review Posted Successfully');
  } catch (error) {
    toast.error(error?.response?.data?.message);
    console.log(error);
  }
};
export const CreateChat = async (senderId, receiverId, navigate, conversationType) => {
  try {
    const data = {
      senderId: senderId,
      receiverId: receiverId,
      conversationType: conversationType
    };
    const response = await Axios.post(CreateChatUrl, data);
    // toast.success(response?.data?.message);
    localStorage.setItem('receiverId', receiverId);
    navigate(
      `/chat/${
        Array.isArray(response?.data?.newConversation)
          ? response?.data?.newConversation[0]?._id
          : response?.data?.newConversation?._id
      }`,
      {
        state: {
          currentChat: Array.isArray(response?.data?.newConversation)
            ? response?.data?.newConversation[0]
            : response?.data?.newConversation
        }
      }
    );
  } catch (error) {
    toast.error(error?.response?.data?.message);
    console.log(error);
  }
};
export const CreatePromptMessage = async (senderId, receiverId, productId, category, navigate, conversationType) => {
  try {
    const data = {
      senderId: senderId,
      receiverId: receiverId,
      category: category ? category : 'userItem',
      productId: productId,
      conversationType: conversationType
    };

    const response = await Axios.post(CreatePromptMessageUrl, data);
    toast.success(response?.data?.message);
    navigate(
      `/chat/${
        Array.isArray(response?.data?.newConversation)
          ? response?.data?.newConversation[0]?._id
          : response?.data?.newConversation?._id
      }`,
      {
        state: {
          currentChat: Array.isArray(response?.data?.newConversation)
            ? response?.data?.newConversation[0]
            : response?.data?.newConversation
        }
      }
    );
    localStorage.setItem('receiverId', receiverId);
  } catch (error) {
    toast.error(error?.response?.data?.message);
    console.log(error);
  }
};
export const PostMessage = async (sendMessage, token) => {
  try {
    console.log(sendMessage);
    await axios.post(PostMessageUrl, sendMessage, {
      headers: { Authorization: `Bearer ${token}` }
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    console.log(error);
  }
};

export const PostMedia = async (sendMedia, token) => {
  try {
    console.log(sendMedia);
    await axios.post(PostMediaUrl, sendMedia, {
      headers: { Authorization: `Bearer ${token}` }
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    console.log(error);
  }
};

// Social
export const SocialPostSignUp = async (signUpDetails, navigate) => {
  try {
    const fetchData = await Axios.post(SocialSignUpUrl, signUpDetails);
    console.log(fetchData);
    if (fetchData?.data?.hasOwnProperty('checkUser')) {
      localStorage.setItem('userdata', JSON.stringify(fetchData?.data?.checkUser));
    } else {
      localStorage.setItem('userdata', JSON.stringify(fetchData?.data?.data?.user));
      localStorage.setItem('user-token', fetchData?.data?.token);
    }

    navigate('/social/summary');
    toast.success('Sign up Successful');
    sessionStorage.removeItem('remember-age');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
    localStorage.clear();
  }
};

export const createSubscription = (data, route, navigate, setModalShow, setsubsType) => {
  Axios.post(`${process.env.REACT_APP_API_URI}subscription`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem('user-token')}` }
  })
    .then(async response => {
      toast.success(response?.data?.message);
      setModalShow(false);
      if (route !== '') {
        if (route == '/') {
          const userData = localStorage.getItem('signupData') && JSON.parse(localStorage.getItem('signupData'));
          if (!userData?.verified) {
            navigate('/');
            toast.error('Email not verified! Check your email');
            localStorage.clear();
          } else if (userData.userType === 'retailer') {
            navigate('/home/retailer-dashboard');
          } else {
            navigate('/home');
          }
        } else {
          navigate(route);
        }
      }
      Axios.get(
        `${process.env.REACT_APP_API_URI}subscription/user-subscription/${
          JSON.parse(localStorage.getItem('userdata'))._id
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user-token')}`
          }
        }
      )
        .then(response => {
          setsubsType(response.data.userSubscriptions);
        })
        .catch(error => {
          console.log(error?.response?.data);
        });

      // const confirmPayment = await stripe?.confirmCardPayment(
      //   response.clientSecret
      // );
      // console.log(response);
      // console.log(confirmPayment);
      // if (confirmPayment?.error) {
      //   toast.error(confirmPayment.error.message);
      // } else {
      //   toast.success(response?.data?.message);
      //   setModalShow(false);
      //   console.log(route);
      //   if (route !== "") {
      //     navigate(route);
      //   }
      // }
    })
    .catch(error => {
      setModalShow(false);
      toast.error(error.response?.data?.message);
      console.log(error);
    });
};
export const createFurthurSubscription = (data, navigate, setModalShow, route) => {
  Axios.post(`${process.env.REACT_APP_API_URI}paid/purchase-pricing`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('user-token')}`
    }
  })
    .then(response => {
      toast.success(response?.data?.message);
      setModalShow(false);
      // navigate(route);
    })
    .catch(error => {
      toast.error(error.response?.data?.message);
      console.log(error);
    });
};

export const googleLogin = (data, navigate) => {
  Axios.post(`${process.env.REACT_APP_API_URI}users/google-login`, data)
    .then(response => {
      if (!response.data.data.url.includes('platform')) {
        localStorage.setItem('userdata', JSON.stringify(response.data.data.user));
        localStorage.setItem('user-token', response.data.token);
      }
      navigate(response.data.data.url);
    })
    .catch(error => {
      console.log(error);
      toast.error(error.response?.data?.message);
    });
};

export const PatchSeedStoreform = async (data, itemId) => {
  try {
    await Axios.patch(PatchSeedStoreUrl + `/${itemId}`, data);
    toast.success('Seed Updated Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};

export const PatchDispensaryform = async (data, itemId) => {
  try {
    await Axios.patch(PatchDispensaryUrl + `/${itemId}`, data);
    toast.success('Strain Updated Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};

export const PatchHeadShopform = async (data, itemId) => {
  try {
    console.log(data);
    await Axios.patch(PatchHeadShopUrl + `/${itemId}`, data);
    toast.success('Accessory Updated Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};

export const PatchGrowndepotform = async (data, itemId) => {
  try {
    await Axios.patch(PatchGrowndepotUrl + `/${itemId}`, data);
    toast.success('Equipment Updated Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};

export const PatchCannabisform = async (data, itemId) => {
  try {
    await Axios.patch(PatchCannabisUrl + `/${itemId}`, data);
    toast.success('Cannabis Updated Successfully');
  } catch (error) {
    toast.error(error.response?.data?.message);
    console.log(error);
  }
};
