import React from 'react'

const ConcreteIcon = () => {
    return (
        <>
        <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.05592 11.8395C1.75651 11.1391 2.66538 10.7983 3.59183 10.7794C5.50424 10.7605 7.32198 10.0222 8.68528 8.67819C10.0486 7.31524 10.7681 5.47904 10.787 3.56712C10.7934 3.06059 10.9062 2.56106 11.1183 2.10098C11.3303 1.6409 11.6368 1.23057 12.0178 0.896648C13.3635 -0.295937 15.4085 -0.295937 16.7528 0.87907C17.1278 1.20365 17.4319 1.60198 17.646 2.04922C17.8602 2.49647 17.9799 2.98304 17.9977 3.47858C18.0154 3.97412 17.9309 4.468 17.7493 4.92943C17.5677 5.39086 17.293 5.80993 16.9422 6.16051C16.2416 6.86092 15.3327 7.20166 14.4063 7.22059C12.4939 7.23952 10.6761 7.97779 9.31284 9.32181C7.94953 10.6848 7.23001 12.521 7.21108 14.4329C7.20475 14.9394 7.09191 15.4389 6.87987 15.899C6.66783 16.3591 6.36134 16.7694 5.98032 17.1034C4.6346 18.2959 2.58964 18.2959 1.24662 17.1209C-0.342544 15.7201 -0.419636 13.316 1.05728 11.8395H1.05592Z" fill="#5D8B2F" />
            <path opacity="0.4" d="M16.9236 11.8397C18.3248 13.2405 18.3248 15.531 16.9236 16.9318C15.5225 18.3326 13.2313 18.3326 11.8302 16.9318C10.429 15.531 10.429 13.2405 11.8302 11.8397C13.2503 10.4389 15.5225 10.4389 16.9236 11.8397Z" fill="#5D8B2F" />
            <path opacity="0.4" d="M1.05674 6.16033C-0.344434 4.75952 -0.344434 2.469 1.05674 1.06819C2.45791 -0.332625 4.74902 -0.332625 6.15019 1.06819C7.55137 2.469 7.55137 4.75952 6.15019 6.16033C4.73009 7.56115 2.45791 7.56115 1.05674 6.16033Z" fill="#5D8B2F" />
        </svg>
        </>
    )
}

export default ConcreteIcon