import React from "react";

const MobSearchIcon = () => {
  return (
    <>
      <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M9.82031 3.27232C9.82031 2.82043 10.1866 2.4541 10.6385 2.4541H15.5479C15.9998 2.4541 16.3661 2.82043 16.3661 3.27232C16.3661 3.72422 15.9998 4.09055 15.5479 4.09055H10.6385C10.1866 4.09055 9.82031 3.72422 9.82031 3.27232Z" fill="white" />
  <path fillRule="evenodd" clipRule="evenodd" d="M9.82031 5.72838C9.82031 5.27649 10.1866 4.91016 10.6385 4.91016H13.0932C13.5451 4.91016 13.9114 5.27649 13.9114 5.72838C13.9114 6.18027 13.5451 6.5466 13.0932 6.5466H10.6385C10.1866 6.5466 9.82031 6.18027 9.82031 5.72838Z" fill="white" />
  <path fillRule="evenodd" clipRule="evenodd" d="M8.59133 1.63644C4.74756 1.63644 1.63644 4.74756 1.63644 8.59133C1.63644 12.4351 4.74756 15.5462 8.59133 15.5462C12.4351 15.5462 15.5462 12.4351 15.5462 8.59133C15.5462 8.13944 15.9126 7.77311 16.3644 7.77311C16.8163 7.77311 17.1827 8.13944 17.1827 8.59133C17.1827 13.3389 13.3389 17.1827 8.59133 17.1827C3.84378 17.1827 0 13.3389 0 8.59133C0 3.84378 3.84378 0 8.59133 0C9.04323 0 9.40956 0.366331 9.40956 0.818222C9.40956 1.27011 9.04323 1.63644 8.59133 1.63644Z" fill="white" />
  <path fillRule="evenodd" clipRule="evenodd" d="M14.9662 14.9672C15.2858 14.6477 15.8038 14.6477 16.1234 14.9672L17.7598 16.6036C18.0793 16.9232 18.0793 17.4412 17.7598 17.7608C17.4403 18.0803 16.9222 18.0803 16.6027 17.7608L14.9662 16.1243C14.6467 15.8048 14.6467 15.2867 14.9662 14.9672Z" fill="white" />
</svg>

    </>
  );
};

export default MobSearchIcon;
