
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
function SocialPolicy() {
    const [terms] = useState(false);
    const [privacy] = useState(false);

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    const [userToken, setuserToken] = useState();
    useEffect(() => {
        const currentUser = localStorage.getItem("user-token-signup");
        setuserToken(currentUser);
    }, []);

    const submitHandler = (e) => {
        e.preventDefault();
        if (terms && privacy) {
            localStorage.setItem("user-token", userToken);
            navigate("/home");
        }
        if (terms && !privacy) {
        }
        if (!terms && privacy) {
        }
    };
    return (
        <div className="max-width-792">
            <h2 className="auth-model-heading mb-4 pb-3">
                Smokin'Singles Privacy Policy
            </h2>
            <form onSubmit={(e) => submitHandler(e)}>
                <p className="text-grey font-16 text-justify">
                    <strong className="text-white"> Information Assimilation and Utilization</strong> <br />
                    <br /><strong>1.1 Purpose and Scope</strong> <br />
                    Smokin'Singles, hereinafter referred to as "The Entity," hereby delineates its comprehensive policy concerning the acquisition, collation, and deployment of personal and non-personal information gathered through its platforms. This policy seeks to elucidate the broad contours of how The Entity utilizes information for operational efficiencies and to foster synergies with allied third-party organizations.
                    <br /><strong>1.2 Data Utilization</strong> <br />
                    Users, while engaging with The Entity, unequivocally grant irrevocable rights to extract, analyze, and leverage information for purposes including but not confined to profit generation, marketing amplification, and the creation of affiliative partnerships with third-party entities, as deemed prudent by The Entity.
                    <br /><strong className="text-white">Data Extirpation Procedure</strong> <br />
                    <br /><strong>2.1 Procedure Overview</strong> <br />
                    Upon the initiation of account deletion procedures, the user data repository will undergo a meticulously coordinated process of data elimination. This process guarantees a purgation of identifiable information within a thirty-day window, except in instances where logistical constraints necessitate an extension of the aforementioned period.
                    <br /><strong>2.2 Exceptions</strong> <br />
                    In unforeseen circumstances, where administrative complexities arise, The Entity retains the right to extend the period for data extirpation to ensure the integrity and thoroughness of the deletion process.
                    <br /><strong className="text-white">Limited Liability Clause</strong> <br />
                    <br /><strong>3.1 Disclaimer</strong> <br />
                    The Entity explicitly disavows liability emanating from malevolent or unauthorized activities undertaken by members on its platform. This exemption encompasses a broad spectrum of misdeeds including, but not restricted to, identity misrepresentation, unlawful engagements, and the distribution of illicit content.
                    <br /><strong>3.2 Reporting Mechanism</strong> <br />
                    Users are encouraged to report any observed violations to facilitate prompt remedial action, fostering a safe and compliant user environment.
                </p>
                <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-5">
                    <button
                        className="green-btn-outline custom-w min-width-208"
                        onClick={() => goBack()}
                    >
                        Back
                    </button>
                </div>
            </form>
            <p className="text-center text-grey mt-5 pt-sm-0 pt-3 font-16">
                <Link className="font-16 text-grey" to="/social/socialterms">Terms of use</Link> | <Link className="font-16 text-grey" to="/social/socialpolicy">Privacy Policy</Link>
            </p>
        </div>
    )
}

export default SocialPolicy
