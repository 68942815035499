import React, { useState, useEffect } from "react";
import DatePicker from "react-date-picker";
import CalendarIcon from "../../assets/Images/Calendar";
import { useNavigate } from "react-router-dom";
import { PatchCannabisform, PostCannabisform } from "../../Api";
import UploadIcon from "../../assets/Images/Upload";
import Add1 from "../../assets/Images/match/Add1";

import { ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";

const CannbisFrom = ({
  addNewhandler,
  isUpdateModel,
  updateModelData,
  setIsUpdating,
}) => {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  // const [arrayData, setArrayData] = useState([]);
  const [productForms, setProductForms] = useState([
    {
      event: "",
      accessories: "",
      entryFee: "",
      photo: null,
      description: "",
      date: "",
    },
  ]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [yearPlaceholderText, setYearPlaceholderText] = useState("YYYY");
  const [monthPlaceholderText, setMonthPlaceholderText] = useState("MM");
  const [dayPlaceholderText, setDayPlaceholderText] = useState("DD");

  useEffect(() => {
    const currentUser = localStorage.getItem("userdata");
    const parsedUser = JSON.parse(currentUser);
    if (!isUpdateModel) {
      const savedData = JSON.parse(localStorage.getItem("cannabisData"));
      if (savedData?.length > 0 && Array.isArray(savedData)) {
        setProductForms(savedData);
      } else {
        setProductForms([
          {
            event: "",
            accessories: "",
            entryFee: "",
            date: "",
            photo: null,
            description: "",
          },
        ]);
      }
    } else {
      setFile(updateModelData?.photo[0]);
      setProductForms([
        {
          event: updateModelData.event,
          accessories: updateModelData.accessories,
          entryFee: updateModelData.entryFee,
          date: updateModelData.date,
          photo: Array.isArray(updateModelData?.photo)
            ? updateModelData?.photo[0]
            : updateModelData?.photo,
          description: updateModelData.description,
        },
      ]);
    }
  }, [isUpdateModel]);

  useEffect(() => {
    localStorage.setItem("cannabisData", JSON.stringify(productForms));
  }, [productForms]);

  useEffect(() => {
    const areAllFormsValid = productForms.every((form) => {
      const { event, accessories, entryFee, description, date } = form;
      return event && accessories && entryFee && description, date;
    });

    setIsFormValid(areAllFormsValid);
  }, [productForms]);

  const formHandler = (e, index) => {
    const { name, value } = e.target;
    const updatedProductForms = [...productForms];
    updatedProductForms[index] = {
      ...updatedProductForms[index],
      [name]: value,
    };
    setProductForms(updatedProductForms);
  };

  const dateHandler = (e, index, type) => {
    let name = type;
    const updatedProductForms = [...productForms];
    updatedProductForms[index] = {
      ...updatedProductForms[index],
      [name]: e,
    };
    setProductForms(updatedProductForms);
  };

  const handleDatePickerFocus = () => {
    setYearPlaceholderText("YYYY");
    setMonthPlaceholderText("MM");
    setDayPlaceholderText("DD");
  };

  const attachFile = (e, index) => {
    if (e.target.files) {
      let imageFile = e.target.files[0];
      const updatedProductForms = [...productForms];
      updatedProductForms[index] = {
        ...updatedProductForms[index],
        photo: imageFile,
      };
      setProductForms(updatedProductForms);
    }
  };

  const clearForm = () => {
    setProductForms([
      ...productForms,
      {
        event: "",
        accessories: "",
        entryFee: "",
        date: "",
        photo: null,
        description: "",
      },
    ]);
    setFile(null);
  };

  const removeForm = (indexToRemove) => {
    const updatedProductForms = productForms.filter(
      (_, index) => index !== indexToRemove
    );
    setProductForms(updatedProductForms);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const currentUser = localStorage.getItem("userdata");
    const parsedUser = JSON.parse(currentUser);
    const userId = parsedUser._id;
    let data = new FormData();
    productForms.forEach((mapData, index) => {
      data.append("userId", userId);
      data.append("event", mapData.event);
      data.append("accessories", mapData.accessories);
      data.append("entryFee", mapData.entryFee);
      data.append("description", mapData.description);
      data.append("date", mapData.date);
      if (Array.isArray(mapData.photo)) {
        mapData.photo.forEach((file) => data.append(`photo-${index}`, file));
      } else {
        data.append(`photo-${index}`, mapData.photo);
      }
    });

    if (isUpdateModel) {
      await PatchCannabisform(data, updateModelData._id);
    } else {
      await PostCannabisform(data, navigate);
    }

    clearForm();
    addNewhandler();
    setProductForms([
      {
        event: "",
        accessories: "",
        entryFee: "",
        date: "",
        photo: null,
        description: "",
      },
    ]);
    localStorage.removeItem("cannabisData");
  };

  return (
    <>
      <h3 className="font-32 font-weight-600 allproduct-heading mx-4">
        Post an Event
      </h3>
      <form onSubmit={(e) => submitHandler(e)} className="overflow-auto px-4">
        <div className="addMore-form">
          {productForms.map((form, index) => (
            <div key={index} className="addMore-form-border pt-5">
              {index !== 0 && (
                <div className="d-flex justify-content-end align-items-center mb-2">
                  <button
                    type="button"
                    onClick={() => removeForm(index)}
                    className="red-btn-outlines px-4 w-max-content"
                  >
                    Remove Form
                  </button>
                </div>
              )}
              <>
                <div className="d-flex flex-md-row flex-column align-items-center gap-4 justify-content-between mb-4">
                  <div className="form-control h-auto p-0 bg-transparent border-0">
                    <label className="text-Black mb-2 font-weight-600 font-18-100">
                      Lounge Accessories provider for Use
                    </label>
                    <select
                      className="auth-inputs"
                      name="accessories"
                      onChange={(e) => formHandler(e, index)}
                      required
                      value={form.accessories}
                    >
                      <option value="">- Select Option -</option>
                      <option value="Bongs">Bongs</option>
                      <option value="Rig">Rig</option>
                      <option value="Papers">Papers</option>
                      <option value="Vaporizers">Vaporizers</option>
                    </select>
                  </div>
                  <div className="form-control h-auto p-0 bg-transparent border-0">
                    <label className="text-Black mb-2 font-weight-600 font-18-100">
                      Events
                    </label>
                    <select
                      className="auth-inputs"
                      name="event"
                      onChange={(e) => formHandler(e, index)}
                      required
                      value={form.event}
                    >
                      <option value="">- Select Option -</option>
                      <option value="Music/Band">Music / Band</option>
                      <option value="ComedyShow">Comedy Show</option>
                      <option value="SeshandPaint">Sesh and Paint</option>
                      <option value="BBQ">BBQ</option>
                      <option value="Other">Other</option>
                      <option value="None">None</option>
                    </select>
                  </div>
                  <div className="form-control h-auto p-0 bg-transparent border-0">
                    <label className="text-Black mb-2 font-weight-600 font-18-100">
                      Entry Fee
                    </label>
                    <input
                      onChange={(e) => formHandler(e, index)}
                      type="number"
                      className="auth-inputs"
                      placeholder="Enter Entry Fee"
                      name="entryFee"
                    />
                  </div>
                </div>
                <div className="form-control h-auto p-0 bg-transparent border-0 mb-1">
                  <label className="text-Black mb-1 font-weight-600 font-18-100">
                    Description
                  </label>
                  <textarea
                    className="auth-inputs-textarea"
                    placeholder="Enter description here..."
                    required
                    name="description"
                    onChange={(e) => formHandler(e, index)}
                    value={form.description}
                  />
                </div>
                <div className="form-control h-auto p-0 bg-transparent border-0 mb-1">
                  <label className="text-Black mb-1 font-weight-600 font-18-100">
                    Enter Event Date
                  </label>
                  <DatePicker
                    onChange={(e) => dateHandler(e, index, "date")}
                    value={form.date}
                    name="date"
                    className={`auth-inputs `}
                    clearIcon={false}
                    format="d/M/yyyy"
                    isOpen={""}
                    yearPlaceholder={yearPlaceholderText}
                    monthPlaceholder={monthPlaceholderText}
                    dayPlaceholder={dayPlaceholderText}
                    calendarIcon={<CalendarIcon />}
                    required={true}
                    maxDate={""}
                    onFocus={handleDatePickerFocus}
                  />
                </div>

                <label className="text-black mb-2 font-weight-600 font-18-100">
                  Upload Images
                </label>
                <div className="d-flex flex-md-row flex-column align-items-center gap-4 justify-content-between mb-4">
                  <label className="upload-files cr-p">
                    <input
                      type="file"
                      className="d-none"
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => attachFile(e, index)}
                      //value={form.file}
                    />
                    <div className="d-flex justify-content-center align-items-center h-100 w-100 gap-2">
                      <UploadIcon />
                      <p className="font-16 font-weight-500">
                        {form.photo === null || form.photo === ""
                          ? "Choose File / Drag & Drop Here"
                          : form.photo.name
                          ? form.photo.name
                          : form.photo}
                      </p>
                    </div>
                  </label>
                </div>
              </>
            </div>
          ))}
        </div>
        {!isUpdateModel && (
          <div className="d-flex flex-md-row flex-column align-items-center gap-4 justify-content-between mb-4">
            {productForms?.length < 10 ? (
              <button
                className="add-mores bg-transparent border-white text-black gap-2"
                type="button"
                onClick={() => clearForm()}
                disabled={productForms?.length >= 10}
              >
                <Add1 />
                Add More Event
              </button>
            ) : (
              <ButtonToolbar
                className="add-mores bg-transparent border-white text-black p-0"
                type="button"
              >
                <OverlayTrigger placement="top" overlay={tooltip}>
                  <div className="d-flex align-items-center gap-2 w-100 h-100 justify-content-center">
                    <Add1 />
                    Add More Event
                  </div>
                </OverlayTrigger>
              </ButtonToolbar>
            )}
          </div>
        )}

        <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-end  mt-3 px-4">
          <button
            className="green-btn-outlines custom-w min-width-208"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => {
              setProductForms([
                {
                  event: "",
                  accessories: "",
                  entryFee: "",
                  photo: null,
                  description: "",
                },
              ]);

              if (setIsUpdating) setIsUpdating(false);
            }}
          >
            Cancel
          </button>
          <button
            className={`green-btn custom-w min-width-208 ${
              isFormValid ? "enabled-button" : "disabled-button"
            }`}
            type="submit"
            data-bs-dismiss="modal"
            disabled={!isFormValid}
          >
            {isUpdateModel ? "Update" : "Post"}
          </button>
        </div>
      </form>
    </>
  );
};

export default CannbisFrom;
const tooltip = (
  <Tooltip id="tooltip">You can't add more than 10 products at a time.</Tooltip>
);
