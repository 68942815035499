import React from 'react'

const EditIcon = () => {
    return (
        <>
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.06861 2.34009C3.58325 0.825447 5.82583 0.271484 8.62867 0.271484H10.7858C11.3815 0.271484 11.8644 0.754383 11.8644 1.35007C11.8644 1.94575 11.3815 2.42865 10.7858 2.42865H8.62867C6.03859 2.42865 4.50612 2.95327 3.59396 3.86544C2.68179 4.77761 2.15717 6.31007 2.15717 8.90015V15.3717C2.15717 17.9617 2.68179 19.4942 3.59396 20.4064C4.50612 21.3185 6.03859 21.8432 8.62867 21.8432H15.1002C17.6902 21.8432 19.2227 21.3185 20.1349 20.4064C21.0471 19.4942 21.5717 17.9617 21.5717 15.3717V13.2145C21.5717 12.6188 22.0546 12.1359 22.6503 12.1359C23.2459 12.1359 23.7288 12.6188 23.7288 13.2145V15.3717C23.7288 18.1745 23.1749 20.4171 21.6602 21.9317C20.1456 23.4464 17.903 24.0003 15.1002 24.0003H8.62867C5.82583 24.0003 3.58325 23.4464 2.06861 21.9317C0.553962 20.4171 0 18.1745 0 15.3717V8.90015C0 6.09731 0.553962 3.85474 2.06861 2.34009Z" fill="#5D8B2F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M18.5211 0.0174877C19.8292 -0.110915 21.0949 0.469991 22.3121 1.68725C23.5294 2.90452 24.1103 4.17015 23.9819 5.47826C23.859 6.72979 23.1073 7.74563 22.3121 8.5408L13.8129 17.04L13.8003 17.0524C13.5322 17.3119 13.1898 17.5319 12.8672 17.6941C12.547 17.855 12.1595 18.0026 11.779 18.057L8.53248 18.5208L8.52699 18.5215C7.65573 18.6414 6.80182 18.4049 6.19644 17.802C5.58957 17.1976 5.35071 16.3425 5.47887 15.4651C5.47898 15.4643 5.47909 15.4636 5.47921 15.4628L5.94191 12.2238C5.942 12.2232 5.9421 12.2225 5.94219 12.2218C5.99618 11.8372 6.14288 11.4472 6.3053 11.1241C6.46816 10.8001 6.69158 10.4542 6.95934 10.1865L15.4586 1.68725C16.2538 0.89208 17.2696 0.140337 18.5211 0.0174877ZM18.7319 2.16434C18.2253 2.21406 17.6556 2.5409 16.9839 3.2126L8.48468 11.7118C8.42886 11.7677 8.32872 11.9018 8.23271 12.0928C8.13655 12.2841 8.08934 12.4434 8.07838 12.522L8.07789 12.5254L7.6141 15.772L7.6135 15.7761C7.56984 16.0735 7.66007 16.2152 7.71869 16.2736C7.77919 16.3338 7.92776 16.4259 8.23062 16.3848C8.23138 16.3847 8.23214 16.3846 8.2329 16.3845L11.4739 15.9215C11.5465 15.9111 11.7037 15.8646 11.8985 15.7667C12.0865 15.6722 12.2274 15.5711 12.2955 15.5067L20.7868 7.01546C21.4585 6.34376 21.7853 5.77408 21.835 5.26752C21.8792 4.81754 21.7267 4.15251 20.7868 3.2126C19.8469 2.2727 19.1818 2.12017 18.7319 2.16434Z" fill="#5D8B2F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.7135 2.63016C15.2871 2.46936 15.8824 2.80399 16.0432 3.37756C16.6639 5.5917 18.3973 7.32745 20.6269 7.95917C21.2 8.12156 21.533 8.71781 21.3706 9.29093C21.2082 9.86405 20.6119 10.197 20.0388 10.0346C17.0912 9.19949 14.7907 6.90133 13.9661 3.95984C13.8053 3.38627 14.1399 2.79095 14.7135 2.63016Z" fill="#5D8B2F" />
            </svg>
        </>
    )
}

export default EditIcon