export default function swDev() {

    function determineAppServerKey() {
        var vapidPublicKey = 'BI6jpilJ828X2e_yX-M0GHDoL6x7LucQMzUDxkl6bsgEJNLEWBUyQj703DC8Se2CWVoJKpNzOK2oeKon251PRjA';
        return urlBase64ToUint8Array(vapidPublicKey);
    }

    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    let swUrl = `${process.env.PUBLIC_URL}/sw.js`;
    navigator?.serviceWorker &&
      navigator?.serviceWorker
        ?.register(swUrl)
        .then((response) => {
          console.warn("response", response);
          return response.pushManager
            .getSubscription()
            .then((subscribe) => {
              return response.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: determineAppServerKey(),
              });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });


        let swFirebaseUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;
        navigator?.serviceWorker &&
          navigator?.serviceWorker
            ?.register(swFirebaseUrl)
            .then((response) => {
              console.warn("response", response);
              return response.pushManager
                .getSubscription()
                .then((subscribe) => {
                  return response.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: determineAppServerKey(),
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
}