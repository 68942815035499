import React from 'react'

const PriceIcon = () => {
    return (
        <>
            <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.0006 7.85888V13.3277C18.0006 16.5984 16.462 18.0001 14.1542 18.0001H6.47005C5.82315 18.0001 5.23745 17.8939 4.73042 17.6603C3.69013 17.1931 2.98203 16.2267 2.72852 14.6764C3.07819 14.7719 3.45409 14.8143 3.84748 14.8143H11.5316C13.8395 14.8143 15.378 13.4127 15.378 10.142V4.67317C15.378 4.17407 15.3431 3.72811 15.2731 3.31396C16.9341 3.73873 18.0006 5.16165 18.0006 7.85888Z" fill="#5D8B2F" />
                <path opacity="0.4" d="M15.377 4.67234V10.1412C15.377 13.4118 13.8384 14.8135 11.5306 14.8135H3.84643C3.45304 14.8135 3.07714 14.7711 2.72747 14.6755C2.50892 14.6331 2.29912 14.5587 2.1068 14.4738C0.795516 13.8791 0 12.4986 0 10.1412V4.67234C0 1.40167 1.53857 0 3.84643 0H11.5306C13.4887 0 14.8962 1.0088 15.2721 3.31314C15.3333 3.7379 15.377 4.17325 15.377 4.67234Z" fill="#5D8B2F" />
                <path d="M7.68287 9.58753C8.95747 9.58753 9.99075 8.56057 9.99075 7.29377C9.99075 6.02696 8.95747 5 7.68287 5C6.40828 5 5.375 6.02696 5.375 7.29377C5.375 8.56057 6.40828 9.58753 7.68287 9.58753Z" fill="#5D8B2F" />
                <path d="M2.68494 5.33789C2.32652 5.33789 2.0293 5.6333 2.0293 5.98953V8.59606C2.0293 8.95229 2.32652 9.2477 2.68494 9.2477C3.04336 9.2477 3.34058 8.95229 3.34058 8.59606V5.98953C3.34058 5.6333 3.0521 5.33789 2.68494 5.33789Z" fill="#5D8B2F" />
                <path d="M12.6752 5.33789C12.3168 5.33789 12.0195 5.6333 12.0195 5.98953V8.59606C12.0195 8.95229 12.3168 9.2477 12.6752 9.2477C13.0336 9.2477 13.3308 8.95229 13.3308 8.59606V5.98953C13.3308 5.6333 13.0423 5.33789 12.6752 5.33789Z" fill="#5D8B2F" />
            </svg>

        </>
    )
}

export default PriceIcon