import Hooks from "../../hooks";

export const RegisteredRoutes = ({ component: Component }) => {
  const { IsUserLoggedIn } = Hooks();
  if (IsUserLoggedIn()) {
    const currentUser = localStorage.getItem("userdata");
    let data = JSON.parse(currentUser);
    if (data && data.userType === "consumer") {
      (window.location.href = "/home");
    } else {
      (window.location.href = "/home/retailer-dashboard");
    }

    return ""
  } else {
    return Component;
  }
};
