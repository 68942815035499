import React from 'react';

const PanicSection = () => {
  return (
    <div className="panic-section d-sm-block d-none">
      <div className="subdiv ">
        <div className="container mx-auto h-100">
          <div className="d-flex  justify-content-center flex-column h-100 ps-12 pe-12">
            <h1 className=" font-72 font-weight-900 text-white mb-4 pb-3">
              Cannabis Connections, Infinite Selections.
            </h1>
            <p className="description text-white font-24 font-weight-300 mb-2">
              <span className="font-weight-600">Grow and Share</span> is a
              platform for cannabis enthusiasts to swap strains and seeds within
              their community, promoting variety and cost-savings.
            </p>
            <p className="description text-white font-24 font-weight-300">
              <span className="font-weight-600">Smokin’Singles</span> is a
              dating platform tailored for cannabis enthusiasts, connecting
              individuals with shared interests in the cannabis culture, where
              users can seek friendships, romantic connections or intimate
              encounters.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanicSection;
