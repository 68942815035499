import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const location = useLocation();

  return (
    <div className="max-width-521 h-100 mx-auto d-flex justify-content-center align-items-center">
      <div className="d-flex flex-column align-items-center">
        <svg
          className="w-100 h-100"
          width="445"
          height="356"
          viewBox="0 0 445 356"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M311.39 84.3781H309.446V30.9326C309.446 22.7288 306.195 14.8609 300.409 9.05995C294.622 3.25896 286.773 4.03499e-09 278.59 4.03499e-09H165.634C161.581 -6.56046e-05 157.569 0.799964 153.825 2.35441C150.081 3.90886 146.68 6.18728 143.814 9.05959C140.949 11.9319 138.676 15.3418 137.125 19.0947C135.575 22.8476 134.776 26.87 134.776 30.9321V324.138C134.776 332.342 138.027 340.21 143.814 346.011C149.601 351.812 157.449 355.071 165.633 355.071H278.588C286.772 355.071 294.62 351.812 300.407 346.011C306.194 340.21 309.445 332.342 309.445 324.138V122.421H311.388L311.39 84.3781Z"
            fill="#3F3D56"
          />
          <path
            d="M303.118 28.8609V326.209C303.117 332.429 300.652 338.394 296.265 342.793C291.878 347.192 285.928 349.664 279.724 349.666H164.45C158.243 349.667 152.289 347.196 147.899 342.797C143.509 338.398 141.042 332.431 141.04 326.209V28.8609C141.042 22.6387 143.509 16.672 147.899 12.2731C152.289 7.87425 158.242 5.4035 164.449 5.4043H178.432C177.746 7.09693 177.484 8.93272 177.671 10.7503C177.858 12.5679 178.488 14.3116 179.504 15.8283C180.521 17.3449 181.894 18.588 183.502 19.4482C185.11 20.3085 186.904 20.7596 188.727 20.7619H254.446C256.269 20.7596 258.063 20.3085 259.671 19.4482C261.279 18.588 262.652 17.3449 263.669 15.8283C264.685 14.3116 265.315 12.5679 265.502 10.7503C265.688 8.93272 265.427 7.09693 264.74 5.4043H279.719C285.924 5.40536 291.874 7.8764 296.262 12.2742C300.65 16.6719 303.116 22.6364 303.118 28.8564V28.8609Z"
            fill="#F2F2F2"
          />
          <path
            d="M222.057 148.228C248.636 148.228 270.182 126.628 270.182 99.9839C270.182 73.3397 248.636 51.7402 222.057 51.7402C195.478 51.7402 173.932 73.3397 173.932 99.9839C173.932 126.628 195.478 148.228 222.057 148.228Z"
            fill="#ED6565"
          />
          <path
            d="M274.543 200.137H172.109C171.606 200.136 171.124 199.936 170.768 199.579C170.412 199.223 170.212 198.739 170.212 198.235V172.764C170.213 172.26 170.413 171.776 170.768 171.42C171.124 171.064 171.606 170.863 172.109 170.862H274.543C275.045 170.863 275.528 171.064 275.883 171.42C276.239 171.776 276.439 172.26 276.439 172.764V198.236C276.439 198.74 276.239 199.223 275.883 199.58C275.528 199.936 275.045 200.137 274.543 200.137V200.137ZM172.109 171.623C171.807 171.623 171.518 171.743 171.304 171.957C171.091 172.171 170.971 172.461 170.97 172.764V198.236C170.971 198.538 171.091 198.828 171.304 199.042C171.518 199.256 171.807 199.377 172.109 199.377H274.543C274.844 199.377 275.134 199.256 275.347 199.042C275.561 198.828 275.681 198.538 275.681 198.236V172.764C275.681 172.461 275.561 172.171 275.347 171.957C275.134 171.743 274.844 171.623 274.543 171.623H172.109Z"
            fill="#E6E6E6"
          />
          <path
            d="M203.789 180.036C203.437 180.036 203.099 180.176 202.85 180.426C202.601 180.676 202.461 181.014 202.461 181.367C202.461 181.72 202.601 182.059 202.85 182.309C203.099 182.558 203.437 182.698 203.789 182.698H266.387C266.739 182.712 267.082 182.585 267.34 182.345C267.599 182.105 267.751 181.772 267.765 181.419C267.778 181.066 267.652 180.722 267.412 180.463C267.173 180.204 266.841 180.051 266.489 180.038C266.469 180.037 266.45 180.037 266.43 180.037H203.789L203.789 180.036Z"
            fill="#E6E6E6"
          />
          <path
            d="M203.789 188.021C203.437 188.021 203.099 188.161 202.85 188.41C202.601 188.66 202.461 188.999 202.461 189.352C202.461 189.705 202.601 190.043 202.85 190.293C203.099 190.543 203.437 190.683 203.789 190.683H266.387C266.739 190.696 267.082 190.569 267.34 190.329C267.599 190.089 267.751 189.756 267.765 189.403C267.778 189.051 267.652 188.707 267.412 188.448C267.173 188.189 266.841 188.035 266.489 188.022C266.469 188.021 266.45 188.021 266.43 188.021H203.789L203.789 188.021Z"
            fill="#E6E6E6"
          />
          <path
            d="M274.543 242.593H172.109C171.606 242.592 171.124 242.392 170.768 242.035C170.412 241.679 170.212 241.195 170.212 240.691V215.22C170.213 214.716 170.413 214.232 170.768 213.876C171.124 213.52 171.606 213.319 172.109 213.318H274.543C275.045 213.319 275.528 213.52 275.883 213.876C276.239 214.232 276.439 214.716 276.439 215.22V240.692C276.439 241.196 276.239 241.679 275.883 242.035C275.527 242.392 275.045 242.592 274.543 242.593ZM172.109 214.079C171.807 214.079 171.518 214.199 171.304 214.413C171.091 214.627 170.971 214.917 170.97 215.22V240.692C170.971 240.994 171.091 241.284 171.304 241.498C171.518 241.712 171.807 241.833 172.109 241.833H274.543C274.844 241.833 275.134 241.712 275.347 241.498C275.561 241.284 275.681 240.994 275.681 240.692V215.22C275.681 214.917 275.561 214.627 275.347 214.413C275.134 214.199 274.844 214.079 274.543 214.079H172.109Z"
            fill="#E6E6E6"
          />
          <path
            d="M203.789 222.629C203.437 222.629 203.099 222.769 202.85 223.019C202.601 223.268 202.461 223.607 202.461 223.96C202.461 224.313 202.601 224.652 202.85 224.901C203.099 225.151 203.437 225.291 203.789 225.291H266.387C266.734 225.289 267.067 225.151 267.313 224.906C267.56 224.662 267.702 224.33 267.707 223.982C267.713 223.634 267.582 223.297 267.344 223.044C267.105 222.792 266.777 222.643 266.43 222.629H203.789V222.629Z"
            fill="#E6E6E6"
          />
          <path
            d="M203.789 230.617C203.437 230.617 203.099 230.757 202.85 231.007C202.601 231.257 202.461 231.595 202.461 231.948C202.461 232.301 202.601 232.64 202.85 232.89C203.099 233.139 203.437 233.28 203.789 233.28H266.387C266.734 233.278 267.067 233.139 267.313 232.895C267.56 232.65 267.702 232.318 267.707 231.97C267.713 231.622 267.582 231.285 267.344 231.033C267.105 230.78 266.777 230.631 266.43 230.618H203.789V230.617Z"
            fill="#E6E6E6"
          />
          <path
            d="M274.543 285.188H172.109C171.606 285.187 171.124 284.986 170.768 284.63C170.412 284.274 170.212 283.79 170.212 283.286V257.815C170.213 257.31 170.413 256.827 170.768 256.471C171.124 256.114 171.606 255.914 172.109 255.913H274.543C275.045 255.914 275.528 256.114 275.883 256.471C276.239 256.827 276.439 257.31 276.439 257.815V283.287C276.439 283.791 276.239 284.274 275.883 284.63C275.528 284.987 275.045 285.187 274.543 285.188V285.188ZM172.109 256.673C171.807 256.674 171.518 256.794 171.304 257.008C171.091 257.222 170.971 257.512 170.97 257.815V283.287C170.971 283.589 171.091 283.879 171.304 284.093C171.518 284.307 171.807 284.427 172.109 284.428H274.543C274.844 284.427 275.134 284.307 275.347 284.093C275.561 283.879 275.681 283.589 275.681 283.287V257.815C275.681 257.512 275.561 257.222 275.347 257.008C275.134 256.794 274.844 256.674 274.543 256.673H172.109Z"
            fill="#E6E6E6"
          />
          <path
            d="M203.789 265.226C203.437 265.226 203.099 265.366 202.85 265.615C202.601 265.865 202.461 266.204 202.461 266.557C202.461 266.91 202.601 267.248 202.85 267.498C203.099 267.748 203.437 267.888 203.789 267.888H266.387C266.561 267.895 266.735 267.867 266.899 267.806C267.062 267.746 267.212 267.653 267.34 267.534C267.468 267.415 267.572 267.273 267.644 267.114C267.717 266.955 267.758 266.783 267.765 266.608C267.772 266.434 267.744 266.259 267.683 266.095C267.623 265.931 267.531 265.781 267.412 265.653C267.294 265.524 267.151 265.421 266.993 265.348C266.834 265.275 266.663 265.234 266.489 265.227C266.469 265.226 266.45 265.226 266.43 265.226H203.789L203.789 265.226Z"
            fill="#E6E6E6"
          />
          <path
            d="M203.789 273.213C203.437 273.213 203.099 273.353 202.85 273.603C202.601 273.852 202.461 274.191 202.461 274.544C202.461 274.897 202.601 275.236 202.85 275.485C203.099 275.735 203.437 275.875 203.789 275.875H266.387C266.561 275.882 266.735 275.854 266.899 275.793C267.062 275.733 267.212 275.64 267.34 275.522C267.468 275.403 267.572 275.26 267.644 275.101C267.717 274.942 267.758 274.77 267.765 274.596C267.772 274.421 267.744 274.247 267.683 274.083C267.623 273.919 267.531 273.768 267.412 273.64C267.294 273.512 267.151 273.408 266.993 273.335C266.834 273.262 266.663 273.221 266.489 273.214C266.469 273.214 266.45 273.213 266.43 273.213H203.789L203.789 273.213Z"
            fill="#E6E6E6"
          />
          <path
            d="M184.89 193.331C189.29 193.331 192.857 189.755 192.857 185.344C192.857 180.933 189.29 177.357 184.89 177.357C180.49 177.357 176.923 180.933 176.923 185.344C176.923 189.755 180.49 193.331 184.89 193.331Z"
            fill="#ED6565"
          />
          <path
            d="M181.075 183.853C180.983 183.853 180.909 184.449 180.909 185.183C180.909 185.918 180.983 186.515 181.075 186.515H188.909C189.001 186.527 189.076 185.941 189.078 185.206C189.129 184.748 189.073 184.285 188.915 183.853H181.075V183.853Z"
            fill="#E6E6E6"
          />
          <path
            d="M184.89 235.942C189.29 235.942 192.857 232.366 192.857 227.955C192.857 223.544 189.29 219.969 184.89 219.969C180.49 219.969 176.923 223.544 176.923 227.955C176.923 232.366 180.49 235.942 184.89 235.942Z"
            fill="#ED6565"
          />
          <path
            d="M181.075 226.464C180.983 226.464 180.909 227.06 180.909 227.795C180.909 228.53 180.983 229.126 181.075 229.126H188.909C189.001 229.138 189.076 228.552 189.078 227.817C189.129 227.36 189.073 226.897 188.915 226.464H181.075V226.464Z"
            fill="#E6E6E6"
          />
          <path
            d="M184.89 278.537C189.29 278.537 192.857 274.961 192.857 270.55C192.857 266.139 189.29 262.563 184.89 262.563C180.49 262.563 176.923 266.139 176.923 270.55C176.923 274.961 180.49 278.537 184.89 278.537Z"
            fill="#ED6565"
          />
          <path
            d="M181.075 269.059C180.983 269.059 180.909 269.655 180.909 270.39C180.909 271.124 180.983 271.721 181.075 271.721H188.909C189.001 271.733 189.076 271.147 189.078 270.412C189.129 269.954 189.073 269.491 188.915 269.059H181.075V269.059Z"
            fill="#E6E6E6"
          />
          <path
            d="M211.661 86.7363L208.842 89.5625L232.453 113.232L235.272 110.405L211.661 86.7363Z"
            fill="white"
          />
          <path
            d="M235.271 89.5625L232.452 86.7363L208.841 110.405L211.66 113.231L235.271 89.5625Z"
            fill="white"
          />
        </svg>
        <h2 className="auth-model-heading mt-4 text-red">
          Oops! <span className="text-red">Something</span> Went{" "}
          <span className="text-red">Wrong</span>
        </h2>
        {location.pathname.includes("/social") ? (
          <div>
            <button className="green-btn custom-w min-width-208 px-4 mt-4">
              Download our application
            </button>
          </div>
        ):""}
        <button
          className="green-btn-outline w-max-content px-5 mt-4 text-primary-green d-flex gap-2 align-items-center justify-content-center"
          onClick={() => goBack()}
        >
          <svg
            width={25}
            height={16}
            viewBox="0 0 25 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H25V7H1V9Z"
              fill="#5D8B2F"
            />
          </svg>
          Back
        </button>
      </div>
    </div>
  );
};

export default NotFound;
