import React from "react";

const Premiumicon = () => {
  return (
    <svg
      width={12}
      height={12}
      className="w-max-content"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        style={{ fill: "#CCA353" }}
        d="M9.02179 12H2.97863C2.73086 12 2.52539 11.7937 2.52539 11.5448C2.52539 11.296 2.73086 11.0896 2.97863 11.0896H9.02179C9.26956 11.0896 9.47502 11.296 9.47502 11.5448C9.47502 11.7937 9.26956 12 9.02179 12Z"
        fill="#CCA353"
      />
      <path
        style={{ fill: "#CCA353" }}
        d="M11.048 1.99823L8.63076 3.73397C8.31047 3.96459 7.85119 3.82501 7.7122 3.45479L6.57004 0.396004C6.37666 -0.132001 5.63335 -0.132001 5.43997 0.396004L4.29177 3.44873C4.15277 3.82501 3.69954 3.96459 3.37925 3.7279L0.961986 1.99216C0.478534 1.65229 -0.162041 2.13175 0.0373829 2.69617L2.55134 9.76658C2.63594 10.0093 2.86558 10.1671 3.11939 10.1671H8.87853C9.13234 10.1671 9.36198 10.0033 9.44658 9.76658L11.9605 2.69617C12.166 2.13175 11.5254 1.65229 11.048 1.99823ZM7.51277 7.59994H4.49119C4.24342 7.59994 4.03795 7.39359 4.03795 7.14476C4.03795 6.89593 4.24342 6.68959 4.49119 6.68959H7.51277C7.76054 6.68959 7.96601 6.89593 7.96601 7.14476C7.96601 7.39359 7.76054 7.59994 7.51277 7.59994Z"
        fill="#CCA353"
      />
    </svg>
  );
};

export default Premiumicon;
