import React, { useEffect, useState } from 'react';
import FacebookIcon from '../../assets/Images/Facebook';
import LinkedInIcon from '../../assets/Images/LinkedIn';
import InstaIcon from '../../assets/Images/Insta';
import TwitterIcon from '../../assets/Images/Twitter';
import { Link } from 'react-router-dom';
import MailIcon from '../../assets/Images/Mail';
import DashboardLogo from '../../assets/Images/DashboardLogo';
import FooterLocationIcon from '../../assets/Images/FooterLocation';
const AppFooter = () => {

  const [currentuserData, setcurrentuserData] = useState();
  useEffect(() => {
    const currentUser = localStorage.getItem("userdata");
    let data = JSON.parse(currentUser);
    setcurrentuserData(data);
  }, []);

  return (
    <>
      <div className="app-footer ">
        <div className="container mx-auto">
          <div className="row m-0 ps-12 pe-12">
            <div className="col-md-5 col-lg-4 col-sm-12 ps-0 pe-md-5 mb-lg-0 mb-5">
              <div className="d-flex align-items-center gap-2 mb-3">
                <DashboardLogo />
                <h3 className="font-18-100 font-weight-700 text-white">
                  GROW AND SHARE
                  <p className="font-10 mt-1 ">
                    Cannabis Connections, Infinite Selections.
                  </p>
                </h3>
              </div>
              <p>
                Grow and Share is a cannabis community where members can swap
                buds and seeds with other members to diversify their cannabis
                supply and save money while also meeting people for friendship,
                dating, and intimate encounters.
              </p>
              <div className="row mt-md-5 mt-3 m-0">
                {/* <div className="col-2 ps-0">
                  <FacebookIcon />
                </div>
                <div className="col-2 ps-0">
                  <LinkedInIcon />
                </div> */}
                <div className="col-4 ps-0"></div>
                <div className="col-2 ps-0">
                  <a
                    target={"_blank"}
                    href="https://twitter.com/grow_n_share/status/1617417532574662656?lang=en"
                  >
                    <TwitterIcon />
                  </a>
                </div>
                <div className="col-2 ps-0">
                  <a
                    target={"_blank"}
                    href="https://www.instagram.com/grow.n.share"
                  >
                    <InstaIcon />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-2 col-sm-6 mb-lg-0 mb-5 ps-0 ">
              <h6 className="footer-heading">Quick Links</h6>
              <ul>
                <li>
                  <Link
                    to={
                      currentuserData?.userType === "retailer"
                        ? "/home/retailer-dashboard"
                        : "/home"
                    }
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/myaccount">My Account</Link>
                </li>
                {currentuserData?.userType !== "retailer" && (
                  <>
                    <li>
                      <Link to="/home/budseedswap">Seed / Bud Swap</Link>
                    </li>
                    <li>
                      <Link to="/favourite">Favourites</Link>
                    </li>
                  </>
                )}
              </ul>
            </div>

            <div className="col-md-4 col-xl-3 col-lg-3 col-sm-6 ps-0 mb-lg-0 mb-5">
              <h6 className="footer-heading">Contact Us</h6>
              <ul>
                <li>
                  <MailIcon />
                  info@growandshare.ca
                </li>
                <li>
                  <FooterLocationIcon />1 King St W, suite 4800 - 246, Toronto,
                  ON, M5H 1A1
                </li>
              </ul>
            </div>
            {/* <div className="col-md-12 col-xl-3 col-lg-3 col-sm-12 p-0 ">
              <h6 className="footer-heading mb-4 pb-1">Newsletter</h6>
              <p>For news and update subscribe our Newsletter</p>
              <div className=" mt-3 newsletter">
                <input
                  type="email"
                  className="border-0 outline-0 w-100"
                  placeholder="Enter Your Email"
                />
                <SendMailIcon />
              </div>
            </div> */}
            <div className="col-12 p-0">
              <pre className="copyright mt-5">
                Copyright © 2023. All Rights Reserved.
              </pre>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppFooter;
