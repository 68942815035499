import React from 'react'

const ScopeIcon = () => {
    return (
        <>
            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.99876 4.09064C6.73526 4.09064 4.08967 6.73623 4.08967 9.99973C4.08967 13.2632 6.73526 15.9088 9.99876 15.9088C13.2623 15.9088 15.9078 13.2632 15.9078 9.99973C15.9078 6.73623 13.2623 4.09064 9.99876 4.09064ZM2.27148 9.99973C2.27148 5.73208 5.7311 2.27246 9.99876 2.27246C14.2664 2.27246 17.726 5.73208 17.726 9.99973C17.726 14.2674 14.2664 17.727 9.99876 17.727C5.7311 17.727 2.27148 14.2674 2.27148 9.99973Z" fill="#5D8B2F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.99965 8.18146C8.99549 8.18146 8.18146 8.99549 8.18146 9.99965C8.18146 11.0038 8.99549 11.8178 9.99965 11.8178C11.0038 11.8178 11.8178 11.0038 11.8178 9.99965C11.8178 8.99549 11.0038 8.18146 9.99965 8.18146ZM6.36328 9.99965C6.36328 7.99134 7.99134 6.36328 9.99965 6.36328C12.008 6.36328 13.636 7.99134 13.636 9.99965C13.636 12.008 12.008 13.636 9.99965 13.636C7.99134 13.636 6.36328 12.008 6.36328 9.99965Z" fill="#5D8B2F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.0009 0C10.503 0 10.91 0.407014 10.91 0.909091V2.72727C10.91 3.22935 10.503 3.63636 10.0009 3.63636C9.49881 3.63636 9.0918 3.22935 9.0918 2.72727V0.909091C9.0918 0.407014 9.49881 0 10.0009 0Z" fill="#5D8B2F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0 9.99991C0 9.49783 0.407014 9.09082 0.909091 9.09082H2.72727C3.22935 9.09082 3.63636 9.49783 3.63636 9.99991C3.63636 10.502 3.22935 10.909 2.72727 10.909H0.909091C0.407014 10.909 0 10.502 0 9.99991Z" fill="#5D8B2F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.0009 16.3633C10.503 16.3633 10.91 16.7703 10.91 17.2724V19.0906C10.91 19.5926 10.503 19.9996 10.0009 19.9996C9.49881 19.9996 9.0918 19.5926 9.0918 19.0906V17.2724C9.0918 16.7703 9.49881 16.3633 10.0009 16.3633Z" fill="#5D8B2F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M16.3633 9.99991C16.3633 9.49783 16.7703 9.09082 17.2724 9.09082H19.0906C19.5926 9.09082 19.9996 9.49783 19.9996 9.99991C19.9996 10.502 19.5926 10.909 19.0906 10.909H17.2724C16.7703 10.909 16.3633 10.502 16.3633 9.99991Z" fill="#5D8B2F" />
            </svg>

        </>
    )
}

export default ScopeIcon