import React from "react";

const FilterIcon = () => {
  return (
    <>

      <svg
        className="w-auto h-100"
        width={40}
        height={40}
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width={50}
          height={50}
          rx="15.5"
          fill="#5D8B2F"
          fillOpacity="0.2"
          stroke="#5D8B2F"
        />
        <path
          d="M41.9999 15.2328V18.7889C41.9999 20.0821 41.186 21.6985 40.372 22.5067L33.3721 28.649C32.3954 29.4572 31.7442 31.0736 31.7442 32.3668V39.3173C31.7442 40.2872 31.0931 41.5803 30.2791 42.0652L28.0001 43.52C25.8838 44.8131 22.9536 43.3584 22.9536 40.7721V32.2051C22.9536 31.0736 22.3025 29.6189 21.6513 28.8107L20.0234 27.1134L29.4977 12H38.7442C40.5348 12 41.9999 13.4548 41.9999 15.2328Z"
          fill="#5D8B2F"
        />
        <path
          opacity="0.4"
          d="M26.8603 12L18.4279 25.4324L15.4651 22.345C14.6512 21.5368 14 20.0821 14 19.1122V15.3945C14 13.4548 15.4651 12 17.2558 12H26.8603Z"
          fill="#5D8B2F"
        />
      </svg>
    </>
  );
};

export default FilterIcon;
