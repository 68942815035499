import React from 'react'

const HeadShopIcon = () => {
    return (
        <>
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.51154 1.70316C1.89199 2.0804 1.41176 2.88641 1.41176 4.19617V20.9562C1.41176 21.8408 2.1554 22.5882 3.08888 22.5882H11.8924V6.81634C11.8924 6.3819 11.7256 5.85863 11.4195 5.373C11.1137 4.88782 10.7102 4.50583 10.3115 4.31064L5.29704 1.84859C5.29696 1.84855 5.29713 1.84863 5.29704 1.84859C4.09322 1.25935 3.13451 1.32383 2.51154 1.70316ZM1.77732 0.497346C2.93026 -0.204685 4.42249 -0.151386 5.91777 0.580613L10.9323 3.04268C10.9321 3.04259 10.9324 3.04277 10.9323 3.04268C11.6175 3.37834 12.2026 3.96774 12.6138 4.62025C13.0249 5.27248 13.3042 6.05364 13.3042 6.81634V23.2941C13.3042 23.6839 12.9881 23.9999 12.5983 23.9999H3.08888C1.40219 23.9999 0 22.6467 0 20.9562V4.19617C0 2.54696 0.620916 1.20148 1.77732 0.497346Z" fill="#707070" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12.1543 9.66704C12.3198 9.53301 12.537 9.4809 12.7454 9.52523L13.2839 9.6398L18.3643 10.7801L18.3661 10.7805L20.6556 11.2881C20.6554 11.288 20.6558 11.2881 20.6556 11.2881C21.4636 11.4656 22.2926 11.7812 22.9282 12.4221C23.5727 13.0721 23.9418 13.9777 23.9866 15.1799C23.9998 15.2862 23.9997 15.3828 23.9996 15.4428C23.9996 15.4474 23.9996 15.4518 23.9996 15.456V19.7251C23.9996 21.0399 23.6962 22.1504 22.9232 22.9234C22.1502 23.6964 21.0397 23.9998 19.7249 23.9998H12.5985C12.2086 23.9998 11.8926 23.6838 11.8926 23.2939V10.2157C11.8926 10.0027 11.9888 9.80106 12.1543 9.66704ZM13.3043 11.0913L18.0569 12.158L18.0587 12.1584L20.3527 12.6669C21.0353 12.8169 21.5619 13.0492 21.9257 13.4161C22.2736 13.767 22.5473 14.3169 22.5769 15.2635C22.5779 15.2946 22.5809 15.3256 22.586 15.3564C22.5861 15.3577 22.5866 15.3626 22.587 15.3727C22.5877 15.3913 22.5878 15.4134 22.5878 15.456V19.7251C22.5878 20.8497 22.3265 21.5236 21.925 21.9252C21.5234 22.3267 20.8495 22.588 19.7249 22.588H13.3043V11.0913Z" fill="#707070" />
                <path fillRule="evenodd" clipRule="evenodd" d="M3.98633 8.61213C3.98633 8.22228 4.30236 7.90625 4.69221 7.90625H8.61117C9.00102 7.90625 9.31705 8.22228 9.31705 8.61213C9.31705 9.00198 9.00102 9.31801 8.61117 9.31801H4.69221C4.30236 9.31801 3.98633 9.00198 3.98633 8.61213Z" fill="#707070" />
                <path fillRule="evenodd" clipRule="evenodd" d="M3.98633 13.1297C3.98633 12.7399 4.30236 12.4238 4.69221 12.4238H8.61117C9.00102 12.4238 9.31705 12.7399 9.31705 13.1297C9.31705 13.5196 9.00102 13.8356 8.61117 13.8356H4.69221C4.30236 13.8356 3.98633 13.5196 3.98633 13.1297Z" fill="#707070" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12.6886 9.77785C12.856 9.64389 13.0751 9.59324 13.2843 9.64019L18.3665 10.7809C18.6888 10.8532 18.9178 11.1393 18.9178 11.4696V15.1062C18.9178 16.8965 17.4611 18.3532 15.6708 18.3532C13.8805 18.3532 12.4238 16.8965 12.4238 15.1062V10.3289C12.4238 10.1146 12.5212 9.91181 12.6886 9.77785ZM13.8356 11.2108V15.1062C13.8356 16.1168 14.6602 16.9415 15.6708 16.9415C16.6814 16.9415 17.5061 16.1168 17.5061 15.1062V12.0346L13.8356 11.2108Z" fill="#707070" />
                <path fillRule="evenodd" clipRule="evenodd" d="M17.7699 10.9191C17.9368 10.7851 18.1555 10.7341 18.3645 10.7804L20.6559 11.2883C20.6556 11.2883 20.6561 11.2884 20.6559 11.2883C21.4638 11.4659 22.2929 11.7815 22.9284 12.4224C23.5799 13.0795 23.95 13.9978 23.9882 15.2197C23.9888 15.2389 23.9886 15.2583 23.9876 15.2775C23.901 16.9815 22.4882 18.3532 20.7529 18.3532C18.9626 18.3532 17.5059 16.8965 17.5059 15.1062V11.4696C17.5059 11.2555 17.603 11.053 17.7699 10.9191ZM18.9176 12.349V15.1062C18.9176 16.1168 19.7423 16.9414 20.7529 16.9414C21.7188 16.9414 22.5135 16.1819 22.5761 15.2333C22.5417 14.3054 22.2701 13.7636 21.9259 13.4164C21.5621 13.0495 21.0356 12.8172 20.3529 12.6672L18.9176 12.349Z" fill="#707070" />
            </svg>
        </>
    )
}

export default HeadShopIcon