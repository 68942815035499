import React from 'react'

const CrossBorderIcon = () => {
    return (
        <>
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 16C0 7.19745 7.19745 0 16 0C24.8025 0 32 7.19745 32 16C32 24.8025 24.8025 32 16 32C7.19745 32 0 24.8025 0 16ZM16 2.23256C8.43046 2.23256 2.23256 8.43046 2.23256 16C2.23256 23.5695 8.43046 29.7674 16 29.7674C23.5695 29.7674 29.7674 23.5695 29.7674 16C29.7674 8.43046 23.5695 2.23256 16 2.23256Z" fill="black" />
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0056 10.9988C21.4415 11.4348 21.4415 12.1415 21.0056 12.5775L12.5814 21.0017C12.1455 21.4376 11.4387 21.4376 11.0027 21.0017C10.5668 20.5657 10.5668 19.8589 11.0027 19.423L19.4269 10.9988C19.8629 10.5629 20.5696 10.5629 21.0056 10.9988Z" fill="black" />
                <path fillRule="evenodd" clipRule="evenodd" d="M11.0027 10.9988C11.4387 10.5629 12.1455 10.5629 12.5814 10.9988L21.0056 19.423C21.4415 19.8589 21.4415 20.5657 21.0056 21.0017C20.5696 21.4376 19.8629 21.4376 19.4269 21.0017L11.0027 12.5775C10.5668 12.1415 10.5668 11.4348 11.0027 10.9988Z" fill="black" />
            </svg>
        </>
    )
}

export default CrossBorderIcon