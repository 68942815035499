import React from 'react'

const SendMessageChatIcon = () => {
  return (
    <>
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.9785 3.01565C20.4397 2.47605 19.2341 2.10917 17.108 2.81784L6.79979 6.25393C6.79952 6.25402 6.80006 6.25384 6.79979 6.25393C5.14436 6.80775 3.99685 7.41951 3.28792 8.00537C2.57466 8.59481 2.42862 9.04571 2.42862 9.3084C2.42862 9.57071 2.57428 10.0206 3.28694 10.6083C3.99559 11.1927 5.1422 11.8022 6.79797 12.3532L9.85863 13.3734C10.2212 13.4942 10.5058 13.7788 10.6266 14.1414L11.6466 17.2014C12.1975 18.8568 12.8078 20.0039 13.3929 20.7124C13.9812 21.425 14.4324 21.5714 14.6962 21.5714C14.9599 21.5714 15.4111 21.425 15.9995 20.7124C16.5846 20.0039 17.1946 18.8574 17.7455 17.202L21.1813 6.89468C21.1811 6.89501 21.1814 6.89434 21.1813 6.89468C21.8842 4.76825 21.5188 3.55671 20.9785 3.01565ZM22.6971 1.29964C24.1877 2.79245 24.3136 5.15776 23.487 7.65722L20.0499 17.9688C20.0498 17.969 20.0499 17.9686 20.0499 17.9688C19.4471 19.78 18.7183 21.2341 17.8721 22.2588C17.0292 23.2797 15.9564 24 14.6962 24C13.436 24 12.3632 23.2797 11.5202 22.2588C10.674 21.2341 9.94537 19.7806 9.34266 17.9694C9.34259 17.9692 9.34273 17.9696 9.34266 17.9694L8.51464 15.4854L6.03119 14.6575C6.03099 14.6575 6.03139 14.6576 6.03119 14.6575C4.22039 14.0549 2.76647 13.327 1.74181 12.482C0.721051 11.6403 0 10.5685 0 9.3084C0 8.04865 0.720665 6.97635 1.74083 6.13329C2.76521 5.28674 4.21839 4.55652 6.02937 3.95075L16.3401 0.513856C18.8406 -0.319646 21.205 -0.194728 22.6971 1.29964Z"
          fill="#5D8B2F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9112 8.78415C15.386 9.25771 15.3871 10.0266 14.9136 10.5014L10.5663 14.8608C10.0928 15.3357 9.32393 15.3368 8.84905 14.8632C8.37417 14.3896 8.3731 13.6208 8.84665 13.1459L13.1939 8.78655C13.6674 8.31167 14.4363 8.3106 14.9112 8.78415Z"
          fill="#5D8B2F"
        />
      </svg>
    </>
  );
}

export default SendMessageChatIcon