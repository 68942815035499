import React from "react";

const SocialFilterIcon = () => {
  return (
    <>
      <svg
        width={30}
        height={30}
        viewBox="0 0 27 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_15_2181)">
          <path
            d="M11.1866 21.1772L13.0976 22.8346C13.5164 23.1986 13.5641 23.8366 13.2059 24.2614C13.1209 24.3622 13.0168 24.445 12.8997 24.5051C12.7826 24.5651 12.6548 24.6013 12.5237 24.6114C12.3925 24.6215 12.2607 24.6054 12.1358 24.564C12.0109 24.5226 11.8955 24.4567 11.7962 24.3701L9.5357 22.4097C9.42588 22.3144 9.33782 22.1964 9.2775 22.0637C9.21719 21.9311 9.18603 21.787 9.18616 21.6413V11.5101L0.748069 1.67475C0.183314 1.01935 0.645517 0 1.50348 0H25.4976C26.3556 0 26.8149 1.0208 26.253 1.67475L17.8034 11.5116V27.9879C17.8034 28.5476 17.3556 29 16.8038 29C16.672 28.9994 16.5416 28.9728 16.42 28.9216C16.2984 28.8705 16.1881 28.7957 16.0953 28.7018C16.0025 28.6078 15.929 28.4964 15.8791 28.3739C15.8292 28.2514 15.8038 28.1202 15.8043 27.9879V11.1331C15.8041 10.8893 15.8912 10.6536 16.0499 10.469L23.305 2.02275H3.69317L10.9411 10.469C11.0997 10.6536 11.1869 10.8893 11.1866 11.1331V21.1772Z"
            fill="#5D8B2F"
          />
        </g>
        <defs>
          <clipPath id="clip0_15_2181">
            <rect
              width={26}
              height={29}
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default SocialFilterIcon;
