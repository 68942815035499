import React from 'react'

const TwitterIcon = () => {
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.7">
                    <path d="M20 3.63958C19.2563 3.9742 18.4637 4.19599 17.6375 4.30368C18.4875 3.78317 19.1363 2.96522 19.4412 1.97932C18.6488 2.46394 17.7738 2.80625 16.8412 2.99727C16.0887 2.17548 15.0162 1.6665 13.8462 1.6665C11.5763 1.6665 9.74875 3.55625 9.74875 5.87291C9.74875 6.20625 9.77625 6.52676 9.84375 6.83189C6.435 6.66137 3.41875 4.98573 1.3925 2.43317C1.03875 3.06266 0.83125 3.78317 0.83125 4.55881C0.83125 6.01522 1.5625 7.30625 2.6525 8.05368C1.99375 8.04086 1.3475 7.84471 0.8 7.53573C0.8 7.54855 0.8 7.56522 0.8 7.58189C0.8 9.62548 2.22125 11.3229 4.085 11.7139C3.75125 11.8075 3.3875 11.8524 3.01 11.8524C2.7475 11.8524 2.4825 11.837 2.23375 11.7806C2.765 13.446 4.2725 14.6703 6.065 14.7101C4.67 15.8293 2.89875 16.5037 0.98125 16.5037C0.645 16.5037 0.3225 16.4883 0 16.446C1.81625 17.6473 3.96875 18.3332 6.29 18.3332C13.835 18.3332 17.96 11.9229 17.96 6.3665C17.96 6.18061 17.9538 6.00112 17.945 5.82291C18.7588 5.23061 19.4425 4.49086 20 3.63958Z" fill="#CCCCCC" />
                </g>
            </svg>
        </>
    )
}

export default TwitterIcon