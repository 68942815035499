import React from 'react'

const DeleteIcon = () => {
    return (
        <>
            <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M3.23513 4.12853C5.44912 3.9068 7.66346 3.7959 9.87801 3.7959C13.5892 3.7959 17.3104 3.98323 21.0084 4.34667C21.613 4.40609 22.0545 4.94029 21.9946 5.53984C21.9346 6.1394 21.396 6.57727 20.7915 6.51785C17.1635 6.1613 13.5148 5.97771 9.87801 5.97771C7.73713 5.97771 5.59604 6.08494 3.4546 6.29946L3.45138 6.29979L3.45138 6.29978L1.2074 6.51796C0.602789 6.57675 0.0646037 6.13832 0.00532828 5.5387C-0.0539471 4.93908 0.388134 4.40534 0.992744 4.34656L3.23513 4.12853Z" fill="white" />
  <path fillRule="evenodd" clipRule="evenodd" d="M8.47766 3.083L8.23575 4.51158C8.13513 5.10575 7.56788 5.50653 6.96877 5.40675C6.36965 5.30696 5.96553 4.7444 6.06615 4.15023L6.30814 2.72114C6.31271 2.69425 6.31748 2.6654 6.32238 2.63576C6.396 2.19053 6.51832 1.45072 7.0203 0.886186C7.61979 0.211988 8.51471 0 9.55993 0H12.4419C13.5036 0 14.3966 0.23058 14.9914 0.910466C15.4929 1.48355 15.6119 2.22388 15.6824 2.6623C15.6864 2.68719 15.6903 2.71111 15.694 2.73397L15.9355 4.14888C16.0368 4.74292 15.6334 5.30599 15.0344 5.40652C14.4354 5.50705 13.8677 5.10698 13.7663 4.51293L13.5232 3.08828C13.4797 2.82385 13.45 2.65568 13.4082 2.51708C13.37 2.39034 13.3386 2.35047 13.3298 2.34044C13.3298 2.3404 13.3299 2.34054 13.3298 2.34044C13.3273 2.33726 13.3014 2.30523 13.1914 2.26859C13.063 2.22583 12.8329 2.18182 12.4419 2.18182H9.55993C9.16388 2.18182 8.931 2.22413 8.80069 2.26598C8.73893 2.28581 8.70588 2.30375 8.69089 2.31313C8.6771 2.32177 8.67236 2.32698 8.67034 2.32927C8.66648 2.3336 8.63651 2.36794 8.59781 2.49564C8.55525 2.63607 8.52436 2.80804 8.47766 3.083Z" fill="white" />
  <path fillRule="evenodd" clipRule="evenodd" d="M3.39246 7.79137C3.99871 7.75256 4.52189 8.2085 4.56102 8.80974L5.27582 19.7922C5.30713 20.2352 5.33253 20.5651 5.39002 20.8482C5.44536 21.1207 5.51667 21.2708 5.58967 21.365C5.69612 21.5025 6.04362 21.8182 7.46728 21.8182H14.5292C15.9529 21.8182 16.3004 21.5025 16.4068 21.365C16.4798 21.2708 16.5512 21.1207 16.6065 20.8482C16.664 20.5651 16.6894 20.2351 16.7207 19.7921C16.7208 19.7911 16.7208 19.7902 16.7209 19.7892L17.4355 8.80974C17.4746 8.2085 17.9978 7.75256 18.6041 7.79137C19.2103 7.83018 19.67 8.34904 19.6309 8.95028L18.9155 19.9417L18.9135 19.9695C18.8849 20.375 18.852 20.8415 18.7632 21.279C18.67 21.7377 18.5021 22.2415 18.1516 22.6941C17.411 23.6502 16.1745 24 14.5292 24H7.46728C5.82197 24 4.58549 23.6502 3.84494 22.6941C3.49444 22.2415 3.3265 21.7377 3.23334 21.279C3.14451 20.8415 3.11159 20.375 3.08297 19.9695L3.08058 19.9357L2.36561 8.95028C2.32647 8.34904 2.78621 7.83018 3.39246 7.79137Z" fill="white" />
  <path fillRule="evenodd" clipRule="evenodd" d="M8.0625 16.9093C8.0625 16.3068 8.55498 15.8184 9.16249 15.8184H12.8255C13.433 15.8184 13.9255 16.3068 13.9255 16.9093C13.9255 17.5118 13.433 18.0002 12.8255 18.0002H9.16249C8.55498 18.0002 8.0625 17.5118 8.0625 16.9093Z" fill="white" />
  <path fillRule="evenodd" clipRule="evenodd" d="M7.14844 12.545C7.14844 11.9425 7.64092 11.4541 8.24843 11.4541H13.7484C14.3559 11.4541 14.8484 11.9425 14.8484 12.545C14.8484 13.1475 14.3559 13.6359 13.7484 13.6359H8.24843C7.64092 13.6359 7.14844 13.1475 7.14844 12.545Z" fill="white" />
</svg>

        </>
    )
}

export default DeleteIcon