import React from "react";

const ViewLikedUser = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width={44}
        height={44}
        viewBox="0 0 44 44"
        enableBackground="new 0 0 44 44"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <g>
              <g>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="#5D8B2F"
                  d="M25.748,9.077h-7.495c-0.879,0-2.119,0.517-2.74,1.137      l-5.298,5.298c-0.62,0.62-1.137,1.861-1.137,2.74v7.495c0,0.879,0.517,2.119,1.137,2.74l5.298,5.298      c0.62,0.62,1.861,1.137,2.74,1.137h7.495c0.879,0,2.119-0.517,2.74-1.137l5.298-5.298c0.62-0.62,1.137-1.861,1.137-2.74v-7.495      c0-0.879-0.517-2.119-1.137-2.74l-5.298-5.298C27.867,9.594,26.626,9.077,25.748,9.077z M27.439,16.565      c0.505,0.505,0.505,1.323,0,1.827l-3.609,3.609l3.609,3.609c0.505,0.505,0.505,1.323,0,1.827c-0.505,0.505-1.323,0.505-1.827,0      l-3.609-3.609l-3.609,3.609c-0.505,0.505-1.323,0.505-1.827,0c-0.505-0.505-0.505-1.323,0-1.827l3.609-3.609l-3.609-3.609      c-0.505-0.505-0.505-1.323,0-1.827c0.505-0.505,1.323-0.505,1.827,0l3.609,3.609l3.609-3.609      C26.116,16.06,26.934,16.06,27.439,16.565z"
                />
              </g>
              <g>
                <path
                  fill="#5D8B2F"
                  d="M22,44C9.869,44,0,34.131,0,22S9.869,0,22,0s22,9.869,22,22S34.131,44,22,44z M22,2      C10.972,2,2,10.972,2,22c0,11.028,8.972,20,20,20c11.028,0,20-8.972,20-20C42,10.972,33.028,2,22,2z"
                />
              </g>
            </g>
            <g>
              <rect
                x="14.833"
                y="14.086"
                fill="#5D8B2F"
                width="14.983"
                height="15.763"
              />
            </g>
            <g>
              <path fill="none" d="M8.409,8h28v28h-28V8z" />
            </g>
            <g>
              <g>
                <path
                  fill="none"
                  d="M11.714,11.716h20.571v20.571H11.714V11.716z"
                />
              </g>
            </g>
          </g>
          <path
            fill="#FFFFFF"
            d="M28,20.835h-4.833v-4.833c0-0.645-0.522-1.167-1.167-1.167s-1.167,0.522-1.167,1.167v4.833H16   c-0.645,0-1.167,0.522-1.167,1.167s0.522,1.167,1.167,1.167h4.833v4.834c0,0.645,0.522,1.167,1.167,1.167s1.167-0.522,1.167-1.167   v-4.834H28c0.645,0,1.167-0.522,1.167-1.167S28.645,20.835,28,20.835z"
          />
        </g>
      </svg>
    </>
  );
};

export default ViewLikedUser;
