import React from 'react'

const InstaIcon = () => {
    return (
        <>
            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.7" clipPath="url(#clip0_308_7729)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.464 0H15.536C18 0 20 2.004 20 4.464V15.536C20 18 18 20 15.536 20H4.464C2.004 20 0 18 0 15.536V4.464C0 2.004 2.004 0 4.464 0ZM3.52 10C3.52 13.572 6.428 16.48 10 16.48C13.572 16.48 16.48 13.572 16.48 10C16.48 6.428 13.572 3.524 10 3.524C6.428 3.524 3.52 6.428 3.52 10ZM15.244 3.332C15.244 4.088 15.86 4.7 16.616 4.7C17.372 4.7 17.988 4.088 17.988 3.332C17.988 2.576 17.372 1.96 16.616 1.96C15.86 1.96 15.244 2.576 15.244 3.332ZM6.33185 9.99981C6.33185 7.97581 7.97585 6.33181 9.99985 6.33181C12.0239 6.33181 13.6719 7.97581 13.6719 9.99981C13.6719 12.0238 12.0239 13.6718 9.99985 13.6718C7.97585 13.6718 6.33185 12.0238 6.33185 9.99981Z" fill="#CCCCCC" />
                </g>
                <defs>
                    <clipPath id="clip0_308_7729">
                        <rect width={20} height={20} fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </>
    )
}

export default InstaIcon