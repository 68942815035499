import React, { useState, useRef } from 'react';
import { Autocomplete, LoadScript } from '@react-google-maps/api';

const libraries = ['places'];

const AddressInput = ({
  isDark,
  addressValue,
  setAddressProp,
  inputLabel,
  className,
  setpostalCode,
  setLatAndLng,
  setUserAddress
}) => {
  const [address, setAddress] = useState(addressValue);
  const inputRef = useRef();

  const handlePlaceChanged = () => {
    const autocomplete = inputRef.current;
    if (autocomplete) {
      const place = autocomplete.getPlace();
      console.log(place, 'place');
      if (place) {
        setAddressProp(place.formatted_address);
        setAddress(place.formatted_address);
        const postalCodeComponent = place.address_components.find(component => component.types.includes('postal_code'));
        const countryComponent = place.address_components.find(component => component.types.includes('country'));
        const stateComponent = place.address_components.find(component =>
          component.types.includes('administrative_area_level_1')
        );
        const cityComponent = place.address_components.find(component => component.types.includes('locality'));
        const addressComponent = place.formatted_address;

        if (setUserAddress) {
          let city = '';
          let country = '';
          let state = '';
          let postal_code = '';
          let address = '';
          if (postalCodeComponent) {
            postal_code = postalCodeComponent.long_name;
          }
          if (cityComponent) {
            city = cityComponent?.long_name;
          }
          if (countryComponent) {
            country = countryComponent?.short_name;
          }
          if (stateComponent) {
            state = stateComponent?.long_name;
          }
          if (addressComponent) {
            address = addressComponent;
          }

          setUserAddress({
            address,
            city,
            state,
            country,
            postal_code
          });
        }

        if (postalCodeComponent) {
          const postalCode = postalCodeComponent.long_name;
          if (setpostalCode) {
            setpostalCode(postalCode);
          }
        }

        if (setLatAndLng) {
          const latAndLng = [place.geometry.location.lat(), place.geometry.location.lng()];
          setLatAndLng(latAndLng);
        }
      }
    }
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP} libraries={libraries} region="US">
      <Autocomplete
        onLoad={ref => (inputRef.current = ref)}
        onPlaceChanged={handlePlaceChanged}
        options={{
          componentRestrictions: { country: ['US', 'CA'] } // Restrict to the United States and Canada
        }}
      >
        <div className="form-control h-auto p-0 bg-transparent border-0 mb-4">
          <label className={` ${!isDark ? 'text-dark' : 'text-white'} mb-2 font-weight-600 font-18-100`}>
            {inputLabel || 'Address'}:
          </label>
          <input
            type="text"
            className={`auth-input ${!isDark ? 'bg-white' : ''} ${className || ''}`}
            placeholder={addressValue || 'Enter Address'}
            value={address}
            onChange={e => {
              setAddress(e.target.value);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          />
        </div>
      </Autocomplete>
    </LoadScript>
  );
};

export default AddressInput;
