import React from 'react'

function Hidepassowed() {
    return (
        <div>
            <svg width={20} height={16} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99982 6.88728C7.83173 6.88728 6.88899 7.83002 6.88899 8.99811C6.88899 9.28123 6.94414 9.5501 7.04497 9.79592L9.79763 7.04326C9.55181 6.94243 9.28294 6.88728 8.99982 6.88728ZM5.25269 8.99811C5.25269 6.92632 6.92803 5.25098 8.99982 5.25098C10.0365 5.25098 10.97 5.67144 11.6483 6.34967C11.9678 6.66917 11.9678 7.1872 11.6483 7.50671L7.50842 11.6466C7.18891 11.9661 6.67088 11.9661 6.35138 11.6466C5.67315 10.9683 5.25269 10.0348 5.25269 8.99811Z" fill="#6B6B6B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.856449 6.44112C2.8401 3.32302 5.76484 1.41553 8.99979 1.41553C10.8893 1.41553 12.6956 2.07398 14.2541 3.24953C14.6148 3.52163 14.6867 4.03465 14.4146 4.39539C14.1425 4.75613 13.6295 4.82798 13.2687 4.55588C11.9637 3.57151 10.4974 3.05183 8.99979 3.05183C6.45877 3.05183 4.00022 4.54763 2.23675 7.3199L2.23607 7.32097C1.97198 7.7347 1.81233 8.34445 1.81233 9.00388C1.81233 9.66332 1.97198 10.2731 2.23607 10.6868L2.23644 10.6874C2.84326 11.6399 3.54447 12.453 4.29709 13.1002C4.63967 13.3948 4.67855 13.9114 4.38393 14.254C4.0893 14.5966 3.57274 14.6355 3.23015 14.3408C2.34646 13.5809 1.54228 12.6431 0.856415 11.5666C0.384427 10.8268 0.176025 9.89052 0.176025 9.00388C0.176025 8.11722 0.384437 7.1809 0.856449 6.44112Z" fill="#6B6B6B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0731 5.03272C15.4252 4.74951 15.9402 4.80536 16.2234 5.15745C16.5413 5.55263 16.8545 5.97846 17.1439 6.43453C17.6154 7.17411 17.8235 8.10987 17.8235 8.99602C17.8235 9.88267 17.6151 10.819 17.1431 11.5588C15.1595 14.6769 12.2347 16.5844 8.99979 16.5844C7.87724 16.5844 6.77968 16.347 5.75332 15.9148C5.33687 15.7395 5.14142 15.2597 5.31677 14.8433C5.49211 14.4269 5.97185 14.2314 6.38829 14.4068C7.22731 14.76 8.10968 14.9481 8.99979 14.9481C11.5408 14.9481 13.9994 13.4523 15.7628 10.68L15.7635 10.6789C16.0276 10.2652 16.1872 9.65545 16.1872 8.99602C16.1872 8.33658 16.0276 7.72684 15.7635 7.31311L15.7624 7.3113C15.5118 6.91649 15.236 6.54053 14.9484 6.18301C14.6652 5.83092 14.721 5.31592 15.0731 5.03272Z" fill="#6B6B6B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0199 8.76859C12.4643 8.85053 12.7581 9.27718 12.6762 9.72154C12.4019 11.2088 11.2 12.4107 9.71275 12.685C9.26839 12.7669 8.84174 12.4731 8.7598 12.0287C8.67786 11.5844 8.97166 11.1577 9.41603 11.0758C10.236 10.9246 10.9158 10.2448 11.067 9.42481C11.1489 8.98045 11.5756 8.68665 12.0199 8.76859Z" fill="#6B6B6B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.50826 10.4916C7.82777 10.8111 7.82777 11.3291 7.50826 11.6486L1.39667 17.7602C1.07716 18.0797 0.559139 18.0797 0.239631 17.7602C-0.079877 17.4407 -0.079877 16.9227 0.239631 16.6032L6.35122 10.4916C6.67073 10.1721 7.18875 10.1721 7.50826 10.4916Z" fill="#6B6B6B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7597 0.239631C18.0792 0.559139 18.0792 1.07716 17.7597 1.39667L11.6481 7.50826C11.3286 7.82777 10.8106 7.82777 10.4911 7.50826C10.1716 7.18875 10.1716 6.67073 10.4911 6.35122L16.6027 0.239631C16.9222 -0.079877 17.4402 -0.079877 17.7597 0.239631Z" fill="#6B6B6B" />
            </svg>

        </div>
    )
}

export default Hidepassowed
