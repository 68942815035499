import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "../../App.css"
const TermsConditionsPage = () => {
  const [terms] = useState(false);
  const [privacy] = useState(false);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [userToken, setuserToken] = useState();
  useEffect(() => {
    const currentUser = localStorage.getItem("user-token-signup");
    setuserToken(currentUser);
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    if (terms && privacy) {
      localStorage.setItem("user-token", userToken);
      navigate("/home");
      toast.success("Welcome");
    }
    if (terms && !privacy) {
      toast.error("Plase Check Privacy Policy");
    }
    if (!terms && privacy) {
      toast.error("Plase Check Terms & Conditions");
    }
  };
  return (
    <div className="max-width-792">
      <h2 className="auth-model-heading mb-4 pb-3">Terms & Conditions</h2>
      <form onSubmit={(e) => submitHandler(e)}>
        <p className="text-grey font-16 text-justify p-2 overflow overflow-privacy-terms">
          Welcome to the services offered by Grow and Share. You are obliged to
          comprehensively scrutinize these Terms and Conditions (hereinafter
          referred to as “the Agreement”) prior to utilizing our services. By
          engaging with the services, website, and application facilitated by
          Grow and Share Inc. (hereinafter referred to as "the Company", "us",
          "we"), you hereby assent to the provisions laid down in this
          agreement. This Agreement delineates the legal framework governing
          your interaction with the Grow and Share's website, applications, and
          other amenities provided by us, collectively referred to as "the
          Service". The Company exclusively holds the accountability for the
          contents and operations of the Service. The term "App" refers to the
          Grow and Share application and any affiliated applications, whereas
          "Site" refers to the official website accessible at{" "}
          <a
            href="https://www.growandshare.ca/"
            target="_blank"
            rel="noreferrer"
          >
            www.growandshare.ca
          </a>{" "}
          and{" "}
          <a href="https://growandshare.ca/" target="_blank" rel="noreferrer">
            www.grownshare.ca{" "}
          </a>{" "}
          along with any successor websites. Kindly acknowledge that any
          modifications to these Terms and Conditions will be instated and
          enforced forthwith. Your usage of the Service, including attendance at
          events coordinated by us, our subsidiaries, or affiliates, confirms
          your legal eligibility within your jurisdiction to accede to this
          Agreement. The Company maintains a stringent policy against the
          circulation of objectionable material on its platforms, reserving the
          right to impose penalties up to, and including, account termination
          for violations. By engaging with the Service, you grant Grow and Share
          a limited, revocable, non-exclusive, non-transferable license for
          personal, non-commercial use. The Company vigorously enforces
          prohibitions against content and activities infringing upon
          third-party rights, including intellectual property and privacy
          rights, or promoting illegal activities such as substance abuse,
          gambling, or violence. Users are obliged to represent themselves and
          their offerings accurately within the Service. Breaches of this
          obligation, including misrepresentation of goods or engaging in
          fraudulent activities, may incur punitive actions including account
          suspension or termination. The Company maintains a steadfast
          commitment to fostering a respectful and lawful user community,
          prohibiting behaviors such as harassment, hate speech, and the
          distribution of violent or derogatory content. Please note that any
          attempts to commercialize prohibited items or activities through the
          Service are expressly forbidden. Future modifications to these
          provisions may facilitate additional avenues for legal transactions
          involving cannabis products. Your adherence to these terms is
          mandatory for utilizing the services provided by Grow and Share. The
          Company retains the right to remove or modify listings at its sole
          discretion and is not liable for any disputes arising from user
          transactions. Users are mandated to comply with all applicable laws
          pertaining to cannabis transactions and must possess the necessary
          authority and consents to post listings or engage in transactions with
          other community members. Age restrictions for accessing the Service
          are enforced in accordance with applicable jurisdictional laws. For
          inquiries or reporting issues pertaining to transactions or user
          conduct, you may contact us at{" "}
          <a href="mailto:info@growandshare.ca"> info@growandshare.ca </a>
          , providing detailed documentation of the incident for review and
          potential resolution. We reserve the right to take appropriate
          actions, including reporting to the relevant authorities, based on the
          severity of the violations as delineated in this Agreement. Thank you
          for your cooperation and adherence to these Terms and Conditions,
          which serve to foster a respectful and law-abiding community at Grow
          and Share.
          <br />
          <strong className="text-white">
            {" "}
            Transmission of Personal Data Through the Platform{" "}
          </strong>
          <br />
          The process of conveying your data through our platform is delineated
          in the Privacy Policy of Grow and Share. By transmitting your data
          through our platform, you assert, affirm, and guarantee that the
          details provided are precise and exhaustive, committing to timely
          modifications as requisite.
          <br />
          <strong className="text-white"> User Contributions</strong>
          <br />
          Any contributions made by you through various platforms, including but
          not limited to listings, profile pages, forums/blogs, message boards,
          chat interfaces, and comments, squarely rest under your purview. Grow
          and Share disclaims any governance or accountability over the misuse
          or distribution by any third party of your contributions.
          <br />
          <strong className="text-white">
            {" "}
            Registration, Authentication Details, and User Alias
          </strong>
          <br />
          Access to specific or all components of our service might necessitate
          registration. We reserve the right to disapprove or necessitate
          amendments to the username, password, or other particulars furnished
          during registration. The confidentiality of your account credentials
          must be preserved rigorously, with any account activities falling
          entirely under your responsibility. Prompt notification to us is
          imperative in the event of suspected account breach. Utilizing social
          media accounts or Google for registration implies granting us
          permission to access, use, disclose, and retain information we acquire
          from the respective network, in concordance with our privacy policy.
          <br />
          <strong className="text-white"> Oversight and Analysis</strong>
          <br />
          Grow and Share reserves the right, though not obligated, to
          scrutinize, evaluate, modify, or expunge contributions before or after
          their publication on the service, in addition to analyzing your usage
          patterns of our service. You consent to the potential sharing or
          disclosure of any information regarding your engagement with our
          service to any entity for any purpose, subject to our privacy policy.
          <br />
          <strong className="text-white"> Licensing</strong>
          <br />
          You hereby acknowledge and agree that upon the act of submitting any
          data or materials through the Service ("Submissions"), the ownership
          rights of such Submissions are transferred unequivocally to Grow and
          Share. Consequently, Grow and Share acquires an unrestricted,
          worldwide, royalty-free, fully paid-up, perpetual, irrevocable,
          transferable, and fully sublicensable right to use, disseminate,
          commercialize, and create derivative works from such Submissions,
          without any obligation to provide additional compensation to you or
          any third party. This transfer of rights extends to, but is not
          limited to, the right to reproduce, represent, adapt, modify,
          distribute, and publicly display the Submissions, in any format or
          media currently known or developed hereafter, for any purpose,
          including promotional activities, testimonials, and other commercial
          endeavors. Moreover, Grow and Share maintains the right to sell,
          lease, or share the Submissions and any derived data with third
          parties at its sole discretion. You represent and warrant that the
          Submissions are accurate, complete, and not in violation of any
          applicable laws or third-party rights. You further represent and
          warrant that you possess the necessary rights and titles to effectuate
          the transfer of rights as stipulated herein and that your provision of
          Submissions through the Service does not infringe upon any statutory
          or common law rights of any third party. By utilizing the Service, you
          unequivocally agree to these terms, acknowledging that any ideas,
          proposals, suggestions, or other materials provided by you are not
          confidential and may be utilized by Grow and Share for any purpose
          without restriction or obligation.
          <br />
          <strong className="text-white">
            {" "}
            Premium Listings and Subscription Plans
          </strong>
          <br />
          We might introduce paid enhanced features, delineated in the app and
          priced in CAD, exclusive of applicable taxes. Transactions are
          facilitated through Paypal or other third-party platforms introduced
          by us, with inquiries directed to the specified email. Immediate
          electronic delivery is effected upon purchase. Future subscription
          services with automatic renewal may be introduced, with details of
          billing periods communicated appropriately. Subscriptions can be
          managed and canceled from your personal profile within Grow and Share.
          Adjustments to fees are at our discretion, with changes applied to
          succeeding billing cycles. Unless specified otherwise, all sales are
          final, with no provision for refunds or credits.
          <br />
          <strong className="text-white">Grant of Limited Rights</strong>
          <br />
          We license the app to end-users for personal use only, barring any
          commercial engagements unless expressly permitted in writing. This
          license is limited, non-exclusive, revocable, non-transferable, and
          non-sublicensable. Any breach of the terms herein warrants immediate
          discontinuation of app usage, possibly resulting in legal
          repercussions. Compliance with system permissions is mandatory to
          maintain functionality, with users bearing responsibility for the
          necessary equipment and services to use the platform.
          <br />
          <strong className="text-white">
            Proprietary Rights of the Company
          </strong>
          <br />
          Grow and Share retains full proprietorship of the service, protected
          under pertinent rights and laws. The use of our trademarks, service
          marks, and logos is restricted to avoid potential confusion, requiring
          explicit written consent from the founders & owners of Grow and Share
          for any utilization beyond personal use.
          <br />
          <strong className="text-white">Promotional Activities</strong>
          <br />
          Participation in promotional events organized through our service is
          subject to separate rules and guidelines, which take precedence over
          this agreement in case of any conflict.
          <br />
          <strong className="text-white">Jurisdictional Constraints</strong>
          <br />
          Users must ensure the legality of accessing and utilizing our
          platforms in their jurisdiction. Restrictions based on age or regional
          laws must be adhered to, failing which users are prohibited from
          entering into this agreement or utilizing our services. Explicit
          consent to adherence to jurisdictional allowances is implied upon
          using this website.
          <br />
          <strong className="text-white">Return and Exchange Policies</strong>
          <br />
          While committed to customer satisfaction, Grow and Share disclaims
          liability for products obtained from advertisers on the platform.
          Refund or exchange requests should be directed to the respective
          vendor. In the event of disputes arising from trades, reports should
          be filed within the stipulated time to the specified email for
          resolution attempts.
          <br />
          <strong className="text-white">Disclaimer</strong>
          <br />
          Content on Grow and Share is solely for informational and educational
          purposes, with no claims or instructions pertaining to medical advice.
          Consultation with a qualified healthcare professional is recommended
          for any health-related concerns.
          <br />
          1. Disclaimer and Limitation of Liability
          <br />
          <br />
          <strong>7.1 Medical Advice Disclaimer</strong>
          <br />
          All content disseminated through the Grow and Share platform and
          affiliated mediums is provided in good faith and believed to be
          accurate according to the judgment of the author. However, the user
          bears full responsibility for seeking the counsel of a competent
          healthcare professional regarding any content found on the platform or
          its affiliate channels. Recognizing the evolving nature of medical
          advice, users should acknowledge that some information may be outdated
          or incorrect. Before using any supplements discussed on the platform,
          consult with a licensed physician. Grow and Share disclaims any
          liability for direct, indirect, consequential, special, or exemplary
          damages or any other damages arising from the use of information
          contained herein. Contents on this platform have not been evaluated by
          the Food and Drug Administration (FDA) and are not intended to
          diagnose, treat, cure, or prevent any disease.
          <br />
          <strong>7.2 Quality and Safety Disclaimer</strong>
          <br />
          Grow and Share does not warrant the completeness, accuracy,
          availability, or quality of the strains featured on community members'
          profiles within the application(s). The user agrees to use the Grow
          and Share services at their own risk. Grow and Share, in no event,
          shall be liable for any harm, including but not limited to, physical
          or mental harm, financial loss, or any other adverse events stemming
          from user content, user conduct, or representations. Additionally,
          Grow and Share entities shall not be liable for indirect, incidental,
          special, consequential or punitive damages, including but not limited
          to loss of profit, revenue, data, or goodwill.
          <br />
          <strong className="text-white">Registration</strong>
          <br />
          To utilize Grow and Share services, users must register and create an
          account. Only individuals of legal age to consume cannabis within
          their jurisdiction are eligible to register. Registration encompasses
          age verification, either in-person or through our online process, and
          is open to cannabis enthusiasts at no cost.
          <br />
          During registration, users are required to provide the following
          information:
          <br />
          <ul>
            <li>A valid email address</li>
            <li>A chosen password</li>
            <li>Age verification</li>
            <li>Method of obtaining cannabis</li>
            <li>Images of the strain being shared</li>
            <li>Nearest intersection (to facilitate easier trading)</li>
          </ul>
          Users may alternatively register using their Google account. It is
          imperative to maintain complete and accurate information and to
          safeguard the confidentiality of your password. Grow and Share retains
          the right to store and process the data supplied at registration, in
          compliance with data protection laws. Any unauthorized usage of your
          account should be reported promptly to
          <a href="mailto:info@growandshare.ca"> info@growandshare.ca </a>
          <br />
          <strong className="text-white"> Subscription Services</strong>
          <br />
          Upon successful registration, users may access additional service
          packages through various subscription tiers, available for different
          time frames as outlined in the order options.
          <br />
          <strong className="text-white">Amendments to Policy</strong>
          <br />
          Grow and Share reserves the right to amend this policy at its
          discretion. Registered users will be notified of any alterations via
          email, the web application, or through notifications on user profiles.
          The modifications will take effect immediately upon posting, and your
          continued utilization of Grow and Share services signifies your
          agreement to the revised terms.
          <br />
          <strong className="text-white"> Contact</strong>
          <br />
          For inquiries or comments concerning this policy, please reach out to{" "}
          <a href="mailto:info@growandshare.ca">
            info@growandshare.ca
          </a>{" "}
          Kindly avoid transmitting sensitive information via email due to
          potential security vulnerabilities.
        </p>
        <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-5">
          <button
            className="green-btn-outline custom-w min-width-208"
            onClick={() => goBack()}
          >
            Back
          </button>
        </div>
      </form>
      <p className="text-center text-grey mt-5 pt-sm-0 pt-3 font-16">
        <Link className="font-16 text-grey" to="/terms">
          Terms of use
        </Link>{" "}
        |{" "}
        <Link className="font-16 text-grey" to="/privacypolicy">
          Privacy Policy
        </Link>
      </p>
    </div>
  );
};

export default TermsConditionsPage;
