import React from 'react'

const AlertIcon = () => {
    return (
        <>
            <svg width={144} height={144} viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M139.311 102.113L95.1726 17.6057C89.2415 6.23536 81.0345 0 72 0C62.9654 0 54.7585 6.23536 48.8274 17.6057L4.68913 102.113C-0.897117 112.897 -1.51781 123.24 2.96498 131.383C7.44777 139.525 16.2754 144 27.8617 144H116.138C127.725 144 136.552 139.525 141.035 131.383C145.518 123.24 144.897 112.823 139.311 102.113Z" fill="#5D8B2F" />
                <path d="M72 94C69.2667 94 67 91.4892 67 88.4615V51.5385C67 48.5108 69.2667 46 72 46C74.7333 46 77 48.5108 77 51.5385V88.4615C77 91.4892 74.7333 94 72 94Z" fill="#5D8B2F" />
                <path d="M72 112C71.7 112 71.35 111.95 71 111.9C70.7 111.85 70.4 111.75 70.1 111.6C69.8 111.501 69.5 111.351 69.2 111.151C68.95 110.951 68.7 110.752 68.45 110.552C67.55 109.603 67 108.305 67 107.006C67 105.708 67.55 104.409 68.45 103.461C68.7 103.261 68.95 103.061 69.2 102.861C69.5 102.662 69.8 102.512 70.1 102.412C70.4 102.262 70.7 102.162 71 102.112C71.65 101.963 72.35 101.963 72.95 102.112C73.3 102.162 73.6 102.262 73.9 102.412C74.2 102.512 74.5 102.662 74.8 102.861C75.05 103.061 75.3 103.261 75.55 103.461C76.45 104.409 77 105.708 77 107.006C77 108.305 76.45 109.603 75.55 110.552C75.3 110.752 75.05 110.951 74.8 111.151C74.5 111.351 74.2 111.501 73.9 111.6C73.6 111.75 73.3 111.85 72.95 111.9C72.65 111.95 72.3 112 72 112Z" fill="#5D8B2F" />
            </svg>

        </>
    )
}

export default AlertIcon