import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging'

export const askNotificationPermission = async () => {
    if(await isSupported()){
        console.log("Requesting User Permission......");
        Notification?.requestPermission().then((permission) => {
            if (permission === "granted") {
                console.log("Notification User Permission Granted.");
            } else {
                console.log("User Permission Denied.");
            }
        });
    }
}



const firebaseConfig = {
    apiKey: "AIzaSyDnHRSvI0xOVtvCV_RgnRpmPJaQjjOyGBA",
    authDomain: "pwa-react-af683.firebaseapp.com",
    projectId: "pwa-react-af683",
    storageBucket: "pwa-react-af683.appspot.com",
    messagingSenderId: "27450640811",
    appId: "1:27450640811:web:54a2a67d2941feaee96aee"
};

initializeApp(firebaseConfig);

let messaging;
try {
    messaging = messaging = await isSupported() ? getMessaging() : null
} catch (err) {
    console.error('Failed to initialize Firebase Messaging', err);
}

export const requestForToken = () => {
    return getToken(messaging, { vapidKey: 'BI6jpilJ828X2e_yX-M0GHDoL6x7LucQMzUDxkl6bsgEJNLEWBUyQj703DC8Se2CWVoJKpNzOK2oeKon251PRjA' })
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                return currentToken;
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log("payload", payload)
            resolve(payload);
        });
    });



