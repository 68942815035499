import React from 'react'

const ShowPassword = () => {
    return (
        <>
            <svg width={20} height={16} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.99981 5.77975C8.67602 5.77975 7.6076 6.77364 7.6076 8.00509C7.6076 9.23654 8.67602 10.2304 9.99981 10.2304C11.3236 10.2304 12.392 9.23654 12.392 8.00509C12.392 6.77364 11.3236 5.77975 9.99981 5.77975ZM5.75317 8.00509C5.75317 5.82091 7.65184 4.05469 9.99981 4.05469C12.3478 4.05469 14.2465 5.82091 14.2465 8.00509C14.2465 10.1893 12.3478 11.9555 9.99981 11.9555C7.65184 11.9555 5.75317 10.1893 5.75317 8.00509Z" fill="#6B6B6B" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0.771126 5.29822C3.01921 2.01097 6.33383 0 10 0C13.6664 0 16.9812 2.0112 19.2293 5.29878C19.7639 6.0786 20 7.06547 20 8C20 8.93475 19.7638 9.92187 19.2289 10.7018C16.9808 13.989 13.6662 16 10 16C6.33361 16 3.01883 13.9888 0.770739 10.7012C0.23608 9.92139 0 8.93453 0 8C0 7.06524 0.236194 6.07813 0.771126 5.29822ZM10 1.72507C7.12026 1.72507 4.33398 3.30201 2.33543 6.22467L2.33465 6.2258C2.03537 6.66197 1.85443 7.30479 1.85443 8C1.85443 8.69521 2.03537 9.33803 2.33465 9.7742L2.33543 9.77533C4.33398 12.698 7.12026 14.2749 10 14.2749C12.8797 14.2749 15.666 12.698 17.6646 9.77533L17.6653 9.7742C17.9646 9.33803 18.1456 8.69521 18.1456 8C18.1456 7.30479 17.9646 6.66197 17.6653 6.2258L17.6646 6.22467C15.666 3.30201 12.8797 1.72507 10 1.72507Z" fill="#6B6B6B" />
            </svg>
        </>
    )
}

export default ShowPassword