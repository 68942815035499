import React from 'react'

const AddIcon = () => {
  return (
    <>
      <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.4541 10.0009C5.4541 9.49881 5.86112 9.0918 6.36319 9.0918H13.6359C14.138 9.0918 14.545 9.49881 14.545 10.0009C14.545 10.503 14.138 10.91 13.6359 10.91H6.36319C5.86112 10.91 5.4541 10.503 5.4541 10.0009Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M9.99991 5.45508C10.502 5.45508 10.909 5.86209 10.909 6.36417V13.6369C10.909 14.139 10.502 14.546 9.99991 14.546C9.49783 14.546 9.09082 14.139 9.09082 13.6369V6.36417C9.09082 5.86209 9.49783 5.45508 9.99991 5.45508Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M1.74354 1.74354C3.02017 0.466911 4.91034 0 7.27273 0H12.7273C15.0897 0 16.9798 0.466911 18.2565 1.74354C19.5331 3.02017 20 4.91034 20 7.27273V12.7273C20 15.0897 19.5331 16.9798 18.2565 18.2565C16.9798 19.5331 15.0897 20 12.7273 20H7.27273C4.91034 20 3.02017 19.5331 1.74354 18.2565C0.466911 16.9798 0 15.0897 0 12.7273V7.27273C0 4.91034 0.466911 3.02017 1.74354 1.74354ZM3.02919 3.02919C2.26036 3.79801 1.81818 5.08966 1.81818 7.27273V12.7273C1.81818 14.9103 2.26036 16.202 3.02919 16.9708C3.79801 17.7396 5.08966 18.1818 7.27273 18.1818H12.7273C14.9103 18.1818 16.202 17.7396 16.9708 16.9708C17.7396 16.202 18.1818 14.9103 18.1818 12.7273V7.27273C18.1818 5.08966 17.7396 3.79801 16.9708 3.02919C16.202 2.26036 14.9103 1.81818 12.7273 1.81818H7.27273C5.08966 1.81818 3.79801 2.26036 3.02919 3.02919Z" fill="white" />
      </svg>
    </>
  )
}

export default AddIcon