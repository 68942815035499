import React from "react";

const ChatCameraIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M5.55604 24H18.444C21.8381 24 23.1908 21.972 23.3507 19.5L23.9902 9.588C24.1624 6.996 22.0472 4.8 19.3786 4.8C18.6284 4.8 17.9398 4.38 17.5954 3.732L16.71 1.992C16.1443 0.9 14.6686 0 13.4142 0H10.5981C9.33141 0 7.8557 0.9 7.29001 1.992L6.40458 3.732C6.06025 4.38 5.37158 4.8 4.62142 4.8C1.95284 4.8 -0.162353 6.996 0.00981355 9.588L0.64929 19.5C0.796862 21.972 2.1619 24 5.55604 24Z"
          fill="white"
        />
        <path
          d="M13.8421 8.10078H10.1528C9.64859 8.10078 9.23047 7.69278 9.23047 7.20078C9.23047 6.70878 9.64859 6.30078 10.1528 6.30078H13.8421C14.3463 6.30078 14.7644 6.70878 14.7644 7.20078C14.7644 7.69278 14.3463 8.10078 13.8421 8.10078Z"
          fill="white"
        />
        <path
          d="M11.9964 19.3571C14.2921 19.3571 16.153 17.5412 16.153 15.3011C16.153 13.0611 14.2921 11.2451 11.9964 11.2451C9.70082 11.2451 7.83984 13.0611 7.83984 15.3011C7.83984 17.5412 9.70082 19.3571 11.9964 19.3571Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default ChatCameraIcon;
