import React from "react";

const SearchButtonIcon = () => {
  return (
    <>
      <svg
        width={40}
        height={40}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width={40} height={40} rx={10} fill="#5D8B2F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3714 12.7143C15.2471 12.7143 12.7143 15.2471 12.7143 18.3714C12.7143 21.4958 15.2471 24.0286 18.3714 24.0286C21.4958 24.0286 24.0286 21.4958 24.0286 18.3714C24.0286 15.2471 21.4958 12.7143 18.3714 12.7143ZM11 18.3714C11 14.3003 14.3003 11 18.3714 11C22.4426 11 25.7429 14.3003 25.7429 18.3714C25.7429 22.4426 22.4426 25.7429 18.3714 25.7429C14.3003 25.7429 11 22.4426 11 18.3714Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6514 22.651C22.9862 22.3162 23.5289 22.3162 23.8636 22.651L28.7493 27.5367C29.0841 27.8714 29.0841 28.4141 28.7493 28.7489C28.4146 29.0836 27.8719 29.0836 27.5372 28.7489L22.6514 23.8631C22.3167 23.5284 22.3167 22.9857 22.6514 22.651Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default SearchButtonIcon;
