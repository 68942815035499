import React from "react";

const Add1 = () => {
  return (
    <>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.45312 10.0004C5.45312 9.49832 5.86014 9.09131 6.36222 9.09131H13.6349C14.137 9.09131 14.544 9.49832 14.544 10.0004C14.544 10.5025 14.137 10.9095 13.6349 10.9095H6.36222C5.86014 10.9095 5.45312 10.5025 5.45312 10.0004Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99893 5.45459C10.501 5.45459 10.908 5.8616 10.908 6.36368V13.6364C10.908 14.1385 10.501 14.5455 9.99893 14.5455C9.49686 14.5455 9.08984 14.1385 9.08984 13.6364V6.36368C9.08984 5.8616 9.49686 5.45459 9.99893 5.45459Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.74354 1.74354C3.02017 0.466911 4.91034 0 7.27273 0H12.7273C15.0897 0 16.9798 0.466911 18.2565 1.74354C19.5331 3.02017 20 4.91034 20 7.27273V12.7273C20 15.0897 19.5331 16.9798 18.2565 18.2565C16.9798 19.5331 15.0897 20 12.7273 20H7.27273C4.91034 20 3.02017 19.5331 1.74354 18.2565C0.466911 16.9798 0 15.0897 0 12.7273V7.27273C0 4.91034 0.466911 3.02017 1.74354 1.74354ZM3.02919 3.02919C2.26036 3.79801 1.81818 5.08966 1.81818 7.27273V12.7273C1.81818 14.9103 2.26036 16.202 3.02919 16.9708C3.79801 17.7396 5.08966 18.1818 7.27273 18.1818H12.7273C14.9103 18.1818 16.202 17.7396 16.9708 16.9708C17.7396 16.202 18.1818 14.9103 18.1818 12.7273V7.27273C18.1818 5.08966 17.7396 3.79801 16.9708 3.02919C16.202 2.26036 14.9103 1.81818 12.7273 1.81818H7.27273C5.08966 1.81818 3.79801 2.26036 3.02919 3.02919Z"
          fill="black"
        />
      </svg>
    </>
  );
};

export default Add1;
