import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ShowPassword from "../../assets/Images/ShowPassword";
import Hidepassowed from "../../assets/Images/Hidepassowed";
import GoogleIcon from "../../assets/Images/Google";
import { LoginSocialGoogle } from "reactjs-social-login";
import { PostLoginData, googleLogin } from "../../Api";
import { askNotificationPermission, requestForToken } from "../../firebase";

const LoginPage = () => {
  const [loginDetails, setloginDetails] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    const user = sessionStorage.getItem("remember-user");
    const data = JSON.parse(user);
    setloginDetails({ email: data?.email });
  }, []);



  useEffect(() => {
    const rememberUser = localStorage.getItem("remember-user");
    localStorage.clear();
    if (rememberUser) {
      const data = JSON.parse(rememberUser);
      setloginDetails({ email: data?.email, password: data?.password });
      localStorage.setItem("remember-user", rememberUser);
    }
    askNotificationPermission();
    requestForToken().then((token) => {
      localStorage.setItem("firebaseToken", token);
    });
  }, []);


  const [passwordShown, setPasswordShown] = useState(false);
  const [rememberCheck, setRememberCheck] = useState(false);

  const formHandler = (e) => {
    const { name, value } = e.target;
    setloginDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const navigate = useNavigate();
  const submitHandler = (e) => {
    e.preventDefault();
    PostLoginData(
      { ...loginDetails, firebaseToken: localStorage.getItem("firebaseToken") },
      rememberCheck,
      navigate
    );

    // Save user details if "Remember me" is checked
    if (rememberCheck) {
      rememberUser();
    } else {
      // Forget user details if "Remember me" is not checked
      forgetUser();
    }
  };
  const rememberUser = () => {
    // Save user details in local storage
    localStorage.setItem("remember-user", JSON.stringify(loginDetails));
  };

  const forgetUser = () => {
    // Remove user details from local storage
    localStorage.removeItem("remember-user");
  };


  const terms = () => {
    navigate('/terms')
  }
  return (
    <>
      <div className="max-width-521 min-width-521">
        <h2 className="auth-model-heading mb-4">Welcome Back!</h2>
        <p className="auth-model-desc mb-5">Please login to your account.</p>
        <form onSubmit={(e) => submitHandler(e)}>
          <input
            className="auth-input"
            type="email"
            placeholder="Enter email"
            name="email"
            onChange={(e) => formHandler(e)}
            value={loginDetails.email}
          />

          <div className="my-3">
            <input
              name="password"
              required
              type={passwordShown ? "text" : "password"}
              placeholder="Enter password"
              className="auth-input"
              onChange={(e) => formHandler(e)}
              value={loginDetails.password}
            />{" "}
            <span
              onClick={() => {
                setPasswordShown(!passwordShown);
              }}
              className="see-pswd-btn mt-2 cr-p"
            >
              {passwordShown ? <Hidepassowed /> : <ShowPassword />}
              {/* <ShowPassword /> */}
            </span>
          </div>

          <div className="d-flex align-items-center justify-content-between mb-4 pb-3">
            <div className="checkbox-container">
              <input
                type="checkbox"
                id="head-checkbox"
                onChange={() => setRememberCheck(!rememberCheck)}
                checked={rememberCheck ? true : false} />
              <label htmlFor="head-checkbox">
                <span className="ps-2 font-16-100 font-weight-600">
                  Remember me
                </span>
              </label>
            </div>
            <Link
              to="/forgotpassword"
              className="text-white font-16-100 font-weight-600"
            >
              <u>Forget Password?</u>
            </Link>
          </div>
          <button className="green-btn">Login</button>
        </form>
        <div className="or font-18-100 font-weight-600"> or </div>

        <LoginSocialGoogle
          client_id={
            "643396070667-bfebpofn127mm7krc7c4iamdu5ejckig.apps.googleusercontent.com"
          }
          discoveryDocs="claims_supported"
          access_type="offline"
          scope="https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile"
          onResolve={({ provider, data }) => {
            const apiData = {
              access_token: data.access_token,
              email: data.email,
              firebaseToken: localStorage.getItem("firebaseToken"),
            };
            googleLogin(apiData, navigate);
          }}
          onReject={(error) => {
            console.log(error);
          }}
        >
          <button className="google-login w-100 my-4">
            <GoogleIcon />
            <span className="ps-3 font-weight-700 font-18-100">
              Login with Google
            </span>
          </button>
        </LoginSocialGoogle>
        <Link
          className="text-white d-flex justify-content-center align-items-center gap-1 font-weight-500 font-18"
          to="/age"
        >
          Don’t have an account?
          <span className="text-primary-green font-weight-700">Register</span>
        </Link>
        <p className="text-center text-grey mt-5 pt-sm-0 pt-3 font-16">
          <Link className="font-16 text-grey" to="/terms">Terms of use</Link> | <Link className="font-16 text-grey" to="/privacypolicy">Privacy Policy</Link>
        </p>
      </div>
    </>
  );
};

export default LoginPage;
