import React from "react";
import DistanceIcon from "../../assets/Images/Distance";
import CountIcon from "../../assets/Images/Count";
import RatingIcon from "../../assets/Images/Rating";
import LocationIcon from "../../assets/Images/Location";
import { useMemo } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import FavouriteIcon from "../../assets/Images/FavouriteIcon";
import Axios from "../../axios/Axios";
import EmptyDataImage from "../../assets/Images/EmptyData";
import { PaginationControl } from "react-bootstrap-pagination-control";
import ImageDummy from "../../assets/Images/match/dummy.png";
import SearchButtonIcon from "../../assets/Images/Search";
import ScopeIcon from "../../assets/Images/Scope";
import CrossBorderIcon from "../../assets/Images/CrossBorder";
// import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import MobSearchIcon from "../../assets/Images/MobSearch";
import MultiRangeSlider from "multi-range-slider-react";
import useDebounce from "../../hooks/useDebounce";
import axios from "axios";
import AddressInput from "../Address/address";
import FilterIcon from "../../assets/Images/filterIcon";
import ImageDisplay from "../Social App/ImageDisplay";

const Seeds = () => {
  const [seeds, setSeeds] = useState([]);
  const routeParams = useParams();
  const [currentuserData, setcurrentuserData] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [type, setType] = useState("Grams");
  const [isLoading, setIsLoading] = useState(false);
  const [customType, setCustomType] = useState("CustomSelect");
  const Location = useLocation();
  const handleChange = (event) => {
    setType(event.target.value);
    if (event.target.value === "All") {
      setFilter((prevState) => ({
        ...prevState,
        quantity: "",
      }));
    }
  };
  const [options, setOptions] = useState([
    {
      id: 1,
      value: "Sativa",
      label: "Sativa",
      icon: "",
      userType: "consumer",
    },
    {
      id: 2,
      value: "Indica",
      label: "Indica",
      icon: "",
      userType: "consumer",
    },
    {
      id: 3,
      value: "Hybrid",
      label: "Hybrid",
      icon: "",
      userType: "consumer",
    },
    {
      id: 4,
      value: "CBD",
      label: "CBD",
      icon: "",
      userType: "consumer",
    },
  ]);
  const [categoryFilter, setcategoryFilter] = useState([]);

  const GetSeeds = async (GetSeedsUrl) => {
    setIsLoading(true);
    try {
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      const userId = data?._id;
      const fetchData = await DashboardAxios.get(
        GetSeedsUrl.concat(`&ignoreUserId=${userId}`)
      );
      setSeeds(fetchData.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
    setIsLoading(false);
  };

  const debouncedSearchedTerm = useDebounce(searchTerm);

  useEffect(() => {
    if (searchTerm !== "") {
      localStorage.removeItem("budSeedFilter");
    }
  }, [searchTerm]);

  useEffect(() => {
    localStorage.removeItem("homeFilter");

    const savedFilter = JSON.parse(localStorage.getItem("budSeedFilter"));
    if (savedFilter) {
      setFilter(savedFilter);
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      // console.log(filter.area);
      let GetAllProductUrl = `${
        process.env.REACT_APP_API_URI
      }users/getDataByRadius?page=${page}&userType=consumer${`&radius=${savedFilter.radius}`}${
        savedFilter.quantity ? `&quantity=${savedFilter.quantity}` : ""
      }${
        savedFilter.area
          ? `&address=${savedFilter.area}`
          : `&latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}`
      }`;
      GetAllProduct(GetAllProductUrl);
    } else {
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      let GetSeedsUrl = `${process.env.REACT_APP_API_URI}users/${
        routeParams.radius
          ? `getDataByRadius?${routeParams.radius}&page=${page}&`
          : `getAllStrainData/?page=${page}&`
      }userType=consumer&name=${searchTerm}&latlang=${
        data?.location?.coordinates[0]
      },${data?.location?.coordinates[1]}&category=${categoryFilter.join(",")}`;
      GetSeeds(GetSeedsUrl);
    }
  }, [debouncedSearchedTerm]);

  const [page, setPage] = useState(1);

  const pageHandler = (page) => {
    setPage(page);
    const currentUser = localStorage.getItem("userdata");
    let data = JSON.parse(currentUser);
    let GetSeedsUrl = `${process.env.REACT_APP_API_URI}users/${
      routeParams.radius
        ? `getDataByRadius?${routeParams.radius}&page=${page}&`
        : `getAllStrainData/?page=${page}&`
    }userType=consumer&latlang=${data?.location?.coordinates[0]},${
      data?.location?.coordinates[1]
    }&category=${categoryFilter.join(",")}`;
    GetSeeds(GetSeedsUrl);
  };

  useEffect(() => {
    const savedFilter = JSON.parse(localStorage.getItem("budSeedFilter"));

    if (savedFilter) {
      setFilter(savedFilter);
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      // console.log(filter.area);
      let GetAllProductUrl = `${
        process.env.REACT_APP_API_URI
      }users/getDataByRadius?page=${page}&userType=consumer${`&radius=${savedFilter.radius}`}${
        savedFilter.quantity ? `&quantity=${savedFilter.quantity}` : ""
      }${
        savedFilter.area
          ? `&address=${savedFilter.area}`
          : `&latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}`
      }`;
      GetAllProduct(GetAllProductUrl);
    } else {
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      setcurrentuserData(data);
      let GetSeedsUrl = `${process.env.REACT_APP_API_URI}users/${
        routeParams.radius
          ? `getDataByRadius?${routeParams.radius}&name=${searchTerm}&page=1&`
          : `getAllStrainData/?page=1&`
      }userType=consumer&latlang=${data?.location?.coordinates[0]},${
        data?.location?.coordinates[1]
      }&category=${categoryFilter.join(",")}`;
      GetAllProductUseEffect(GetSeedsUrl);
      console.log(1);
    }
  }, [routeParams.radius]);

  useEffect(() => {
    const savedFilter = JSON.parse(localStorage.getItem("budSeedFilter"));

    if (savedFilter) {
      setFilter(savedFilter);
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      // console.log(filter.area);
      let GetAllProductUrl = `${
        process.env.REACT_APP_API_URI
      }users/getDataByRadius?page=${page}&userType=consumer${`&radius=${savedFilter.radius}`}${
        savedFilter.quantity ? `&quantity=${savedFilter.quantity}` : ""
      }${
        savedFilter.area
          ? `&address=${savedFilter.area}`
          : `&latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}`
      }`;
      GetAllProduct(GetAllProductUrl);
    } else {
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      setcurrentuserData(data);
      let GetSeedsUrl = `${process.env.REACT_APP_API_URI}users/${
        routeParams.radius
          ? `getDataByRadius?${routeParams.radius}&name=${searchTerm}&page=1&`
          : `getAllStrainData/?page=1&`
      }userType=consumer&latlang=${data?.location?.coordinates[0]},${
        data?.location?.coordinates[1]
      }&category=${categoryFilter.join(",")}`;
      GetSeeds(GetSeedsUrl);
    }
    console.log(1);
  }, [options]);

  const [filter, setFilter] = useState({
    radius: 0,
    area: "",
    quantity: "",
  });
  const formHandler = (e) => {
    const { name, value } = e.target;
    setFilter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    localStorage.setItem("budSeedFilter", JSON.stringify(filter));
  };

  const GetAllProduct = async (GetAllProductUrl) => {
    setIsLoading(true);
    try {
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      const userId = data?._id;
      const fetchData = await Axios.get(
        GetAllProductUrl.concat(`&ignoreUserId=${userId}`)
      );
      // console.log(fetchData.data);
      setSeeds(fetchData.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
    setIsLoading(false);
  };
  const GetAllProductUseEffect = async (GetSeedsUrl) => {
    setIsLoading(true);
    try {
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      const userId = data?._id;
      const fetchData = await Axios.get(
        GetSeedsUrl.concat(`&ignoreUserId=${userId}`)
      );
      setSeeds(fetchData.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
    setIsLoading(false);
  };
  const submitHandler = (event) => {
    event.preventDefault();
    const currentUser = localStorage.getItem("userdata");
    let data = JSON.parse(currentUser);
    // console.log(filter.area);
    let GetAllProductUrl = `${
      process.env.REACT_APP_API_URI
    }users/getDataByRadius?page=${page}&userType=consumer${`&radius=${filter.radius}`}${
      filter.quantity ? `&quantity=${filter.quantity}` : ""
    }${
      filter.area
        ? `&address=${filter.area}`
        : `&latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}`
    }`;
    GetAllProduct(GetAllProductUrl);
    localStorage.setItem("budSeedFilter", JSON.stringify(filter));
  };

  const clearFilterHandler = (e) => {
    e.preventDefault();
    localStorage.removeItem("budSeedFilter");
    setFilter({
      radius: 0,
      area: "",
      quantity: "",
    });
    const currentUser = localStorage.getItem("userdata");
    let data = JSON.parse(currentUser);
    let GetSeedsUrl = `${
      process.env.REACT_APP_API_URI
    }users/getAllStrainData/?page=${page}&userType=consumer&name=${searchTerm}&latlang=${
      data?.location?.coordinates[0]
    },${data?.location?.coordinates[1]}&category=${categoryFilter.join(",")}`;
    GetSeeds(GetSeedsUrl);
  };
  const [minMax, setMinMax] = useState({
    minValue: 0,
    maxValue: 250,
  });
  const handleInput = useMemo(() => {
    return (e) => {
      setMinMax({
        minValue: e.minValue,
        maxValue: e.maxValue,
      });
      setFilter((prevState) => ({
        ...prevState,
        radius: `${e.minValue}-${e.maxValue}`,
      }));
    };
  }, [setMinMax, setFilter]);

  function handleCheckboxChange(event) {
    async function test () {
      localStorage.removeItem("budSeedFilter");
      const { value, checked } = event.target;
      setOptions((prevOptions) =>
          prevOptions.map((option) =>
              option.value === value ? { ...option, checked } : option
          )
      );
      if (categoryFilter.includes(value)) {
        setcategoryFilter((prevStrings) =>
            prevStrings.filter((string) => string !== value)
        );
      } else {
        setcategoryFilter((prevArray) => [...prevArray, value]);
      }
    }
    test()


  }
  const DashboardAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API_URI}/api/v1/`,
  });

  DashboardAxios.interceptors.request.use((request) => {
    document.querySelector(".loader-main").style.display = "block";
    return request;
  });

  DashboardAxios.interceptors.response.use(
    (response) => {
      document.querySelector(".loader-main").style.display = "none";
      return response;
    },
    (error) => {
      document.querySelector(".loader-main").style.display = "none";
      throw error;
    }
  );
  return (
    <>
      <div className="allproduct-mob d-block">
        <div className="container mx-auto">
          <div className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between gap-4">
            <div className="d-flex align-items-center align-items-sm-start align-items-md-center w-100-sm-md gap-sm-4 gap-2 justify-content-between">
              <h2 className="bordered-heading">Seeds and Buds for swap</h2>
              <div className="align-items-center gap-sm-4 gap-3 d-sm-flex d-md-none d-none">
                <div className="search-product d-sm-none d-flex">
                  <input
                    placeholder="Search Product"
                    type="text"
                    className="border-0 outline-0 bg-transparent w-100"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                  <span className="icon-green-bg">
                    <MobSearchIcon />
                  </span>
                </div>

                <div className="d-flex align-items-center w-max-content gap-sm-4 gap-3">
                  {!Location.pathname.includes("map") ? (
                    <Link
                      to={`${Location.pathname}/map`}
                      className="text-white view-map-btn p-2 d-sm-flex d-none align-items-center gap-3"
                    >
                      <span className="d-md-block d-none ps-2">View Map</span>
                      <span className="view-map-btn-scope d-flex align-items-center  justify-content-center ">
                        <ScopeIcon />
                      </span>
                    </Link>
                  ) : (
                    <Link className="text-white view-map-btn p-2 d-sm-flex d-none align-items-center gap-3">
                      <span className="d-md-block d-none ps-2">View Map</span>
                      <span className="view-map-btn-scope d-flex align-items-center justify-content-center ">
                        <ScopeIcon />
                      </span>
                    </Link>
                  )}

                  <div className="d-flex align-items-center w-max-content">
                    <button
                      className="border-0 outline-0 bg-transparent p-0 height-56"
                      data-bs-toggle="modal"
                      data-bs-target="#deactivatemodal"
                    >
                      <FilterIcon />
                    </button>
                  </div>
                  {!Location.pathname.includes("map") ? (
                    <Link
                      to={`${Location.pathname}/map`}
                      className="text-white view-map-btn p-2 d-flex d-sm-none align-items-center gap-3 height-56 rounded-3"
                    >
                      <span className="view-map-btn-scope d-flex align-items-center justify-content-center h-100 w-max-content p-1 rounded-3">
                        <ScopeIcon />
                      </span>
                    </Link>
                  ) : (
                    <Link className="text-white view-map-btn p-2 d-flex d-sm-none align-items-center gap-3 height-56 rounded-3">
                      <span className="view-map-btn-scope d-flex align-items-center justify-content-center h-100 w-max-content p-1 rounded-3">
                        <ScopeIcon />
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex d-sm-none d-md-flex align-items-center gap-sm-4 gap-3">
              <div className="search-product d-sm-none d-flex">
                <input
                  placeholder="Search Product"
                  type="text"
                  className="border-0 outline-0 bg-transparent w-100"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
                <span className="icon-green-bg">
                  <MobSearchIcon />
                </span>
              </div>

              <div className="d-flex align-items-center w-max-content gap-sm-4 gap-3">
                {!Location.pathname.includes("map") ? (
                  <Link
                    to={`${Location.pathname}/map`}
                    className="text-white view-map-btn p-2 d-sm-flex d-none align-items-center gap-3"
                  >
                    <span className="d-md-block d-none ps-2">View Map</span>
                    <span className="view-map-btn-scope d-flex align-items-center  justify-content-center ">
                      <ScopeIcon />
                    </span>
                  </Link>
                ) : (
                  <Link className="text-white view-map-btn p-2 d-sm-flex d-none align-items-center gap-3">
                    <span className="d-md-block d-none ps-2">View Map</span>
                    <span className="view-map-btn-scope d-flex align-items-center justify-content-center ">
                      <ScopeIcon />
                    </span>
                  </Link>
                )}

                <div className="d-flex align-items-center w-max-content">
                  <button
                    className="border-0 outline-0 bg-transparent p-0 height-56"
                    data-bs-toggle="modal"
                    data-bs-target="#deactivatemodal"
                  >
                    <FilterIcon />
                  </button>
                </div>
                {!Location.pathname.includes("map") ? (
                  <Link
                    to={`${Location.pathname}/map`}
                    className="text-white view-map-btn p-2 d-flex d-sm-none align-items-center gap-3 height-56 rounded-3"
                  >
                    <span className="view-map-btn-scope d-flex align-items-center justify-content-center h-100 w-max-content p-1 rounded-3">
                      <ScopeIcon />
                    </span>
                  </Link>
                ) : (
                  <Link className="text-white view-map-btn p-2 d-flex d-sm-none align-items-center gap-3 height-56 rounded-3">
                    <span className="view-map-btn-scope d-flex align-items-center justify-content-center h-100 w-max-content p-1 rounded-3">
                      <ScopeIcon />
                    </span>
                  </Link>
                )}
              </div>
            </div>
          </div>

          <div className="mt-sm-5 mt-4 mb-5 gap-4 d-flex align-items-start justify-content-between">
            <div className="search-product d-sm-flex d-none">
              <input
                placeholder="Search Product"
                className="border-0 outline-0 bg-transparent"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              <div className="icon-green-bg">
                <SearchButtonIcon />
              </div>
            </div>
            <div
              className={`d-flex gap-3 overflow-x-auto all-products-link ${
                isLoading && "blur"
              }`}
            >
              {options.map((option) => {
                return (
                  <label
                    key={option.value}
                    className={`product-item cr-p ${
                      option.checked ? "active" : ""
                    }`}
                  >
                    <input
                      disabled={isLoading}
                      className="d-none"
                      type="checkbox"
                      value={option.value}
                      checked={option.checked}
                      aria-disabled={isLoading}
                      onChange={(e) => handleCheckboxChange(e)}
                    />
                    {option.icon} {option.label}
                  </label>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container mx-auto ">{children}</div> */}
      <div
        className="modal fade"
        id="deactivatemodal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog custom-model w-max-content modal-dialog-centered mx-auto filter-model">
          <div className="modal-content justify-content-center p-4">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font-32 font-weight-800 text-center">
                Filter your search
              </p>
              <span className="cr-p" data-bs-dismiss="modal">
                <CrossBorderIcon />
              </span>
            </div>
            <form>
              <div className="d-flex flex-column align-items-start justify-content-center mb-5 mt-4 pt-2">
                <div className="p-0 bg-transparent border-0 w-100">
                  {/* <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP}
                    libraries={libraries}
                  >
                    <StandaloneSearchBox
                      onLoad={(ref) => (inputRef1.current = ref)}
                      onPlacesChanged={handlePlaceChanged}
                    >
                      <div className="form-control h-auto p-0 bg-transparent border-0">
                        <label className="mb-2 font-weight-600 font-18-100">
                          Search an area
                        </label>
                        <input
                          onChange={(e) =>
                            setFilter((prevState) => ({
                              ...prevState,
                              area: e.target.value,
                            }))
                          }
                          value={filter.area}
                          type="text"
                          className="auth-input bg-white height-42"
                          placeholder="Enter here..."
                          name="area"
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </StandaloneSearchBox>
                  </LoadScript> */}
                  <AddressInput
                    setAddressProp={(address) =>
                      setFilter((prevState) => ({
                        ...prevState,
                        area: address,
                      }))
                    }
                    inputLabel="Search an area"
                    className="height-42"
                    addressValue={filter.area}
                    isDark={false}
                  />
                </div>
                <div className="d-flex flex-column align-items-start justify-content-center w-100 gap-2 mb-4">
                  <label className="font-weight-600 font-18-100 d-flex justify-content-between w-100">
                    <span className="text-dark">Distance(km)</span>
                    <span className="text-dark">
                      {minMax.minValue}-{minMax.maxValue}
                    </span>
                  </label>
                  <MultiRangeSlider
                    className="shadow-none border-0 py-0 px-2 py-2 w-100"
                    min={0}
                    max={250}
                    minValue={minMax.minValue}
                    maxValue={minMax.maxValue}
                    ruler={false}
                    label={false}
                    barLeftColor="white"
                    barInnerColor="#5D8B2F"
                    barRightColor="white"
                    thumbLeftColor="white"
                    thumbRightColor="white"
                    onInput={handleInput}
                  />
                </div>
                <div className="w-100">
                  <div
                    className="btn-group btn-group-toggle height-42 mb-4 btn-group-dash"
                    data-toggle="buttons"
                  >
                    <label className="btn font-14 bg-grey active d-flex align-items-center py-3 px-sm-3 px-2">
                      <input
                        type="radio"
                        name="options"
                        id="Grams"
                        autoComplete="off"
                        readOnly
                        checked={type === "Grams"}
                        onChange={handleChange}
                        value="Grams"
                      />
                      <span className="pl-2">Grams</span>
                    </label>
                    <label className="btn font-14 bg-grey d-flex align-items-center px-sm-3 px-2">
                      <input
                        type="radio"
                        name="options"
                        id="Seeds"
                        value="Seeds"
                        autoComplete="off"
                        checked={type === "Seeds"}
                        onChange={handleChange}
                      />
                      <span className="pl-2">Seeds</span>
                    </label>
                    <label className="btn font-14 bg-grey d-flex align-items-center px-sm-3 px-2">
                      <input
                        type="radio"
                        name="options"
                        id="All"
                        value="All"
                        autoComplete="off"
                        checked={type === "All"}
                        onChange={handleChange}
                      />
                      <span className="pl-2">All</span>
                    </label>
                  </div>
                  {customType === "CustomSelect" && type !== "All" && (
                    <div className="form-control h-auto p-0 bg-transparent border-0 w-100">
                      <label className="mb-2 font-weight-600 font-18-100">
                        Search by Quantity
                      </label>
                      <select
                        className="auth-input bg-white height-42 w-100"
                        required
                        name="quantity"
                        onChange={(e) => formHandler(e)}
                      >
                        <option value={""}>- All -</option>
                        <option value={type === "Seeds" ? "1-4" : "1-7"}>
                          {type === "Seeds" ? "1-4 Seeds" : "1-7 Grams"}
                        </option>
                        <option value={type === "Seeds" ? "5-10" : "7-14"}>
                          {type === "Seeds" ? "5-10 Seeds" : "7-14 Grams"}
                        </option>
                        <option value={type === "Seeds" ? "11-15" : "14-30"}>
                          {type === "Seeds" ? "11-15 Seeds" : "14-30 Grams"}
                        </option>
                        {type === "Seeds" && (
                          <option value={"16-20"}>{"16-20 Seeds"}</option>
                        )}
                      </select>
                    </div>
                  )}

                  {customType === "CustomAny" && (
                    <div className="form-control h-auto p-0 bg-transparent border-0">
                      <input
                        type="text"
                        name="quantity"
                        placeholder={
                          type === "Seeds" ? "1-7 Seeds" : "1-7 Grams"
                        }
                        className="auth-input height-42 bg-white"
                        onChange={(e) => formHandler(e)}
                      />
                      <p className="text-danger mt-2">
                        Value must be in range format E.g 1-7, 14-20 etc
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center w-100 mt-4 flex-wrap">
                <button
                  className="light-red-btn-outline text-white custom-w min-width-208 height-42"
                  onClick={clearFilterHandler}
                  data-bs-dismiss="modal"
                >
                  Clear Filter
                </button>
                <button
                  className="green-btn-outline text-primary-green custom-w min-width-208 height-42"
                  data-bs-dismiss="modal"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="green-btn custom-w min-width-208 height-42"
                  onClick={submitHandler}
                  data-bs-dismiss="modal"
                >
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="seeds-card-main row m-0">
        <div className="d-flex justify-content-center align-items-center">
          <div className="loader-main ">
            <div className="loader">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        {seeds?.result?.length !== 0 ? (
          (seeds || []).result?.map((data, index) => {
            const imageUrl = data?.photo
              ? `${process.env.REACT_APP_PORT}/${data?.photo}`
              : `${process.env.REACT_APP_PORT}/undefined`;
            const isPlaceholderImage =
              imageUrl === `${process.env.REACT_APP_PORT}/undefined`;
            return (
              <div
                className={`col-xl-3 col-lg-4  col-md-6 mb-4 seed-card-col ${
                  isLoading ? "blur" : ""
                }`}
                key={index}
              >
                <div className="seed-card position-relative text-black">
                  <div className="row m-0 flex-sm-column w-100">
                    <div className="col-4 col-sm-12 p-0">
                      {isPlaceholderImage ? (
                        <ImageDisplay
                          className="intro-img cards-image-style"
                          src={ImageDummy}
                          alt=""
                        />
                      ) : (
                        <ImageDisplay
                          className="intro-img cards-image-style"
                          src={imageUrl}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="col-8 col-sm-12 p-0">
                      <div className="ps-sm-0 ps-3">
                        <p className="my-sm-4 mb-3 font-24 font-weight-700 cut-text">
                          {data.productName || data.strainName}
                        </p>
                        <div className="d-flex justify-content-between align-items-center mb-sm-3 mb-2 gap-2">
                          <span className="d-flex gap-2 align-items-center font-18 font-weight-500 w-50">
                            <DistanceIcon />
                            <span className="cut-text">
                              {data.distance} Away
                            </span>
                          </span>
                          <span className="d-flex gap-2 align-items-center font-18 font-weight-500 w-50 ">
                            <CountIcon />
                            <span className="cut-text">
                              {data.quantity} Seeds
                            </span>
                          </span>
                        </div>

                        <span className="d-flex gap-2 align-items-center font-18 font-weight-500 mb-sm-4 pb-sm-1 mb-2 ">
                          <LocationIcon />
                          <span className="cut-text">
                            {data.userId?.location?.address}
                          </span>
                        </span>
                        <div className="d-flex justify-content-between align-items-center gap-sm-2 gap-3 flex-sm-nowrap flex-wrap">
                          <div className="d-flex gap-2 align-items-center flex-wrap">
                            <span className="d-flex gap-2 align-items-center font-24 font-weight-700">
                              <RatingIcon />
                              {data?.ratingsAverage}
                            </span>
                            <span className="font-14-100 text-grey font-weight-400">
                              (
                              {data?.ratingsQuantity === 0
                                ? "No Reviews"
                                : `${data?.ratingsQuantity} Reviews`}
                              )
                            </span>
                          </div>
                          <Link
                            to={`/home/userItem/${data._id}`}
                            className="green-btn-outline bg-primary-green text-white ps-3 pe-1 d-flex align-items-center justify-content-between font-18 py-sm-3 py-2 gap-2 w-max-content"
                          >
                            <span>View Profile </span>
                            <span className="icon-green-bg bg-light-green">
                              <FavouriteIcon />
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <>
            {!isLoading && (
              <div className="d-flex justify-content-center w-100">
                <EmptyDataImage />
              </div>
            )}
          </>
        )}
        {seeds.totalRecords > 10 && (
          <div className="d-flex justify-content-center mt-4">
            <PaginationControl
              page={page}
              between={3}
              total={seeds.totalRecords}
              limit={seeds.limit}
              changePage={(page) => pageHandler(page)}
              ellipsis={1}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Seeds;
