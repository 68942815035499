import { useEffect, useState } from "react";
import Hooks from "../../hooks";
import { useNavigate } from "react-router-dom";

export const UserProtectedRoutes = (props) => {
  const { children: Children, userType } = props;
  const [currentuserData, setcurrentuserData] = useState();
  const { IsUserLoggedIn } = Hooks();
  const navigate = useNavigate();
  useEffect(() => {
    const currentUser = localStorage.getItem("userdata");
    let data = JSON.parse(currentUser);
    setcurrentuserData(data);
  }, []);
  if (IsUserLoggedIn() && currentuserData?.userType === userType) {
    return Children;
  } else if (IsUserLoggedIn() && userType === "consumerretailer") {
    return Children;
  } else {
    return navigate(-1);
  }
};
