import React from 'react'

const DistanceIcon = () => {
    return (
        <>
            <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M3.14528 6.3C4.88237 6.3 6.29055 4.8897 6.29055 3.15C6.29055 1.4103 4.88237 0 3.14528 0C1.40819 0 0 1.4103 0 3.15C0 4.8897 1.40819 6.3 3.14528 6.3Z" fill="#5D8B2F" />
                <path opacity="0.4" d="M13.5063 11.7002H16.2022C17.1908 11.7002 17.9995 12.5102 17.9995 13.5002V16.2002C17.9995 17.1902 17.1908 18.0002 16.2022 18.0002H13.5063C12.5178 18.0002 11.709 17.1902 11.709 16.2002V13.5002C11.709 12.5102 12.5178 11.7002 13.5063 11.7002Z" fill="#5D8B2F" />
                <path d="M9.01347 15.9754H6.60508C5.56265 15.9754 4.65501 15.3454 4.29555 14.3734C3.92711 13.4014 4.1967 12.3304 4.97853 11.6374L12.1587 5.34639C12.5901 4.96839 12.5991 4.45539 12.4733 4.10439C12.3385 3.75339 11.997 3.37539 11.4219 3.37539H9.01347C8.64502 3.37539 8.33948 3.06939 8.33948 2.70039C8.33948 2.33139 8.64502 2.02539 9.01347 2.02539H11.4219C12.4643 2.02539 13.3719 2.65539 13.7314 3.62739C14.0998 4.59939 13.8302 5.67039 13.0484 6.36339L5.86819 12.6544C5.43684 13.0324 5.42785 13.5454 5.55366 13.8964C5.68846 14.2474 6.02995 14.6254 6.60508 14.6254H9.01347C9.38191 14.6254 9.68746 14.9314 9.68746 15.3004C9.68746 15.6694 9.38191 15.9754 9.01347 15.9754Z" fill="#5D8B2F" />
                <path d="M14.8635 15.7502C14.3692 15.7502 13.9648 15.3452 13.9648 14.8502C13.9648 14.3552 14.3603 13.9502 14.8635 13.9502H14.8725C15.3667 13.9502 15.7711 14.3552 15.7711 14.8502C15.7711 15.3452 15.3667 15.7502 14.8635 15.7502Z" fill="#5D8B2F" />
                <path d="M3.18185 4.05C2.6876 4.05 2.2832 3.645 2.2832 3.15C2.2832 2.655 2.67861 2.25 3.18185 2.25H3.19084C3.6851 2.25 4.08949 2.655 4.08949 3.15C4.08949 3.645 3.6851 4.05 3.18185 4.05Z" fill="#5D8B2F" />
            </svg>
        </>
    )
}

export default DistanceIcon