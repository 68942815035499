import { useEffect ,useState} from "react";
import "../src/Pages/common.css";
import "bootstrap/dist/css/bootstrap.css";
import NavigationRoutes from "./Routes/NavigationRoutes";
import "./App.css";
import { onMessageListener } from './firebase';
import { toast } from "react-toastify";

function App() {
  const [notification, setNotification] = useState({ title: '', body: '' });

  useEffect(() => {
    if (notification?.title) {
      toast.info(notification?.body);
    }
  }, [notification]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  onMessageListener()
      .then((payload) => {
          setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
      })
      .catch((err) => console.log('failed: ', err));

  
  return <NavigationRoutes />;
}

export default App;
