import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-number-input/style.css';
import { useRef } from 'react';
import { PostAddress } from '../../Api';
import { useEffect } from 'react';
import AddressInput from '../../Components/Address/address';
import { addRoute } from 'workbox-precaching';
import { toast } from 'react-toastify';

const AddAddressPage = () => {
  const [postalCode, setpostalCode] = useState('');
  const [userAdress, setUserAddress] = useState({
    address: '',
    city: '',
    state: '',
    country: '',
    postal_code: ''
  });
  const [address, setAddress] = useState({
    address: JSON.parse(localStorage.getItem('signupData'))?.address || '',
    postalCode: JSON.parse(localStorage.getItem('signupData'))?.postalCode || '',
    phone: JSON.parse(localStorage.getItem('signupData'))?.phone || ''
  });
  const [userData, setuserData] = useState();

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const currentUser = localStorage.getItem('userdata');
    setuserData(JSON.parse(currentUser));
  }, []);

  useEffect(() => {
    setAddress(prevState => ({
      ...prevState,
      postalCode: postalCode
    }));
  }, [address.address]);

  const addressUrl = `${process.env.REACT_APP_API_URI}users/updateUser/${userData?._id}`;
  const submitHandler = e => {
    e.preventDefault();

    if (address.address === '') {
      toast('Please select address from dropdown');
      return;
    }
    let formData = { ...address, city: userAdress?.city, state: userAdress?.state, country: userAdress?.country };
    PostAddress(addressUrl, formData, navigate);
  };

  return (
    <div className="max-width-792">
      <h2 className="auth-model-heading text-center mb-4">
        {userData?.userType !== 'retailer'
          ? ' Enter your address to find nearby swaps'
          : 'Enter your address so consumers can find your store'}
      </h2>
      <p className="auth-model-desc mb-4 pb-3">Provide your address to set your discovery range</p>

      <form onSubmit={e => submitHandler(e)}>
        <div className="mb-5">
          {/* <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP}
            libraries={libraries}
            region="US"
          >
            <Autocomplete
              onLoad={(ref) => (inputRef.current = ref)}
              onPlaceChanged={handlePlaceChanged}
              options={{
                componentRestrictions: { country: ["US", "CA"] }, // Restrict to the United States and Canada
              }}
            >
              <div className="form-control h-auto p-0 bg-transparent border-0 mb-4">
                <label className="text-white mb-2 font-weight-600 font-18-100">
                  Address
                </label>
                <input
                  type="text"
                  required
                  className="auth-input"
                  placeholder="Enter Address"
                />
              </div>
            </Autocomplete>
          </LoadScript> */}
          <AddressInput
            setAddressProp={address =>
              setAddress(prevState => ({
                ...prevState,
                address: address
              }))
            }
            setpostalCode={setpostalCode}
            addressValue={address.address}
            isDark={true}
            setUserAddress={setUserAddress}
          />
          <div className="form-control h-auto p-0 bg-transparent border-0 mb-4">
            <label className="text-white mb-2 font-weight-600 font-18-100">Postal Code / Zip Code</label>
            <input
              type="text"
              required
              className="auth-input"
              placeholder="Enter Postal Code/Zip Code"
              value={address.postalCode}
              onChange={e => {
                setAddress(prevState => ({
                  ...prevState,
                  postalCode: e.target.value
                }));
              }}
            />
          </div>
          <div className="form-control h-auto p-0 bg-transparent border-0">
            <label className="text-white mb-2 font-weight-600 font-18-100">
              Phone number [Used to verify your profile] (optional)
            </label>

            <div className="custom-phone-input auth-input d-flex align-items-center">
              <PhoneInput
                onlyCountries={['us', 'ca']}
                country={'us'}
                disableCountryCode={true}
                placeholder="Enter Phone Number"
                onChange={value =>
                  setAddress(prevState => ({
                    ...prevState,
                    phone: value
                  }))
                }
                inputProps={{
                  name: 'phone'
                }}
                buttonClass="d-none"
                inputClass="bg-transparent outline-0 p-0 m-0 border-0 shadow-none custom-phone-input-1 font-18-100"
              />
            </div>
          </div>
        </div>

        <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-5">
          {/* <button
            className="green-btn-outline custom-w min-width-208 back-btn"
            onClick={() => goBack()}
            type="button"
          >
            Back
          </button> */}
          <button className="green-btn custom-w min-width-208 next-btn" type="submit">
            Next
          </button>
        </div>
      </form>
      <p className="text-center text-grey mt-5 pt-sm-0 pt-3 font-16">
        <Link className="font-16 text-grey" to="/terms">
          Terms of use
        </Link>{' '}
        |{' '}
        <Link className="font-16 text-grey" to="/privacypolicy">
          Privacy Policy
        </Link>
      </p>
    </div>
  );
};

export default AddAddressPage;
