import React, { useEffect } from "react";
import PanicSection from "../../Components/Dashboard Components/PanicSection";
import AllProductsDashboard from "./AllProducts";
import { askNotificationPermission, requestForToken } from "../../firebase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AllData = () => {
  useEffect(() => {
    localStorage.setItem("platform", "grow");
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    askNotificationPermission();
    requestForToken().then((token) => {
    });
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("userdata");
    const data = JSON.parse(user);
    if (!data?.location?.address) {
      navigate("/address");
    }
}, [navigate])

// useEffect(() => {
//   const currentUser = localStorage.getItem("userdata");
//   let data = JSON.parse(currentUser);
//   if (data && !data.verified) {
//     console.log("User not verified, clearing localStorage and navigating to /");
//     toast.error('Email not verified! Check your email');
//     localStorage.clear();
//     navigate("/", { replace: true });
//   }
// }, [navigate]);

  return (
    <div>
      <PanicSection />
      <AllProductsDashboard />
    </div>
  );
};

export default AllData;
