import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import socialuser from "../../assets/Images/social-user.svg";
import Axios from "../../axios/Axios";
import { toast } from "react-toastify";
import ImageDisplay from "./ImageDisplay";
import userprofile from "../../assets/Images/social-user.svg";
import ImageGallery from "react-image-gallery";
import 'react-image-gallery/styles/css/image-gallery.css';

const SocialUploadPicture = () => {
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState([]);
  const [userData, setUserData] = useState();
  const [imageCountError, setImageCountError] = useState("");

  useEffect(() => {
    const currentUser = localStorage.getItem("userdata");
    let userData = JSON.parse(currentUser);
    setUserData(JSON.parse(currentUser));
    setSelectedImages(userData?.social_photos || []);
  }, []);

  const EditProfileUrl = `${process.env.REACT_APP_API_URI}users/profileUpdate/${userData?._id}`;

  const UploadImages = async (imageData) => {
    try {
      const fetchData = await Axios.patch(EditProfileUrl, imageData);
      localStorage.setItem(
        "userdata",
        JSON.stringify(fetchData?.data?.updateUser)
      );
      toast.success("Images Added Successfully");
      const currentUser = localStorage.getItem("userdata");
      let userData = JSON.parse(currentUser);
      setUserData(JSON.parse(currentUser));
      setSelectedImages(userData?.social_photos);
      navigate("/social/profile");
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length < 1 || selectedFiles.length > 3) {
      setImageCountError("Select 1 to 3 images.");
    } else {
      setImageCountError("");
      const imageData = new FormData();
      selectedFiles.forEach((file) => {
        imageData.append("social_photos", file);
      });
      UploadImages(imageData);
    }
  };

  const socialPhotos = userData?.socialPhotos || [];
  const items = socialPhotos.map((img) => {
    if (img) {
      const imageUrl = `${process.env.REACT_APP_PORT}/${img}`;
      return {
        original: imageUrl,
        thumbnail: imageUrl,
      };
    } else {
      return {
        original: userprofile,
        thumbnail: userprofile,
      };
    }
  });

  return (
    <div className="py-4 px-4">
      <h3 className="font-24-social font-weight-700 text-center text-white mb-4 pb-3">
        Notice
      </h3>
      <p className="font-14 font-weight-400 text-primary-red mb-3">
        <span className="font-weight-700">Warning:</span> The Following
        inappropriate photos are strictly prohibited and will be removed.
      </p>

      <div className="text-white p-0 font-14 font-weight-400 pt-3">
        <p>1. The person in the photo is nude or minimally clothed.</p>
        <p>2, Photos that contain sexually suggestive poses.</p>
        <p>3, Photos that contain minors, sexual aids, or illegalfetishes.</p>
        <p>4, Photos that contain lewd or profane content.</p>
        <p>
          5, Any other inappropriate content/photos defined in our Terms of
          Service.
        </p>
        {userData?.socialPhotos?.length <= 2 ? (
          <p>6, For best results: Upload a minimum of 3 pictures</p>
        ) : (
          ""
        )}
      </div>
      <div className="d-flex justify-content-center align-items-center my-5">
        {userData?.socialPhotos?.length <= 1 ? (
          <ImageDisplay
            src={
              userData?.socialPhotos
                ? `${process.env.REACT_APP_PORT}/${userData?.socialPhotos[0]}`
                : userprofile
            }
            alt=""
            className=" w-50 rounded-3 user-profile-image"
          />
        ) : (
          <ImageGallery
            items={items}
            showFullscreenButton={false}
            showPlayButton={false}
            autoPlay={false}
            additionalClass="rounded-5"
            showIndex={true}
            renderRightNav={(onClick, disabled) => (
              <RightNav onClick={onClick} disabled={disabled} />
            )}
            renderLeftNav={(onClick, disabled) => (
              <LeftNav onClick={onClick} disabled={disabled} />
            )}
          />
        )}
      </div>
      {/* <div className="d-flex justify-content-center align-items-center my-5">
        {selectedImages.length > 0 ? (
          selectedImages.map((image, index) => (
            <ImageDisplay
              key={index}
              className="h-50 w-50 rounded-3 mx-2"
              src={`${process.env.REACT_APP_PORT}/${image[0]}`}
              alt={`social-user-img-${index}`}
            />
          ))
        ) : (
          <ImageDisplay
            className="h-50 w-50 rounded-3"
            src={socialuser}
            alt="social-user-img"
          />
        )}
      </div> */}
      {/* <button className="green-btn my-4" type="button">
        User camera
      </button> */}
      {imageCountError && (
        <p className="text-danger text-center mb-3">{imageCountError}</p>
      )}
      <label className="green-btn w-100 height-56 cr-p d-flex justify-content-center align-items-center">
        <input
          className="d-none"
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          multiple
          onChange={(e) => submitHandler(e)}
        />
        <span>Upload pictures from gallery</span>
      </label>
    </div>
  );
};

export default SocialUploadPicture;

const RightNav = React.memo(({ disabled, onClick }) => {
  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-right-nav"
      disabled={disabled}
      onClick={onClick}
      aria-label="Next Slide"
    >
      <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.73023 0.29552C9.14137 -0.0985067 9.80795 -0.0985067 10.2191 0.29552L17.0833 6.874C18.305 8.04493 18.305 9.95507 17.0833 11.126L10.2191 17.7045C9.80795 18.0985 9.14137 18.0985 8.73023 17.7045C8.31909 17.3105 8.31909 16.6716 8.73023 16.2776L15.5944 9.69911C15.9939 9.31623 15.9939 8.68377 15.5944 8.30089L8.73023 1.72242C8.31909 1.32839 8.31909 0.689547 8.73023 0.29552Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.308354 0.29552C0.719492 -0.0985067 1.38608 -0.0985067 1.79722 0.29552L8.66138 6.874C9.88316 8.04493 9.88316 9.95507 8.66138 11.126L1.79722 17.7045C1.38608 18.0985 0.719492 18.0985 0.308354 17.7045C-0.102785 17.3105 -0.102785 16.6716 0.308354 16.2776L7.17252 9.69911C7.57202 9.31623 7.57202 8.68377 7.17252 8.30089L0.308354 1.72242C-0.102785 1.32839 -0.102785 0.689547 0.308354 0.29552Z"
          fill="white"
        />
      </svg>
    </button>
  );
});

const LeftNav = React.memo(({ disabled, onClick }) => {
  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-left-nav"
      disabled={disabled}
      onClick={onClick}
      aria-label="Previous Slide"
    >
      <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.26977 0.29552C8.85863 -0.0985067 8.19205 -0.0985067 7.78091 0.29552L0.916746 6.874C-0.305037 8.04493 -0.305037 9.95507 0.916746 11.126L7.78091 17.7045C8.19205 18.0985 8.85863 18.0985 9.26977 17.7045C9.68091 17.3105 9.68091 16.6716 9.26977 16.2776L2.40561 9.69911C2.0061 9.31623 2.0061 8.68377 2.40561 8.30089L9.26977 1.72242C9.68091 1.32839 9.68091 0.689547 9.26977 0.29552Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6916 0.29552C17.2805 -0.0985067 16.6139 -0.0985067 16.2028 0.29552L9.33862 6.874C8.11684 8.04493 8.11684 9.95507 9.33862 11.126L16.2028 17.7045C16.6139 18.0985 17.2805 18.0985 17.6916 17.7045C18.1028 17.3105 18.1028 16.6716 17.6916 16.2776L10.8275 9.69911C10.428 9.31623 10.428 8.68377 10.8275 8.30089L17.6916 1.72242C18.1028 1.32839 18.1028 0.689547 17.6916 0.29552Z"
          fill="white"
        />
      </svg>
    </button>
  );
});
