import axios from 'axios';

const Axios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URI}/api/v1/`
});

Axios.interceptors.request.use(request => {
  document.querySelector('.api-loading-bg').style.display = 'flex';
  if (request.method === 'post' && request.data instanceof FormData) {
    request.headers['Content-Type'] = 'multipart/form-data';
  }
  return request;
});

Axios.interceptors.response.use(
  response => {
    document.querySelector('.api-loading-bg').style.display = 'none';

    return response;
  },
  error => {
    document.querySelector('.api-loading-bg').style.display = 'none';
    throw error;
  }
);

export default Axios;
