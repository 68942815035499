import React from "react";

const ReloadIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width={44}
        height={44}
        viewBox="0 0 44 44"
        enableBackground="new 0 0 44 44"
        xmlSpace="preserve"
      >
        <g>
          <path
            fill="#5D8B2F"
            d="M25.947,15.219h-9.221v-3.947c0-0.493-0.544-0.74-0.925-0.466l-6.501,5.18   c-0.299,0.219-0.299,0.685,0,0.904l6.501,5.18c0.381,0.301,0.925,0.027,0.925-0.466v-3.947h9.221c3.617,0,6.582,2.96,6.582,6.605   c0,3.645-2.965,6.633-6.582,6.633H14.306c-0.68,0-1.197,0.548-1.197,1.206c0,0.685,0.544,1.206,1.197,1.206h11.641   c4.95,0,8.976-4.056,8.976-9.045S30.898,15.219,25.947,15.219z"
          />
          <circle
            fill="none"
            stroke="#5D8B2F"
            strokeWidth={2}
            cx={22}
            cy={22}
            r={21}
          />
        </g>
      </svg>
    </>
  );
};

export default ReloadIcon;
