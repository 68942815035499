import React, { useEffect, useMemo, useState } from "react";

import { Link, useLocation, useParams } from "react-router-dom";
import GoogleMapNew from "./GoogleMap/GoogleMapNew";
import { toast } from "react-toastify";
import Axios from "../../axios/Axios";
import SearchButtonIcon from "../../assets/Images/Search";
import MobSearchIcon from "../../assets/Images/MobSearch";
import useDebounce from "../../hooks/useDebounce";
import axios from "axios";
import FilterIcon from "../../assets/Images/filterIcon";
import CrossBorderIcon from "../../assets/Images/CrossBorder";
// import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import MultiRangeSlider from "multi-range-slider-react";
import { useRef } from "react";
import AddressInput from "../Address/address";
import ListView from "../../assets/Images/ListView";

const AllProductMapView = () => {
  const [activeMarker, setActiveMarker] = useState(null);
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  const Location = useLocation();

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchedTerm = useDebounce(searchTerm);
  const [type, setType] = useState("Grams");
  const [customType, setCustomType] = useState("CustomSelect");
  const [allProduct, setAllProduct] = useState([]);
  const [categoryFilter, setcategoryFilter] = useState([]);
  const inputRef1 = useRef();
  const [quantityFilter, setQuantityFilter] = useState("");
  const [userType, setUserType] = useState(
    localStorage.getItem("userType") || "retailer"
  );
  const [latAndLng, setLatAndLng] = useState([]);
  const routeParams = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [filter, setFilter] = useState({
    radius: 0,
    area: "",
    quantity: "",
  });

  const [options, setOptions] = useState([
    {
      id: 1,
      value: "all",
      label: "All",
      userType: "retailer",
    },
    {
      id: 2,
      value: "seedStore",
      label: "Seed",
      userType: "retailer",
    },
    {
      id: 3,
      value: "dispensary",
      label: "Dispensary",
      userType: "retailer",
    },
    {
      id: 4,
      value: "cannabisLounge",
      label: "Cannabis Lounge",
      userType: "retailer",
    },
    {
      id: 5,
      value: "headShop",
      label: "Head Shop",
      userType: "retailer",
    },
    {
      id: 6,
      value: "growDepot",
      label: "Grow Depot",
      userType: "retailer",
    },
    {
      id: 1,
      value: "all",
      label: "All",
      userType: "consumer",
    },
    {
      id: 2,
      value: "Sativa",
      label: "Sativa",
      userType: "consumer",
    },
    {
      id: 3,
      value: "Indica",
      label: "Indica",
      userType: "consumer",
    },
    {
      id: 4,
      value: "Hybrid",
      label: "Hybrid",
      userType: "consumer",
    },
    {
      id: 5,
      value: "CBD",
      label: "CBD",
      userType: "consumer",
    },
  ]);

  const [groupData, setGroupData] = useState({});
  const [sharingStatus, setSharingStatus] = useState([]);

  const GetAllProduct = async (GetAllProductUrl) => {
    setIsLoading(true);
    setAllProduct([]);
    try {
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      const userId = data?._id;
      const fetchData = await DashboardAxios.get(
        GetAllProductUrl.concat(`&ignoreUserId=${userId}`)
      );
      setAllProduct(fetchData.data);
      const groupByCategory = fetchData.data.result.reduce((group, product) => {
        const { userId } = product;
        if (!userId) return group;
        const { storeName, email } = userId;
        const key = storeName || email;
        if (!group[key]) {
          group[key] = [];
        }
        group[key].push(product);
        return group;
      }, {});
      setGroupData(groupByCategory);
      // group by category and keep of one user only
      const groupByCategoryLength = fetchData.data.result.reduce(
        (group, product) => {
          const { userId } = product;
          if (!userId) return group;
          const { category } = product;
          group[category] = group[category] ?? [];
          group[category].push(product);
          return group;
        },
        {}
      );
      // remove all the duplicate user
      const filterDuplicateUser = Object.keys(groupByCategoryLength).reduce(
        (group, key) => {
          const user = groupByCategoryLength[key];
          const uniqueUser = user.filter(
            (v, i, a) => a.findIndex((t) => t?.userId?._id === v?.userId?._id) === i
          );
          group[key] = uniqueUser;
          return group;
        },
        {}
      );
      console.log(filterDuplicateUser);
      //  ?[`${Object.keys(groupData).length} People Selling Strains`] :
      if (userType === "retailer") {
        const formatedMsgs = Object.keys(filterDuplicateUser).map((cat) => {
          if (cat === "dispensary") {
            return `${filterDuplicateUser[cat].length} Dispenseries Selling Strains`;
          } else if (cat === "cannabisLounge") {
            return `${filterDuplicateUser[cat].length} Cannabis Lounges Have Entry Fee`;
          } else if (cat === "seedStore") {
            return `${filterDuplicateUser[cat].length} Seed Stores Selling Seeds`;
          } else if (cat === "growDepot") {
            return `${filterDuplicateUser[cat].length} Grow Depots Selling Equipment`;
          }
        });
        setSharingStatus(formatedMsgs);
      } else {
        // if (Object.keys(groupByCategory).length > 0)
        // setSharingStatus([
        //   `${Object.keys(groupByCategory).length} People Sharing Strains`,
        // ]);
        // console.log("🚀 ~ GetAllProduct ~ groupByCatego:", Object.keys(groupByCategoryLength).length)
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
      console.log(error);
    }
    setIsLoading(false);
  };
  const GetAllProductUseEffect = async (GetAllProductUrl) => {
    setIsLoading(true);
    try {
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      const userId = data?._id;
      const fetchData = await Axios.get(
        GetAllProductUrl.concat(`&ignoreUserId=${userId}`)
      );
      setAllProduct(fetchData.data);
       const groupByCategory = fetchData.data.result.reduce(
         (group, product) => {
           const { userId } = product;
           if (!userId) return group;
           const { storeName, email } = userId;
           const key = storeName || email;
           if (!group[key]) {
             group[key] = [];
           }
           group[key].push(product);
           return group;
         },
         {}
       );
      setGroupData(groupByCategory);
    } catch (error) {
      toast.error(error.response?.data?.message);
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    const savedFilter = JSON.parse(localStorage.getItem("homeFilter"));
    if (savedFilter) {
      const tempRadius = savedFilter.radius.split("-");
      setMinMax({
        minValue: tempRadius[0],
        maxValue: tempRadius[1],
      });
      setFilter(savedFilter);
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      let GetAllProductUrl = `${
        process.env.REACT_APP_API_URI
      }users/getDataByRadius?userType=${userType}${
        savedFilter.radius !== 0 ? `&radius=${savedFilter.radius}` : ""
      }${savedFilter.quantity && `&quantity=${savedFilter.quantity}`}${
        savedFilter.area
          ? `&address=${savedFilter.area}`
          : `&latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}`
      }&category=${categoryFilter?.join(",")}`;
      GetAllProduct(GetAllProductUrl);
    } else {
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      let GetAllProductUrl = `${process.env.REACT_APP_API_URI}users/${
        routeParams.radius
          ? `getDataByRadius?${routeParams.radius}&userType=${userType}&name=${searchTerm}&`
          : `getAllStrainData/?name=${searchTerm}&`
      }latlang=${data?.location?.coordinates[0]},${
        data?.location?.coordinates[1]
      }&userType=${userType}&category=${categoryFilter?.join(",")}`;

      GetAllProduct(GetAllProductUrl).finally(() => {
        setIsLoading(false);
      });
    }
  }, [debouncedSearchedTerm, categoryFilter]);

  const formHandler = (e) => {
    const { name, value } = e.target;
    setFilter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    localStorage.setItem("homeFilter", JSON.stringify(filter));
  };

  const userTyperHandler = (type) => {
    setUserType(type);
    localStorage.setItem("userType", type);
    setcategoryFilter([]);
    const currentUser = localStorage.getItem("userdata");
    let data = JSON.parse(currentUser);
    let GetAllProductUrl = `${
      process.env.REACT_APP_API_URI
    }users/${`getAllStrainData/?name=${searchTerm}&`}latlang=${
      data?.location?.coordinates[0]
    },${data?.location?.coordinates[1]}&userType=${type}&category=`;
    GetAllProduct(GetAllProductUrl);
  };

  // useEffect(() => {
  //   const currentUser = localStorage.getItem("userdata");
  //   let data = JSON.parse(currentUser);
  //   let GetAllProductUrl = `${
  //     process.env.REACT_APP_API_URI
  //   }users/${`getAllData/?`}latlang=${data?.location?.coordinates[0]},${
  //     data?.location?.coordinates[1]
  //   }&userType=${userType}&category=${categoryFilter?.join(",")}`;
  //   GetAllProductUseEffect(GetAllProductUrl);
  // }, []);

  useEffect(() => {
    handleCheckboxChange({ target: { value: "all", checked: true } });
  }, []);

  function handleCheckboxChange(event) {
    async function test() {
      const { value, checked } = event.target;
      if (value === "all") {
        setcategoryFilter(checked ? [""] : []);
        const updatedOptions = options.map((option) => ({
          ...option,
          checked: option.value === "all" ? checked : false,
        }));
        setOptions(updatedOptions);
      } else {
        if (value === "dispensary" && checked && userType === "retailer") {
          setSharingStatus();
        }
        const updatedOptions = options.map((option) =>
          option.value === value ? { ...option, checked } : option
        );
        setOptions(updatedOptions);

        if (checked) {
          setcategoryFilter((prevArray) => [...prevArray, value]);
        } else {
          setcategoryFilter((prevStrings) =>
            prevStrings.filter((string) => string !== value)
          );
        }
        if (categoryFilter.includes("")) {
          setOptions((prevOptions) =>
            prevOptions.map((option) =>
              option.value === "all" ? { ...option, checked: false } : option
            )
          );
          setcategoryFilter((prevStrings) =>
            prevStrings.filter((string) => string !== "")
          );
        }
      }
    }
    test();
  }

  const DashboardAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API_URI}/api/v1/`,
  });

  DashboardAxios.interceptors.request.use((request) => {
    document.querySelector(".loader-main").style.display = "block";
    return request;
  });

  DashboardAxios.interceptors.response.use(
    (response) => {
      document.querySelector(".loader-main").style.display = "none";
      return response;
    },
    (error) => {
      document.querySelector(".loader-main").style.display = "none";
      throw error;
    }
  );

  useEffect(() => {
    const hasDispensary = categoryFilter.includes("dispensary");
    const hasSeedStore =
      categoryFilter.includes("seedStore") ||
      categoryFilter.includes("seedbank");
    const hasBoth = hasDispensary && hasSeedStore;
    // console.log(hasBoth);
    // console.log(quantityFilter);

    if (hasDispensary) {
      setQuantityFilter("dispensary");
      setType("grams");
    }
    if (hasSeedStore) {
      setQuantityFilter("seedStore");
      setType("Seeds");
    }
    if (hasBoth || categoryFilter === "") {
      setQuantityFilter("all");
      setType("Seeds");
    }
  }, [categoryFilter]);

  const submitHandler = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name === "quantity") {
      setFilter((prevState) => ({
        ...prevState,
        quantity: value,
      }));
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      let GetAllProductUrl = `${
        process.env.REACT_APP_API_URI
      }users/getDataByRadius?userType=${userType}&radius=${minMax.minValue}-${
        minMax.maxValue
      }${value && `&quantity=${value}`}${
        filter.area
          ? `&address=${filter.area}`
          : `&latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}`
      }&category=${categoryFilter?.join(",")}`;
      GetAllProduct(GetAllProductUrl);
    } else {
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      let GetAllProductUrl = `${
        process.env.REACT_APP_API_URI
      }users/getDataByRadius?userType=${userType}&radius=${minMax.minValue}-${
        minMax.maxValue
      }${filter.quantity && `&quantity=${filter.quantity}`}${
        filter.area
          ? `&address=${filter.area}`
          : `&latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}`
      }&category=${categoryFilter?.join(",")}`;
      GetAllProduct(GetAllProductUrl);
    }
    const filterTemp = {
      radius: `${minMax.minValue}-${minMax.maxValue}`,
      quantity: filter.quantity,
      area: filter.area,
    };
    localStorage.setItem("homeFilter", JSON.stringify(filterTemp));
  };
  const clearFilterHandler = (e) => {
    e.preventDefault();
    localStorage.removeItem("homeFilter");
    setFilter({
      radius: 0,
      area: "",
      quantity: "",
    });
    setMinMax({
      minValue: 0,
      maxValue: 250,
    });
    setLatAndLng([]);
    const currentUser = localStorage.getItem("userdata");
    let data = JSON.parse(currentUser);
    let GetAllProductUrl = `${
      process.env.REACT_APP_API_URI
    }users/getAllStrainData/?category=${categoryFilter?.join(
      ","
    )}&userType=${userType}&name=${searchTerm}&latlang=${
      data?.location?.coordinates[0]
    },${data?.location?.coordinates[1]}`;
    GetAllProduct(GetAllProductUrl);
  };

  const [minMax, setMinMax] = useState(() => {
    const savedFilter = JSON.parse(localStorage.getItem("homeFilter"));
    if (savedFilter) {
      const tempRadius = savedFilter.radius.split("-");
      return {
        minValue: parseInt(tempRadius[0], 10),
        maxValue: parseInt(tempRadius[1], 10),
      };
    }
    return {
      minValue: 0,
      maxValue: 250,
    };
  });

  const handleInput = useMemo(() => {
    return (e) => {
      setMinMax({
        minValue: e.minValue,
        maxValue: e.maxValue,
      });
      setFilter((prevState) => ({
        ...prevState,
        radius: `${e.minValue}-${e.maxValue}`,
      }));
    };
  }, [setMinMax, setFilter]);

  const handleChange = (event) => {
    setType(event.target.value);
    if (event.target.value === "All") {
      setFilter((prevState) => ({
        ...prevState,
        quantity: "",
      }));
    }
  };

  const handlePlaceChanged = () => {
    const [place] = inputRef1.current.getPlaces();
    if (place) {
      setFilter((prevState) => ({
        ...prevState,
        area: place.formatted_address,
      }));
    }
  };

  return (
    <div className="all-product-section ">
      <div className="allproduct-mob d-block">
        <div className="container mx-auto">
          <div className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between gap-md-4 gap-3 ps-12 pe-12">
            <div className="d-flex align-items-center gap-md-4 gap-3 justify-content-between">
              <h2 className="allproduct-heading">All Products</h2>
              <div
                className="btn-group btn-group-toggle height-56px btn-group-dash"
                data-toggle="buttons"
              >
                <label
                  className={`btn font-14 bg-grey d-flex align-items-center px-sm-3 px-2 ${
                    userType === "retailer" ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    name="options"
                    id="Grams"
                    autoComplete="off"
                    readOnly
                    checked={userType === "retailer"}
                    value="retailer"
                    onChange={(e) => userTyperHandler(e.target.value)}
                  />
                  <span>Retailer</span>
                </label>
                <label
                  className={`btn font-14 bg-grey d-flex align-items-center px-sm-3 px-2 ${
                    userType === "consumer" ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    name="options"
                    id="Seeds"
                    value="consumer"
                    autoComplete="off"
                    checked={userType === "consumer"}
                    onChange={(e) => userTyperHandler(e.target.value)}
                  />
                  <span>Consumer</span>
                </label>
              </div>
            </div>
            <div className="d-flex align-items-center gap-sm-4 gap-3">
              <div className="search-product d-sm-none d-flex">
                <input
                  placeholder="Search Product"
                  type="text"
                  className="border-0 outline-0 bg-transparent w-100"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
                <span className="icon-green-bg">
                  <MobSearchIcon />
                </span>
              </div>
              <div className="d-flex align-items-end gap-md-4 gap-2">
                {Location.pathname.includes("map") ? (
                  <>
                    <Link
                      to={`${"/home"}`}
                      className="text-white view-map-btn p-2 align-items-center gap-3 d-none d-sm-flex"
                    >
                      <span className="d-md-block d-none ps-2">View List</span>
                      <span className="view-map-btn-scope d-none d-sm-flex align-items-center  justify-content-center ">
                        <ListView />
                      </span>
                    </Link>
                    <Link
                      to={`${"/home"}`}
                      className="text-white view-map-btn p-2 d-flex d-sm-none align-items-center gap-3 height-56 rounded-12"
                    >
                      <span className="view-map-btn-scope d-flex d-sm-none align-items-center justify-content-center h-100 w-max-content p-1 rounded-3">
                        <ListView />
                      </span>
                      <span className="view-map-btn-scope d-none d-sm-flex align-items-center  justify-content-center ">
                        <ListView />
                      </span>
                    </Link>
                  </>
                ) : (
                  ""
                )}
                <div className="d-flex align-items-center w-max-content gap-sm-4 gap-3">
                  <div className="d-sm-flex d-flex align-items-center w-max-content">
                    <button
                      className="border-0 outline-0 bg-transparent p-0 height-56"
                      data-bs-toggle="modal"
                      data-bs-target="#deactivatemodal"
                    >
                      <FilterIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-sm-5 mt-4 mb-5 gap-4 d-flex align-items-start justify-content-between ps-12 pe-12">
            <div className="search-product d-sm-flex d-none">
              <input
                placeholder="Search Product"
                className="border-0 outline-0 bg-transparent"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              <div className="icon-green-bg">
                <SearchButtonIcon />
              </div>
            </div>
            <div
              className={`d-flex gap-3 overflow-x-auto all-products-link ${
                isLoading && "blur"
              }`}
            >
              {options.map((option) => {
                if (userType === option?.userType) {
                  return (
                    <label
                      key={option.value}
                      className={`product-item cr-p ${
                        option.checked ? "active" : ""
                      }`}
                    >
                      <input
                        disabled={isLoading}
                        className="d-none"
                        type="checkbox"
                        value={option.value}
                        checked={option.checked}
                        aria-disabled={isLoading}
                        onChange={handleCheckboxChange}
                      />
                      {option.icon} {option.label}
                    </label>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="loader-main">
          <div className="loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="row flex-md-row flex-column-reverse seed-card p-0 flex-row ms-12 me-12">
          <div className="col-12 p-0 mb-md-0">
            <div className="map-container">
              <div
                className={`active show tab-pane h-100 w-100 fade  chat-detail`}
              >
                <div
                  style={{ height: "100%", width: "100%" }}
                  className="custom-map position-relative"
                >
                  <div className="d-flex flex-column justify-content-end w-100 poeple-sharing-seed">
                    {userType !== "consumer" ? (
                      sharingStatus?.map((status, index) => {
                        if (!status) return null;
                        return (
                          <button
                            key={index}
                            className="green-btn-outline bg-white z-index-1 text-black d-flex align-items-center justify-content-between font-18 py-sm-3 gap-2 w-max-content mt-1"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.4"
                                d="M7.0254 0C4.41078 0 2.28516 2.13 2.28516 4.75C2.28516 7.32 4.29103 9.4 6.90564 9.49C6.98548 9.48 7.06531 9.48 7.12519 9.49C7.14515 9.49 7.15513 9.49 7.17509 9.49C7.18507 9.49 7.18507 9.49 7.19505 9.49C9.74979 9.4 11.7557 7.32 11.7656 4.75C11.7656 2.13 9.64001 0 7.0254 0Z"
                                fill="#5D8B2F"
                              />
                              <path
                                d="M12.0951 12.1499C9.31083 10.2899 4.77018 10.2899 1.96595 12.1499C0.698562 12.9999 0 14.1499 0 15.3799C0 16.6099 0.698562 17.7499 1.95597 18.5899C3.3531 19.5299 5.18932 19.9999 7.02553 19.9999C8.86175 19.9999 10.698 19.5299 12.0951 18.5899C13.3525 17.7399 14.0511 16.5999 14.0511 15.3599C14.0411 14.1299 13.3525 12.9899 12.0951 12.1499Z"
                                fill="#5D8B2F"
                              />
                              <path
                                opacity="0.4"
                                d="M17.9929 5.3401C18.1526 7.2801 16.7754 8.9801 14.8694 9.2101C14.8594 9.2101 14.8594 9.2101 14.8494 9.2101H14.8195C14.7596 9.2101 14.6997 9.2101 14.6498 9.2301C13.6818 9.2801 12.7936 8.9701 12.125 8.4001C13.1529 7.4801 13.7417 6.1001 13.6219 4.6001C13.5521 3.7901 13.2726 3.0501 12.8535 2.4201C13.2327 2.2301 13.6718 2.1101 14.1209 2.0701C16.0769 1.9001 17.8233 3.3601 17.9929 5.3401Z"
                                fill="#5D8B2F"
                              />
                              <path
                                d="M19.9894 14.5904C19.9096 15.5604 19.2909 16.4004 18.253 16.9704C17.2551 17.5204 15.9976 17.7804 14.7502 17.7504C15.4687 17.1004 15.8879 16.2904 15.9677 15.4304C16.0675 14.1904 15.4787 13.0004 14.3011 12.0504C13.6325 11.5204 12.8541 11.1004 12.0059 10.7904C14.2113 10.1504 16.9856 10.5804 18.6921 11.9604C19.6102 12.7004 20.0792 13.6304 19.9894 14.5904Z"
                                fill="#5D8B2F"
                              />
                            </svg>
                            {status}
                          </button>
                        );
                      })
                    ) : (
                      <>
                        {allProduct?.length > 0 && (
                          <button className="green-btn-outline bg-white z-index-1 text-black d-flex align-items-center justify-content-between font-18 py-sm-3 gap-2 w-max-content mt-1">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.4"
                                d="M7.0254 0C4.41078 0 2.28516 2.13 2.28516 4.75C2.28516 7.32 4.29103 9.4 6.90564 9.49C6.98548 9.48 7.06531 9.48 7.12519 9.49C7.14515 9.49 7.15513 9.49 7.17509 9.49C7.18507 9.49 7.18507 9.49 7.19505 9.49C9.74979 9.4 11.7557 7.32 11.7656 4.75C11.7656 2.13 9.64001 0 7.0254 0Z"
                                fill="#5D8B2F"
                              />
                              <path
                                d="M12.0951 12.1499C9.31083 10.2899 4.77018 10.2899 1.96595 12.1499C0.698562 12.9999 0 14.1499 0 15.3799C0 16.6099 0.698562 17.7499 1.95597 18.5899C3.3531 19.5299 5.18932 19.9999 7.02553 19.9999C8.86175 19.9999 10.698 19.5299 12.0951 18.5899C13.3525 17.7399 14.0511 16.5999 14.0511 15.3599C14.0411 14.1299 13.3525 12.9899 12.0951 12.1499Z"
                                fill="#5D8B2F"
                              />
                              <path
                                opacity="0.4"
                                d="M17.9929 5.3401C18.1526 7.2801 16.7754 8.9801 14.8694 9.2101C14.8594 9.2101 14.8594 9.2101 14.8494 9.2101H14.8195C14.7596 9.2101 14.6997 9.2101 14.6498 9.2301C13.6818 9.2801 12.7936 8.9701 12.125 8.4001C13.1529 7.4801 13.7417 6.1001 13.6219 4.6001C13.5521 3.7901 13.2726 3.0501 12.8535 2.4201C13.2327 2.2301 13.6718 2.1101 14.1209 2.0701C16.0769 1.9001 17.8233 3.3601 17.9929 5.3401Z"
                                fill="#5D8B2F"
                              />
                              <path
                                d="M19.9894 14.5904C19.9096 15.5604 19.2909 16.4004 18.253 16.9704C17.2551 17.5204 15.9976 17.7804 14.7502 17.7504C15.4687 17.1004 15.8879 16.2904 15.9677 15.4304C16.0675 14.1904 15.4787 13.0004 14.3011 12.0504C13.6325 11.5204 12.8541 11.1004 12.0059 10.7904C14.2113 10.1504 16.9856 10.5804 18.6921 11.9604C19.6102 12.7004 20.0792 13.6304 19.9894 14.5904Z"
                                fill="#5D8B2F"
                              />
                            </svg>
                            {/* groupData{} check no of item inside  */}
                            {`${
                              Object.keys(groupData).length
                            } People Swapping Strain${
                              Object.keys(groupData).length > 1 ? "s" : ""
                            }`}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                  <GoogleMapNew
                    markersData={
                      userType === "consumer" ? groupData : groupData
                    }
                    activeMarker={activeMarker}
                    setActiveMarker={setActiveMarker}
                    handleActiveMarker={handleActiveMarker}
                    isMarkerShown
                    filteredLatAndLng={latAndLng}
                    // isForConsumer={userType === "consumer"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* filter modal */}
      <div
        className="modal fade"
        id="deactivatemodal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog custom-model w-max-content modal-dialog-centered mx-auto filter-model">
          <div className="modal-content justify-content-center p-4">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font-32 font-weight-800 text-center">
                Filter your search
              </p>
              <span className="cr-p" data-bs-dismiss="modal">
                <CrossBorderIcon />
              </span>
            </div>
            <form>
              <div className="d-flex flex-column align-items-start justify-content-center mt-4 pt-2">
                <div className="p-0 bg-transparent border-0 mb-4 w-100">
                  {/* <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP}
                    libraries={libraries}
                  >
                    <StandaloneSearchBox
                      onLoad={(ref) => (inputRef1.current = ref)}
                      onPlacesChanged={handlePlaceChanged}
                    >
                      <div className="form-control h-auto p-0 bg-transparent border-0">
                        <label className="mb-2 font-weight-600 font-18-100">
                          Search an area
                        </label>
                        <input
                          onChange={(e) =>
                            setFilter((prevState) => ({
                              ...prevState,
                              area: e.target.value,
                            }))
                          }
                          value={filter.area}
                          type="text"
                          className="auth-input bg-white height-42"
                          placeholder="Enter here..."
                          name="area"
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </StandaloneSearchBox>
                  </LoadScript> */}

                  <AddressInput
                    setAddressProp={(address) =>
                      setFilter((prevState) => ({
                        ...prevState,
                        area: address,
                      }))
                    }
                    inputLabel="Search an area"
                    className="height-42"
                    addressValue={filter.area}
                    isDark={false}
                    setLatAndLng={setLatAndLng}
                  />
                </div>
                <div className="d-flex flex-column align-items-start justify-content-center w-100 gap-2 mb-4">
                  {/* <label className="font-weight-600 font-18-100">
                      Distance
                    </label>
                     <input
                      type="range"
                      className="form-control-range w-100"
                      min="0"
                      max="50"
                      step="10"
                      name="radius"
                      value={filter.radius}
                      onChange={(e) =>
                        setFilter((prevState) => ({
                          ...prevState,
                          radius: e.target.value,
                        }))
                      }
                    ></input>
                    <p className="rangetext d-flex w-100 justify-content-between ">
                      <span>All</span>
                      <span></span>
                      <span>0-10km</span>
                      <span>0-20km</span>
                      <span>0-30km</span>
                      <span>0-40km</span>
                      <span>0-50km</span>
                    </p> */}
                  <label className="font-weight-600 font-18-100 d-flex justify-content-between w-100">
                    <span className="text-dark">Distance(km)</span>
                    <span className="text-dark">
                      {minMax.minValue}-{minMax.maxValue}
                    </span>
                  </label>
                  <MultiRangeSlider
                    className="shadow-none border-0 py-0 px-2 py-2 w-100"
                    min={0}
                    max={250}
                    minValue={minMax.minValue}
                    maxValue={minMax.maxValue}
                    ruler={false}
                    label={false}
                    barLeftColor="white"
                    barInnerColor="#5D8B2F"
                    barRightColor="white"
                    thumbLeftColor="white"
                    thumbRightColor="white"
                    onInput={handleInput}
                  />
                </div>
              </div>

              <div className="gap-3 mb-3">
                {quantityFilter === "all" || quantityFilter === "" ? (
                  <div
                    className="btn-group btn-group-toggle height-42 mb-4 btn-group-dash"
                    data-toggle="buttons"
                  >
                    <label className="btn font-14 bg-grey active d-flex align-items-center py-3 px-sm-3 px-2">
                      <input
                        type="radio"
                        name="options"
                        id="Grams"
                        autoComplete="off"
                        readOnly
                        checked={type === "Grams"}
                        onChange={handleChange}
                        value="Grams"
                      />
                      <span className="pl-2">Grams</span>
                    </label>
                    <label className="btn font-14 bg-grey d-flex align-items-center px-sm-3 px-2">
                      <input
                        type="radio"
                        name="options"
                        id="Seeds"
                        value="Seeds"
                        autoComplete="off"
                        checked={type === "Seeds"}
                        onChange={handleChange}
                      />
                      <span className="pl-2">Seeds</span>
                    </label>
                    <label className="btn font-14 bg-grey d-flex align-items-center">
                      <input
                        type="radio"
                        name="options"
                        id="All"
                        value="All"
                        autoComplete="off"
                        checked={type === "All"}
                        onChange={handleChange}
                      />
                      <span className="pl-2">All</span>
                    </label>
                  </div>
                ) : (
                  ""
                )}
                {customType === "CustomSelect" && type !== "All" && (
                  <div className="form-control h-auto p-0 mb-5 bg-transparent border-0">
                    <select
                      className="auth-input bg-white height-42 m-0"
                      required
                      name="quantity"
                      onChange={(e) => formHandler(e)}
                    >
                      <option value={""}>- Search by Quantity -</option>
                      <option value={type === "Seeds" ? "1-4" : "1-7"}>
                        {type === "Seeds" ? "1-4 Seeds" : "1-7 Grams"}
                      </option>
                      <option value={type === "Seeds" ? "5-10" : "7-14"}>
                        {type === "Seeds" ? "5-10 Seeds" : "7-14 Grams"}
                      </option>
                      <option value={type === "Seeds" ? "11-15" : "14-30"}>
                        {type === "Seeds" ? "11-15 Seeds" : "14-30 Grams"}
                      </option>
                      {type === "Seeds" && (
                        <option value={"16-20"}>{"15-20 Seeds"}</option>
                      )}
                    </select>
                  </div>
                )}

                {customType === "CustomAny" && (
                  <div className="form-control h-auto p-0 mb-5 bg-transparent border-0">
                    <input
                      type="text"
                      name="quantity"
                      placeholder={type === "Seeds" ? "1-7 Seeds" : "1-7 Grams"}
                      className="auth-input height-42 bg-white"
                      onChange={(e) => formHandler(e)}
                    />
                    <p className="text-danger mt-2">
                      Value must be in range format E.g 1-7, 14-20 etc
                    </p>
                  </div>
                )}
                <div className="d-none d-sm-flex d-md-none align-items-center gap-4 w-max-content">
                  {/* <button
                    className="border-0 outline-0 bg-transparent p-0 height-56"
                    data-bs-toggle="modal"
                    data-bs-target="#deactivatemodal"
                  >
                    <FilterIcon />
                  </button> */}
                </div>
              </div>

              <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center w-100 mt-4 flex-wrap">
                <button
                  className="light-red-btn-outline text-white custom-w min-width-208 height-42"
                  onClick={clearFilterHandler}
                  data-bs-dismiss="modal"
                >
                  Clear Filter
                </button>
                <button
                  className="green-btn-outline text-primary-green custom-w min-width-208 height-42"
                  data-bs-dismiss="modal"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="green-btn custom-w min-width-208 height-42"
                  onClick={(e) => submitHandler(e)}
                  data-bs-dismiss="modal"
                >
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProductMapView;
