import React from "react";

const ListView = () => {
  return (
    <>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2.5 5.5H17.5" stroke="#5D8B2F" strokeLinecap="round" />
        <path d="M2.5 10.5H17.5" stroke="#5D8B2F" strokeLinecap="round" />
        <path d="M2.5 15.5H17.5" stroke="#5D8B2F" strokeLinecap="round" />
      </svg>
    </>
  );
};

export default ListView;
