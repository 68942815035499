import React from "react";

const UserStartIcon = () => {
  return (
    <>
      <svg
        width={27}
        height={29}
        viewBox="0 0 27 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.2893 13.2631C12.8526 13.2631 14.352 12.6421 15.4575 11.5366C16.563 10.4311 17.184 8.93175 17.184 7.36837C17.184 5.80499 16.563 4.30564 15.4575 3.20016C14.352 2.09468 12.8526 1.47363 11.2893 1.47363C9.72589 1.47363 8.22654 2.09468 7.12106 3.20016C6.01558 4.30564 5.39453 5.80499 5.39453 7.36837C5.39453 8.93175 6.01558 10.4311 7.12106 11.5366C8.22654 12.6421 9.72589 13.2631 11.2893 13.2631Z"
          stroke="#5D8B2F"
          strokeWidth="1.95"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.973633 25.0528V23.5792C0.973274 21.8387 1.41328 20.1264 2.2527 18.6018C3.09211 17.0771 4.30364 15.7896 5.7745 14.8592C7.24536 13.9287 8.92773 13.3855 10.665 13.2801C12.4023 13.1748 14.138 13.5107 15.7105 14.2566M18.1199 19.7697L19.6511 16.5232C19.6925 16.4304 19.7599 16.3515 19.8452 16.2962C19.9304 16.2409 20.0299 16.2115 20.1315 16.2115C20.2331 16.2115 20.3326 16.2409 20.4179 16.2962C20.5031 16.3515 20.5705 16.4304 20.6119 16.5232L22.1446 19.7697L25.5679 20.2943C26.0071 20.3606 26.181 20.9265 25.8627 21.2507L23.3869 23.7752L23.9705 27.3429C24.0456 27.8013 23.5873 28.1505 23.1938 27.9339L20.1315 26.2495L17.0692 27.9339C16.6757 28.1505 16.2174 27.8013 16.2926 27.3444L16.8762 23.7752L14.4004 21.2507C14.0821 20.9265 14.2559 20.3606 14.6951 20.2928L18.1199 19.7697Z"
          stroke="#5D8B2F"
          strokeWidth="1.95"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default UserStartIcon;
