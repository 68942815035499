import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button } from 'react-bootstrap';
import { createSubscription } from '../../Api/index';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddressInput from '../Address/address.js';

const CARD_ELEMENT_OPTIONS = {
  iconStyle: 'solid',
  hidePostalCode: true,
  style: {
    base: {
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#CFD7DF'
      }
    },
    invalid: {
      color: 'red'
    }
  }
};

const CardPaymentForm = ({ type, setModalShow, route, setsubsType }) => {
  const [postalCode, setpostalCode] = useState('');
  const [userAdress, setUserAddress] = useState({
    address: '',
    city: '',
    state: '',
    country: '',
    postal_code: ''
  });
  const [address, setAddress] = useState({
    address: JSON.parse(localStorage.getItem('signupData'))?.location?.address || '',
    postalCode: JSON.parse(localStorage.getItem('signupData'))?.location?.postalCode || '',
    phone: JSON.parse(localStorage.getItem('signupData'))?.location?.phone || ''
  });
  const [isUserAddressExists, setIsAddressExists] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  useEffect(() => {
    setAddress(prevState => ({
      ...prevState,
      postalCode: postalCode
    }));
  }, [address.address]);

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('userdata'));

    if (
      currentUser &&
      currentUser?.location?.address &&
      currentUser?.location?.address != '' &&
      currentUser?.location?.country &&
      currentUser?.location?.country != ''
    ) {
      setIsAddressExists(true);
    }
  }, []);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (!isUserAddressExists && address.address === '') {
      toast.error('Please select address from dropdown');
      return;
    }

    const paymentMethod = await stripe?.createPaymentMethod({
      type: 'card',
      card: elements?.getElement(CardElement)
    });

    if (paymentMethod.error) {
      console.log(paymentMethod.error.message);
      toast.error(paymentMethod.error.message);
    } else {
      const userId = JSON.parse(localStorage.getItem('userdata'))?._id;
      const data = {
        planId: type,
        payment_method: paymentMethod?.paymentMethod?.id,
        userId
      };
      if (!isUserAddressExists) {
        data.city = userAdress?.city;
        data.state = userAdress?.state;
        data.country = userAdress?.country;
        data.address = userAdress?.address;
        data.postalCode = address?.postalCode;

        if (data?.state == '') {
          data.state = userAdress?.country;
        }
        if (data?.city == '') {
          data.city = userAdress?.state;
        }
      }
      createSubscription(data, route, navigate, setModalShow, setsubsType);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <div className={`${isUserAddressExists ? 'd-none' : 'd-block'}`}>
        <div className="mt-4">
          <AddressInput
            setAddressProp={address =>
              setAddress(prevState => ({
                ...prevState,
                address: address
              }))
            }
            setpostalCode={setpostalCode}
            addressValue={address.address}
            isDark={false}
            setUserAddress={setUserAddress}
          />
        </div>
        <div className="form-control h-auto p-0 bg-transparent border-0 mb-2">
          <label className="mb-2 font-weight-600 font-18-100">Postal Code / Zip Code</label>
          <input
            type="text"
            required={!isUserAddressExists}
            className="auth-inputs"
            placeholder="Enter Postal Code/Zip Code"
            value={address.postalCode}
            onChange={e => {
              setAddress(prevState => ({
                ...prevState,
                postalCode: e.target.value
              }));
            }}
          />
        </div>
      </div>
      <div className="text-center mt-3">
        <Button type="submit" variant="success" className="green-btn w-max-content height-56-important px-5 mt-4">
          Subscribe Now
        </Button>
      </div>
    </form>
  );
};

export default CardPaymentForm;
