import React from 'react'

const VaporizeIcon = () => {
    return (
        <>
            <svg width={15} height={24} viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3928 10.6666H12.8571C12.8571 9.78398 12.1367 9.06651 11.2499 9.06651V7.99995C11.2499 7.11734 10.5295 6.39988 9.64268 6.39988H7.49978V4.26652C7.49978 3.64248 7.13824 3.10115 6.6104 2.8372L5.98357 0.759848C5.84736 0.30673 5.4348 0 4.95802 0H4.14919C3.67237 0 3.25981 0.30673 3.12325 0.760038L2.49642 2.83739C1.96879 3.10134 1.60723 3.64262 1.60723 4.26671V9.06665C0.720677 9.06665 0 9.78388 0 10.6667V22.3999C0 23.2825 0.720438 24 1.60723 24H11.2501C12.1366 24 12.8573 23.2828 12.8573 22.3999L12.8571 15.4667H13.3928C14.2793 15.4667 15 14.7494 15 13.8666V12.2665C15 11.3839 14.2794 10.6666 13.3928 10.6666L13.3928 10.6666ZM9.64292 7.46667C9.93757 7.46667 10.1786 7.70663 10.1786 7.99995V9.06651H7.50002V7.46643L9.64292 7.46667ZM2.67866 4.26676C2.67866 3.97342 2.91969 3.73348 3.21432 3.73348H5.89288C6.18754 3.73348 6.42855 3.97343 6.42855 4.26676V5.33332H2.67866V4.26676ZM4.95811 1.06665L5.44016 2.66673H3.66705L4.14909 1.06665H4.95811ZM2.67866 6.39992H6.42855V9.06656H2.67866V6.39992ZM1.60733 10.1333H11.2502C11.5448 10.1333 11.7858 10.3733 11.7858 10.6666V20.2665L1.07157 20.2667V10.6668C1.07157 10.3734 1.3126 10.1335 1.60723 10.1335L1.60733 10.1333ZM11.2502 22.9332H1.60733C1.31268 22.9332 1.07166 22.6932 1.07166 22.3999V21.3333H11.7859V22.3999C11.7859 22.693 11.5447 22.9332 11.2501 22.9332H11.2502ZM13.9287 13.8666C13.9287 14.1599 13.6877 14.3999 13.3931 14.3999H12.8574V11.7332H13.3931C13.6877 11.7332 13.9287 11.9732 13.9287 12.2665V13.8666Z" fill="#707070" />
                <path d="M4.82259 15.9996C6.00378 15.9996 6.96548 15.0423 6.96548 13.8663C6.96548 12.6903 6.00383 11.7329 4.82259 11.7329C3.64134 11.7329 2.67969 12.6903 2.67969 13.8663C2.67969 15.0422 3.64134 15.9996 4.82259 15.9996ZM4.82259 12.7997C5.4145 12.7997 5.89391 13.277 5.89391 13.8663C5.89391 14.4555 5.4145 14.9328 4.82259 14.9328C4.23067 14.9328 3.75126 14.4555 3.75126 13.8663C3.75126 13.277 4.23067 12.7997 4.82259 12.7997Z" fill="#707070" />
                <path d="M3.21535 18.6667H4.82259C5.11724 18.6667 5.35825 18.4267 5.35825 18.1334C5.35825 17.84 5.11722 17.6001 4.82259 17.6001H3.21535C2.9207 17.6001 2.67969 17.8401 2.67969 18.1334C2.67969 18.4267 2.92071 18.6667 3.21535 18.6667Z" fill="#707070" />
            </svg>
        </>
    )
}

export default VaporizeIcon