import React from 'react'

const DispensaryIcon = () => {
    return (
        <>
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_51_10318)">
                    <path d="M12.3431 0.822719C12.2824 0.688094 12.1483 0.601562 12.0006 0.601562C11.8528 0.601562 11.7189 0.688094 11.6581 0.822719C10.5875 3.19427 10.0215 5.95056 10.0215 8.79362C10.0215 11.6068 10.5759 14.3347 11.6248 16.6892V23.0232C11.6248 23.2307 11.7931 23.399 12.0006 23.399C12.2082 23.399 12.3764 23.2308 12.3764 23.0232V16.6892C13.4254 14.3347 13.9797 11.6067 13.9797 8.79358C13.9797 5.95052 13.4138 3.19422 12.3431 0.822719ZM12.0006 15.6367C11.1957 13.5553 10.773 11.2085 10.773 8.79358C10.773 6.37862 11.1957 4.03192 12.0006 1.95044C12.8055 4.03192 13.2282 6.37867 13.2282 8.79358C13.2282 11.2085 12.8055 13.5552 12.0006 15.6367Z" fill="#707070" />
                    <path d="M11 17.984C9.53538 17.0472 7.75212 16.3798 5.8429 16.054C3.93462 15.7282 2.01527 15.7632 0.292428 16.1552C0.144725 16.1888 0.0316157 16.3078 0.00560005 16.457C-0.0204156 16.6063 0.0456782 16.7566 0.173366 16.8382C1.63797 17.7749 3.42124 18.4423 5.33046 18.7682C6.19408 18.9156 7.05991 18.9892 7.90882 18.9892C8.93566 18.9892 9.9378 18.8816 10.8809 18.667C11.0286 18.6334 11.1417 18.5143 11.1677 18.3651C11.1938 18.2159 11.1277 18.0656 11 17.984ZM5.45697 18.0274C4.01097 17.7806 2.64401 17.3291 1.44893 16.7076C2.80783 16.5184 4.26415 16.5469 5.71652 16.7948C7.16247 17.0416 8.52949 17.4931 9.72452 18.1146C8.36552 18.3038 6.9093 18.2753 5.45697 18.0274Z" fill="#707070" />
                    <path d="M11.1367 19.6925C11.0808 19.5758 10.9689 19.4959 10.8403 19.4812C9.73891 19.3552 8.63262 19.5227 7.64112 19.9657C6.64343 20.4115 5.82335 21.1086 5.26943 21.9816C5.2001 22.0908 5.19185 22.2281 5.24763 22.3449C5.30346 22.4617 5.4154 22.5415 5.54402 22.5563C5.78201 22.5835 6.02018 22.597 6.25741 22.597C7.11808 22.597 7.96596 22.419 8.74319 22.0717C9.74088 21.6259 10.561 20.9289 11.1149 20.0559C11.1842 19.9466 11.1925 19.8093 11.1367 19.6925ZM8.43658 21.3856C7.77133 21.6828 7.04622 21.8385 6.30846 21.8453C6.74693 21.3464 7.30394 20.9397 7.94777 20.652C8.61302 20.3547 9.33813 20.1991 10.0759 20.1923C9.63743 20.6911 9.08041 21.0978 8.43658 21.3856Z" fill="#707070" />
                    <path d="M11.1582 16.9029C10.4683 14.5904 9.21723 12.289 7.54037 10.2476C5.8629 8.20542 3.86129 6.54764 1.75196 5.45339C1.61804 5.38388 1.45562 5.40122 1.33942 5.49736C1.22317 5.5935 1.17573 5.74983 1.21881 5.89434C1.90876 8.20687 3.15976 10.5083 4.83667 12.5497C6.51413 14.5919 8.51574 16.2496 10.6251 17.3439C10.6797 17.3722 10.739 17.3861 10.798 17.3861C10.8838 17.3861 10.9688 17.3568 11.0376 17.2999C11.1539 17.2037 11.2013 17.0474 11.1582 16.9029ZM5.41745 12.0727C4.02399 10.3763 2.93696 8.49656 2.24021 6.58753C3.9526 7.61719 5.56998 9.03286 6.95959 10.7245C8.35304 12.4209 9.43998 14.3006 10.1368 16.2097C8.42438 15.1801 6.80706 13.7644 5.41745 12.0727Z" fill="#707070" />
                    <path d="M23.9939 16.457C23.9678 16.3078 23.8548 16.1887 23.7071 16.1552C21.9841 15.7632 20.0648 15.7282 18.1566 16.0539C16.2474 16.3798 14.4641 17.0472 12.9995 17.984C12.8719 18.0656 12.8058 18.2158 12.8318 18.3651C12.8578 18.5143 12.9709 18.6334 13.1186 18.6669C14.0618 18.8815 15.0638 18.9891 16.0906 18.9891C16.9395 18.9891 17.8055 18.9156 18.669 18.7682C20.5782 18.4423 22.3615 17.7749 23.8261 16.8381C23.9538 16.7565 24.0199 16.6063 23.9939 16.457ZM18.5427 18.0273C17.0903 18.2752 15.634 18.3037 14.2751 18.1146C15.4702 17.4931 16.8371 17.0416 18.2831 16.7947C19.7355 16.5468 21.1918 16.5184 22.5507 16.7075C21.3556 17.3291 19.9886 17.7805 18.5427 18.0273Z" fill="#707070" />
                    <path d="M18.7302 21.9816C18.1763 21.1086 17.3562 20.4115 16.3585 19.9657C15.367 19.5227 14.2608 19.3551 13.1593 19.4812C13.0307 19.4959 12.9187 19.5757 12.8629 19.6926C12.8071 19.8094 12.8153 19.9466 12.8847 20.0559C13.4386 20.9288 14.2587 21.6259 15.2564 22.0717C16.0337 22.4191 16.8814 22.5971 17.7422 22.597C17.9793 22.597 18.2176 22.5835 18.4556 22.5563C18.5841 22.5416 18.6961 22.4617 18.7519 22.3449C18.8078 22.2281 18.7996 22.0909 18.7302 21.9816ZM15.5631 21.3855C14.9193 21.0978 14.3622 20.6911 13.9238 20.1922C14.6615 20.199 15.3867 20.3547 16.0519 20.6519C16.6957 20.9396 17.2527 21.3463 17.6912 21.8452C16.9535 21.8383 16.2282 21.6827 15.5631 21.3855Z" fill="#707070" />
                    <path d="M22.6607 5.49735C22.5445 5.40125 22.382 5.38386 22.2481 5.45338C20.1388 6.54763 18.1372 8.20536 16.4597 10.2476C14.7828 12.289 13.5318 14.5903 12.8419 16.9029C12.7988 17.0474 12.8462 17.2038 12.9625 17.2999C13.0313 17.3568 13.1164 17.3862 13.202 17.3862C13.2611 17.3862 13.3204 17.3723 13.375 17.3439C15.4843 16.2498 17.4859 14.5919 19.1634 12.5497C20.8403 10.5083 22.0914 8.20696 22.7813 5.89438C22.8244 5.74981 22.7769 5.59349 22.6607 5.49735ZM18.5826 12.0726C17.193 13.7644 15.5756 15.1801 13.8633 16.2096C14.56 14.3006 15.647 12.4209 17.0405 10.7245C18.4302 9.03275 20.0475 7.61703 21.7599 6.58747C21.063 8.49655 19.9761 10.3763 18.5826 12.0726Z" fill="#707070" />
                </g>
                <defs>
                    <clipPath id="clip0_51_10318">
                        <rect width={24} height={24} fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </>
    )
}

export default DispensaryIcon