import React from 'react'

const FavouriteIcon = () => {
    return (
        <>
            <svg width={18} height={24} viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.81165 2.10437C6.61776 2.10437 4.85257 3.7256 4.85257 5.72389C4.85257 7.665 6.50612 9.23888 8.59567 9.33127C8.73388 9.32315 8.87904 9.3227 9.01857 9.33116C11.1054 9.2377 12.7606 7.66517 12.7707 5.72168C12.7694 3.72594 10.9949 2.10437 8.81165 2.10437ZM2.55078 5.72389C2.55078 2.56647 5.34314 0 8.81165 0C12.267 0 15.0725 2.56493 15.0725 5.72389V5.72827H15.0725C15.0584 8.81523 12.3962 11.3334 9.03446 11.4367C8.98334 11.4382 8.93217 11.4367 8.88128 11.432C8.84564 11.4288 8.7811 11.4279 8.72019 11.4329C8.67271 11.4369 8.62499 11.4381 8.57733 11.4367C5.2164 11.3334 2.55078 8.81503 2.55078 5.72389Z" fill="#5D8B2F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.00863 12.8232C11.2709 12.8232 13.5925 13.3415 15.3986 14.4441C17.0309 15.4372 18 16.8526 18 18.4038C18 19.9545 17.0314 21.3721 15.4005 22.3702L15.4002 22.3704C13.5888 23.4784 11.2639 24.0001 9 24.0001C6.73659 24.0001 4.41231 23.4787 2.60103 22.3711C0.968935 21.378 0 19.9627 0 18.4117C0 16.8609 0.968581 15.4434 2.59955 14.4452L2.60377 14.4426L2.60378 14.4426C4.41978 13.3418 6.74568 12.8232 9.00863 12.8232ZM3.87791 16.1952C2.72528 16.9013 2.30179 17.7243 2.30179 18.4117C2.30179 19.0988 2.72494 19.919 3.87726 20.6199L3.87978 20.6214C5.23328 21.4493 7.08619 21.8957 9 21.8957C10.9137 21.8957 12.7665 21.4494 14.12 20.6215M3.87791 16.1952C5.23818 15.3713 7.09484 14.9276 9.00863 14.9276C10.9238 14.9276 12.7738 15.3719 14.121 16.1945L14.1227 16.1956C15.2751 16.8964 15.6982 17.7167 15.6982 18.4038C15.6982 19.0915 15.2743 19.915 14.1202 20.6214" fill="#5D8B2F" />
            </svg>
        </>
    )
}

export default FavouriteIcon