import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Axios from "../../axios/Axios";
import GoogleMapNew from "./GoogleMap/GoogleMapNew";
import CrossBorderIcon from "../../assets/Images/CrossBorder";
import MultiRangeSlider from "multi-range-slider-react";
// import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import AddressInput from "../Address/address";
import ListView from "../../assets/Images/ListView";
import FilterIcon from "../../assets/Images/filterIcon";
import EmptyDataImage from "../../assets/Images/EmptyData";

const seedsDetail = [
  {
    id: 1,
    name: "Raza Awan",
    active: false,
  },
  {
    id: 2,
    name: "Raza Awan",
    active: true,
  },
  {
    id: 3,
    name: "Raza Awan",
    active: false,
  },
];
const SeedMap = () => {
    const [seeds, setSeeds] = useState([]);
    const routeParams = useParams();
    const [currentuserData, setcurrentuserData] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [type, setType] = useState("Grams");
    const [customType, setCustomType] = useState("CustomSelect");
    const [isLoading, setIsLoading] = useState(false);
    const Location = useLocation();
    const handleChange = (event) => {
      setType(event.target.value);
      if (event.target.value === "All") {
        setFilter((prevState) => ({
          ...prevState,
          quantity: "",
        }));
      }
    };
    const [latAndLng, setLatAndLng] = useState([]);

    const GetSeeds = async (GetSeedsUrl) => {
      setIsLoading(true);
      try {
        const currentUser = localStorage.getItem("userdata");
        let data = JSON.parse(currentUser);
        const userId = data?._id;
        const fetchData = await Axios.get(
          GetSeedsUrl.concat(`&ignoreUserId=${userId}`)
        );
        setSeeds(fetchData.data);
      } catch (error) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      }
      setIsLoading(false);
    };
    const [activeMarker, setActiveMarker] = useState(null);
    const handleActiveMarker = (marker) => {
      if (marker === activeMarker) {
        return;
      }
      setActiveMarker(marker);
    };
    

    useEffect(() => {
      const savedFilter = JSON.parse(localStorage.getItem("budSeedFilter"));
      if (savedFilter) {
        setFilter(savedFilter);
        const currentUser = localStorage.getItem("userdata");
        let data = JSON.parse(currentUser);
        let  GetSeedsUrl  = `${
          process.env.REACT_APP_API_URI
        }users/getDataByRadius?${`&radius=${savedFilter.radius}`}${
          savedFilter.quantity ? `&quantity=${savedFilter.quantity}` : ""
        }${
          filter.area
            ? `&address=${savedFilter.area}`
            : `&latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}`
        }`;
        GetSeeds(GetSeedsUrl);
      }else{
        const currentUser = localStorage.getItem("userdata");
        let data = JSON.parse(currentUser);
        setcurrentuserData(data);
        let GetSeedsUrl = `${process.env.REACT_APP_API_URI}users/${
          routeParams.radius
            ? `getDataByRadius?${routeParams.radius}&name=${searchTerm}&page=1&`
            : `getAllStrainData/?page=1&`
        }userType=consumer&latlang=${data?.location?.coordinates[0]},${
          data?.location?.coordinates[1]}`;
              
      GetSeeds(GetSeedsUrl);
      }

    }, []);

    const [filter, setFilter] = useState({
      radius: 0,
      area: "",
      quantity: "",
    });
    const formHandler = (e) => {
      const { name, value } = e.target;
      setFilter((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      localStorage.setItem("budSeedFilter", JSON.stringify(filter));

    };
    const inputRef1 = useRef();
    const handlePlaceChanged = () => {
      const [place] = inputRef1.current.getPlaces();
      if (place) {
        setFilter((prevState) => ({
          ...prevState,
          area: place.formatted_address,
        }));
      }
    };

    const GetAllProduct = async (GetAllProductUrl) => {
      setIsLoading(true);
      try {
        const currentUser = localStorage.getItem("userdata");
        let data = JSON.parse(currentUser);
        const userId = data?._id;
        const fetchData = await Axios.get(
          GetAllProductUrl.concat(`&ignoreUserId=${userId}`)
        );
        // console.log(fetchData.data);
        setSeeds(fetchData.data);
      } catch (error) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      }
      setIsLoading(false);
    };
    const submitHandler = (event) => {
      event.preventDefault();
      localStorage.setItem("budSeedFilter", JSON.stringify(filter));
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      // console.log(filter.area);
      let GetAllProductUrl = `${
        process.env.REACT_APP_API_URI
      }users/getDataByRadius?${`&radius=${filter.radius}`}${
        filter.quantity ? `&quantity=${filter.quantity}` : ""
      }${
        filter.area
          ? `&address=${filter.area}`
          : `&latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}`
      }`;
      GetAllProduct(GetAllProductUrl);
    };

    const clearFilterHandler = (e) => {
      e.preventDefault();
      localStorage.removeItem("budSeedFilter");
      setFilter({
        radius: 0,
        area: "",
        quantity: "",
      });
      setLatAndLng([]);
      const currentUser = localStorage.getItem("userdata");
      let data = JSON.parse(currentUser);
      let GetSeedsUrl = `${process.env.REACT_APP_API_URI}users/getAllStrainData/?userType=consumer&name=${searchTerm}&latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}`;
      GetSeeds(GetSeedsUrl);
    };
    const [minMax, setMinMax] = useState({
      minValue: 0,
      maxValue: 250,
    });
    const handleInput = (e) => {
      setMinMax({
        minValue: e.minValue,
        maxValue: e.maxValue,
      });
      setFilter((prevState) => ({
        ...prevState,
        radius: `${e.minValue}-${e.maxValue}`,
      }));
    };
  
  return (
    <div className="ps-12 pe-12">
      <div className="allproduct-mob d-block">
        <div className="container mx-auto mb-5">
          <div className="d-flex align-items-sm-center align-items-center justify-content-between gap-sm-4 gap-3 flex-md-nowrap flex-wrap">
            <h2 className="bordered-heading">Seeds and Buds for swap</h2>
            <div className="d-flex align-items-end gap-sm-4 gap-3">
              {Location.pathname.includes("map") ? (
                <>
                  <Link
                    to={`${"/home/budseedswap"}`}
                    className="text-white view-map-btn p-2 align-items-center gap-3 d-none d-sm-flex"
                  >
                    <span className="d-block ps-2">View List</span>
                    <span className="view-map-btn-scope d-none d-sm-flex align-items-center  justify-content-center ">
                      <ListView />
                    </span>
                  </Link>
                  <Link
                    to={`${"/home/budseedswap"}`}
                    className="text-white view-map-btn p-2 d-flex d-sm-none align-items-center gap-3 height-56 rounded-12"
                  >
                    <span className="d-block ps-2">View List</span>
                    <span className="view-map-btn-scope d-flex d-sm-none align-items-center justify-content-center h-100 w-max-content p-1 rounded-3">
                      <ListView />
                    </span>
                    <span className="view-map-btn-scope d-none d-sm-flex align-items-center  justify-content-center ">
                      <ListView />
                    </span>
                  </Link>
                </>
              ) : (
                ""
              )}
              <button
                className="border-0 outline-0 bg-transparent p-0 d-none d-sm-block"
                data-bs-toggle="modal"
                data-bs-target="#deactivatemodal"
              >
                <svg
                  className="w-auto h-100"
                  width={40}
                  height={40}
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width={50}
                    height={50}
                    rx="15.5"
                    fill="#5D8B2F"
                    fillOpacity="0.2"
                    stroke="#5D8B2F"
                  />
                  <path
                    d="M41.9999 15.2328V18.7889C41.9999 20.0821 41.186 21.6985 40.372 22.5067L33.3721 28.649C32.3954 29.4572 31.7442 31.0736 31.7442 32.3668V39.3173C31.7442 40.2872 31.0931 41.5803 30.2791 42.0652L28.0001 43.52C25.8838 44.8131 22.9536 43.3584 22.9536 40.7721V32.2051C22.9536 31.0736 22.3025 29.6189 21.6513 28.8107L20.0234 27.1134L29.4977 12H38.7442C40.5348 12 41.9999 13.4548 41.9999 15.2328Z"
                    fill="#5D8B2F"
                  />
                  <path
                    opacity="0.4"
                    d="M26.8603 12L18.4279 25.4324L15.4651 22.345C14.6512 21.5368 14 20.0821 14 19.1122V15.3945C14 13.4548 15.4651 12 17.2558 12H26.8603Z"
                    fill="#5D8B2F"
                  />
                </svg>
              </button>
              <div className="d-flex d-sm-none align-items-center w-max-content">
                <button
                  className="border-0 outline-0 bg-transparent p-0 height-56"
                  data-bs-toggle="modal"
                  data-bs-target="#deactivatemodal"
                >
                  <FilterIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row flex-md-row flex-column-reverse seed-card p-0 flex-row ms-12 me-12">
        <div className="col-12 p-0 mb-md-0">
          {seedsDetail.map((chatsdetail, index) => {
            return (
              <div
                key={index}
                className={`${
                  chatsdetail.active ? "active show" : ""
                } tab-pane h-100 w-100 fade  chat-detail`}
                id={`v-pills-${chatsdetail.id}`}
                role="tabpanel"
                aria-labelledby={`v-pills-${chatsdetail.id}-tab`}
              >
                <div
                  style={{ height: "100vh", width: "100%" }}
                  className="custom-map position-relative"
                >
                  {
                    seeds.length > 0 ?( <div className="d-flex flex-column justify-content-end w-100 poeple-sharing-seed">
                    <button className="green-btn-outline bg-white z-index-1 text-black d-flex align-items-center justify-content-between font-18 py-sm-3 gap-2 w-max-content">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.4"
                          d="M7.0254 0C4.41078 0 2.28516 2.13 2.28516 4.75C2.28516 7.32 4.29103 9.4 6.90564 9.49C6.98548 9.48 7.06531 9.48 7.12519 9.49C7.14515 9.49 7.15513 9.49 7.17509 9.49C7.18507 9.49 7.18507 9.49 7.19505 9.49C9.74979 9.4 11.7557 7.32 11.7656 4.75C11.7656 2.13 9.64001 0 7.0254 0Z"
                          fill="#5D8B2F"
                        />
                        <path
                          d="M12.0951 12.1499C9.31083 10.2899 4.77018 10.2899 1.96595 12.1499C0.698562 12.9999 0 14.1499 0 15.3799C0 16.6099 0.698562 17.7499 1.95597 18.5899C3.3531 19.5299 5.18932 19.9999 7.02553 19.9999C8.86175 19.9999 10.698 19.5299 12.0951 18.5899C13.3525 17.7399 14.0511 16.5999 14.0511 15.3599C14.0411 14.1299 13.3525 12.9899 12.0951 12.1499Z"
                          fill="#5D8B2F"
                        />
                        <path
                          opacity="0.4"
                          d="M17.9929 5.3401C18.1526 7.2801 16.7754 8.9801 14.8694 9.2101C14.8594 9.2101 14.8594 9.2101 14.8494 9.2101H14.8195C14.7596 9.2101 14.6997 9.2101 14.6498 9.2301C13.6818 9.2801 12.7936 8.9701 12.125 8.4001C13.1529 7.4801 13.7417 6.1001 13.6219 4.6001C13.5521 3.7901 13.2726 3.0501 12.8535 2.4201C13.2327 2.2301 13.6718 2.1101 14.1209 2.0701C16.0769 1.9001 17.8233 3.3601 17.9929 5.3401Z"
                          fill="#5D8B2F"
                        />
                        <path
                          d="M19.9894 14.5904C19.9096 15.5604 19.2909 16.4004 18.253 16.9704C17.2551 17.5204 15.9976 17.7804 14.7502 17.7504C15.4687 17.1004 15.8879 16.2904 15.9677 15.4304C16.0675 14.1904 15.4787 13.0004 14.3011 12.0504C13.6325 11.5204 12.8541 11.1004 12.0059 10.7904C14.2113 10.1504 16.9856 10.5804 18.6921 11.9604C19.6102 12.7004 20.0792 13.6304 19.9894 14.5904Z"
                          fill="#5D8B2F"
                        />
                      </svg>
                      {seeds.length} People Sharing Strains
                    </button>
                  </div>):''
                  }
                 
                  {seeds.length > 1 ? (
                    <GoogleMapNew
                      markersData={seeds.result}
                      activeMarker={activeMarker}
                      setActiveMarker={setActiveMarker}
                      handleActiveMarker={handleActiveMarker}
                      isMarkerShown
                      filteredLatAndLng={latAndLng}
                      isForConsumer={true}
                    />
                  ) : (
                    <div className="d-flex justify-content-center w-100">
                      <EmptyDataImage />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div
        className="modal fade"
        id="deactivatemodal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog custom-model w-max-content modal-dialog-centered mx-auto filter-model">
          <div className="modal-content justify-content-center p-4">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font-32 font-weight-800 text-center">
                Filter your search
              </p>
              <span className="cr-p" data-bs-dismiss="modal">
                <CrossBorderIcon />
              </span>
            </div>
            <form>
              <div className="d-flex flex-column align-items-start justify-content-center mb-5 mt-4 pt-2">
                <div className="p-0 bg-transparent border-0 mb-4 w-100">
                  {/* <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP}
                    libraries={libraries}
                  >
                    <StandaloneSearchBox
                      onLoad={(ref) => (inputRef1.current = ref)}
                      onPlacesChanged={handlePlaceChanged}
                    >
                      <div className="form-control h-auto p-0 bg-transparent border-0">
                        <label className="mb-2 font-weight-600 font-18-100">
                          Search an area
                        </label>
                        <input
                          onChange={(e) =>
                            setFilter((prevState) => ({
                              ...prevState,
                              area: e.target.value,
                            }))
                          }
                          value={filter.area}
                          type="text"
                          className="auth-input bg-white height-42"
                          placeholder="Enter here..."
                          name="area"
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </StandaloneSearchBox>
                  </LoadScript> */}
                  <AddressInput
                    setAddressProp={(address) =>
                      setFilter((prevState) => ({
                        ...prevState,
                        area: address,
                      }))
                    }
                    setLatAndLng={setLatAndLng}
                    inputLabel="Search an area"
                    className="height-42"
                    addressValue={filter.area}
                    isDark={false}
                  />
                </div>
                <div className="d-flex flex-column align-items-start justify-content-center w-100 gap-2 mb-4">
                  <label className="font-weight-600 font-18-100 d-flex justify-content-between w-100">
                    <span className="text-dark">Distance(km)</span>
                    <span className="text-dark">
                      {minMax.minValue}-{minMax.maxValue}
                    </span>
                  </label>
                  <MultiRangeSlider
                    className="shadow-none border-0 py-0 px-2 py-2 w-100"
                    min={0}
                    max={250}
                    minValue={minMax.minValue}
                    maxValue={minMax.maxValue}
                    ruler={false}
                    label={false}
                    barLeftColor="white"
                    barInnerColor="#5D8B2F"
                    barRightColor="white"
                    thumbLeftColor="white"
                    thumbRightColor="white"
                    onInput={handleInput}
                  />
                </div>
                <div className="w-100">
                  <div
                    className="btn-group btn-group-toggle height-42 mb-4 btn-group-dash"
                    data-toggle="buttons"
                  >
                    <label className="btn font-14 bg-grey active d-flex align-items-center py-3 px-sm-3 px-2">
                      <input
                        type="radio"
                        name="options"
                        id="Grams"
                        autoComplete="off"
                        readOnly
                        checked={type === "Grams"}
                        onChange={handleChange}
                        value="Grams"
                      />
                      <span className="pl-2">Grams</span>
                    </label>
                    <label className="btn font-14 bg-grey d-flex align-items-center px-sm-3 px-2">
                      <input
                        type="radio"
                        name="options"
                        id="Seeds"
                        value="Seeds"
                        autoComplete="off"
                        checked={type === "Seeds"}
                        onChange={handleChange}
                      />
                      <span className="pl-2">Seeds</span>
                    </label>
                    <label className="btn font-14 bg-grey d-flex align-items-center">
                      <input
                        type="radio"
                        name="options"
                        id="All"
                        value="All"
                        autoComplete="off"
                        checked={type === "All"}
                        onChange={handleChange}
                      />
                      <span className="pl-2">All</span>
                    </label>
                  </div>
                  {customType === "CustomSelect" && type !== "All" && (
                    <div className="form-control h-auto p-0 bg-transparent border-0 w-100">
                      <label className="mb-2 font-weight-600 font-18-100">
                        Search by Quantity
                      </label>
                      <select
                        className="auth-input bg-white height-42 w-100"
                        required
                        name="quantity"
                        onChange={(e) => formHandler(e)}
                      >
                        <option value={""}>- All -</option>
                        <option value={type === "Seeds" ? "1-4" : "1-7"}>
                          {type === "Seeds" ? "1-4 Seeds" : "1-7 Grams"}
                        </option>
                        <option value={type === "Seeds" ? "5-10" : "7-14"}>
                          {type === "Seeds" ? "5-10 Seeds" : "7-14 Grams"}
                        </option>
                        <option value={type === "Seeds" ? "11-15" : "14-30"}>
                          {type === "Seeds" ? "11-15 Seeds" : "14-30 Grams"}
                        </option>
                        {type === "Seeds" && (
                          <option value={"16-20"}>{"16-20 Seeds"}</option>
                        )}
                      </select>
                    </div>
                  )}
                  {customType === "CustomAny" && (
                    <div className="form-control h-auto p-0 bg-transparent border-0">
                      <input
                        type="text"
                        name="quantity"
                        placeholder={
                          type === "Seeds" ? "1-7 Seeds" : "1-7 Grams"
                        }
                        className="auth-input height-42 bg-white"
                        onChange={(e) => formHandler(e)}
                      />
                      <p className="text-danger mt-2">
                        Value must be in range format E.g 1-7, 14-20 etc
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center w-100 mt-4 flex-wrap">
                <button
                  className="light-red-btn-outline text-white custom-w min-width-208 height-42"
                  onClick={clearFilterHandler}
                  data-bs-dismiss="modal"
                >
                  Clear Filter
                </button>
                <button
                  className="green-btn-outline text-primary-green custom-w min-width-208 height-42"
                  data-bs-dismiss="modal"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="green-btn custom-w min-width-208 height-42"
                  onClick={submitHandler}
                  data-bs-dismiss="modal"
                >
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeedMap;
