import React from 'react'

const MobMapIcon = () => {
    return (
        <>
            <svg width={22} height={27} viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.0421 26.3859C15.5978 26.3859 21.7944 17.2031 21.7944 11.3347C21.7944 5.46626 16.9804 0.708984 11.0421 0.708984C5.10388 0.708984 0.289978 5.46626 0.289978 11.3347C0.289978 17.2031 6.48657 26.3859 11.0421 26.3859ZM11.0422 14.299C13.4643 14.299 15.4278 12.3586 15.4278 9.96496C15.4278 7.57137 13.4643 5.63099 11.0422 5.63099C8.62011 5.63099 6.65662 7.57137 6.65662 9.96496C6.65662 12.3586 8.62011 14.299 11.0422 14.299Z" fill="#5D8B2F" />
            </svg>
        </>
    )
}

export default MobMapIcon