import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Axios from '../../axios/Axios';
import { useNavigate, Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import CardPaymentForm from '../../Components/Social App/CardPaymentForm';
import { loadStripe } from '@stripe/stripe-js';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import { createSubscription } from '../../Api';
import ReactCardFlip from 'react-card-flip';
import CrossBorderIcon from '../../assets/Images/CrossBorder';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const AuthSubscription = () => {
  const [flip, setFlip] = useState(false);
  const [planDescription, setplanDescription] = useState([]);
  const [modalCheck, setModalCheck] = useState(false);

  const navigate = useNavigate();

  const submitHandler = e => {
    e.preventDefault();
    navigate('/');
  };

  const [type, setType] = useState('');
  const [substype, setsubsType] = useState([]);
  const [allPlans, setAllPlans] = useState([]);
  const [currentuserData, setcurrentuserData] = useState('');
  const [termsAndPrivacyCheck, settermsAndPrivacyCheck] = useState(false);
  const [freeModalCheck, setFreeModalCheck] = useState(true);
  const [isSelectAlreadySubscribed, setIsSelectAlreadySubscribed] = useState(false);

  const route = '/';
  useEffect(() => {
    const currentUser = localStorage.getItem('userdata');
    let data = JSON.parse(currentUser);
    setcurrentuserData(data);
    let GetUserUrl = `${process.env.REACT_APP_API_URI}users/${data?._id}`;
    GetUser(GetUserUrl);
    Axios.get(`${process.env.REACT_APP_API_URI}subscription/user-subscription/${data?._id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('user-token')}`
      }
    })
      .then(response => {
        setsubsType(response.data.userSubscriptions);
      })
      .catch(error => {
        console.log(error?.response?.data);
      });

    const url =
      data.userType === 'retailer'
        ? `${process.env.REACT_APP_API_URI}plan/${data.retailerType}`
        : `${process.env.REACT_APP_API_URI}plan/`;
    Axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('user-token')}`
      }
    })
      .then(response => {
        // setAllPlans(response.data.plans);
        const filteredPlans = response.data.plans.filter(
          plan => plan.type === 'grow' || plan.type === 'combine' || plan.type === 'retailer'
        );
        const sorting = filteredPlans.sort((a, b) => a.price - b.price);
        setType(sorting[0]._id);
        setAllPlans(sorting);
        setplanDescription(sorting[0]);
      })
      .catch(error => {
        console.log(error.response.data);
      });
  }, []);

  const GetUser = async GetUserUrl => {
    try {
      const fetchData = await Axios.get(GetUserUrl);
      localStorage.setItem('userdata', JSON.stringify(fetchData?.data?.data?.doc));
      setcurrentuserData(fetchData?.data?.data?.doc);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
  };

  useEffect(() => {
    const planIds = substype && substype?.map(obj => obj.planId);
    const filteredPlans = allPlans.filter(plan => !planIds?.includes(plan._id));
    setAllPlans(filteredPlans);
  }, [substype]);

  const handleChange = (event, plan) => {
    if (plan?.free) {
      setFlip(false);
    }
    if (!plan?.free) {
      setFlip(true);
    }
    setplanDescription(plan);
    const planIds = substype?.map(obj => obj.planId);
    const includesMatchingPlanId = planIds?.includes(event.target.value);
    if (!includesMatchingPlanId) {
      if (plan?.free) {
        setModalCheck(false);
        setFreeModalCheck(true);
      } else {
        setModalCheck(true);
        setFreeModalCheck(false);
        // setType(event.target.value);
      }
    } else {
      setModalCheck(false);
      setIsSelectAlreadySubscribed(true);
    }
  };
  const sliderRef = useRef(null);

  const [modalShow, setModalShow] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    onSwipe: direction => {
      console.log('Swiped:', direction);
    },
    beforeChange: (current, next) => {
      setplanDescription(allPlans[next]);
      if (allPlans[next]?.free) {
        setFlip(false);
      }
      if (!allPlans[next]?.free) {
        setFlip(true);
      }
      const planIds = substype?.map(obj => obj.planId);
      const includesMatchingPlanId = planIds?.includes(allPlans[next]._id);
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Add smooth scrolling behavior
      });
      if (!includesMatchingPlanId) {
        if (allPlans[next]?.free) {
          setType(allPlans[next]._id);
          setModalCheck(false);
          setFreeModalCheck(true);
        } else {
          setModalCheck(true);
          setFreeModalCheck(false);
          setType(allPlans[next]._id);
        }
      } else {
        setModalCheck(false);
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = event => {
      event.preventDefault();
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const planIds = substype?.map(obj => obj.planId);
    const includesMatchingPlanId = planIds?.includes(type);
    if (includesMatchingPlanId) {
      setIsSelectAlreadySubscribed(true);
    } else {
      setIsSelectAlreadySubscribed(false);
    }
  }, [type, substype]);

  const handleFreeSubscription = () => {
    const route = '/';
    const data = {
      planId: type,
      userId: currentuserData._id
    };
    const planIds = substype?.map(obj => obj.planId);
    const includesMatchingPlanId = planIds?.includes(type);
    if (!includesMatchingPlanId) {
      createSubscription(data, route, navigate, setModalShow, setsubsType);
    }
  };
  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="rounded-10 subscription-modal payment-modal"
        backdrop="static"
      >
        <Modal.Header className="px-4 justify-content-end">
          <button type="button" className="outline-0 border-0 bg-transparent" onClick={() => setModalShow(false)}>
            <CrossBorderIcon />
          </button>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Modal.Title id="contained-modal-title-vcenter" className="font-weight-bold mb-3">
            Payment Method
          </Modal.Title>
          <div className="auth-input h-auto bg-white text-dark mb-3 p-3">
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between text-grey gap-3 align-items-center border-bottom border-grey pb-3 flex-wrap">
                <span className="font-16-social">Subscription Type</span>
                <h4 className="font-18-social">{planDescription.name}</h4>
              </div>
              <div className="d-flex justify-content-between text-grey gap-3 align-items-center pt-3">
                <span className="font-16-social">Total</span>
                <h4 className="font-18-social">${planDescription.price}</h4>
              </div>
            </div>
          </div>
          <Elements stripe={stripePromise}>
            <CardPaymentForm type={type} setModalShow={setModalShow} route={route} setsubsType={setsubsType} />
          </Elements>
        </Modal.Body>
      </Modal>
      <div className="max-width-521 min-width-521">
        {/* <h2 className="auth-model-heading mb-4">Welcome Back!</h2>
        <p className="auth-model-desc mb-5">Please login to your account.</p> */}

        <form onSubmit={e => submitHandler(e)} className="px-4 mt-4">
          <div className="subscription-scroll">
            <ReactCardFlip isFlipped={flip} flipDirection="vertical">
              <div className="self-summary  border-0 rounded-4 p-4 d-flex flex-column mb-4">
                <p className="font-weight-700 text-white">Grow and Shares</p>
                <div className="auth-subscription mt-0 overflow-hidden">
                  <ul>
                    {(planDescription.description || []).map((data, index) => {
                      return (
                        <li key={index}>
                          <h4 className="font-16-social text-grey font-weight-400 line-height-20px">{data}</h4>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="auth-input h-auto bg-transparent text-dark mb-3 p-3">
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-between text-grey gap-3 align-items-center border-bottom border-grey pb-3 flex-wrap">
                        <span className="font-16-social">Subscription Type</span>
                        <h4 className="font-18-social">{planDescription.name}</h4>
                      </div>
                      <div className="d-flex justify-content-between text-grey gap-3 align-items-center pt-3">
                        <span className="font-16-social">Total</span>
                        <h4 className="font-18-social">${planDescription.price}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-summary  border-0 rounded-4 p-4 d-flex flex-column mb-4">
                <p className="font-weight-700 text-white">Plus Subscription Benefits: Grow and Share</p>
                <div className="auth-subscription mt-0 overflow-hidden">
                  <ul>
                    {(planDescription.description || []).map((data, index) => {
                      return (
                        <li key={index}>
                          <h4 className="font-16-social text-grey font-weight-400 line-height-20px">{data}</h4>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="auth-input h-auto bg-transparent text-dark mb-3 p-3">
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-between text-grey gap-3 align-items-center border-bottom border-grey pb-3 flex-wrap">
                        <span className="font-16-social">Subscription Type</span>
                        <h4 className="font-18-social">{planDescription.name}</h4>
                      </div>
                      <div className="d-flex justify-content-between text-grey gap-3 align-items-center pt-3">
                        <span className="font-16-social">Total</span>
                        <h4 className="font-18-social">${planDescription.price}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ReactCardFlip>
          </div>
          {!isSelectAlreadySubscribed && (
            <>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="head-checkbox"
                  onChange={() => settermsAndPrivacyCheck(!termsAndPrivacyCheck)}
                />
                <label htmlFor="head-checkbox">
                  {/* <span className="ps-2 font-16-100 font-weight-600">
                I have read and understand the terms and conditions as well as
                the privacy policy.
              </span> */}
                  <span className="ps-2 font-14 text-grey font-weight-500">
                    I have read and understand the
                    <span className="text-primary-green font-weight-700 px-1">
                      <Link className="text-primary-green font-weight-700" to="/terms">
                        terms and conditions
                      </Link>
                    </span>
                    as well as the
                    <span className="text-primary-green font-weight-700">
                      <Link className="text-primary-green font-weight-700" to="/privacypolicy">
                        {' '}
                        Privacy policy.
                      </Link>
                    </span>
                  </span>
                </label>
              </div>
              {modalCheck && termsAndPrivacyCheck && (
                <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-4 pt-3">
                  <button className="green-btn w-max-content px-5" onClick={() => setModalShow(true)} type="button">
                    Subscribe Now
                  </button>
                </div>
              )}
              {freeModalCheck && termsAndPrivacyCheck && (
                <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-4 pt-3">
                  <button
                    className="green-btn w-max-content px-5"
                    onClick={() => handleFreeSubscription()}
                    type="button"
                  >
                    Subscribe Now
                  </button>
                </div>
              )}
            </>
          )}
          {isSelectAlreadySubscribed && (
            <p className="font-14 font-weight-400 text-light-grey mt-3 text-center">
              You have already subscribed to this plan
            </p>
          )}

          <div className="btn-group btn-group-toggle my-4 flex-column gap-4 w-100" data-toggle="buttons">
            <Slider ref={sliderRef} {...settings}>
              {allPlans.length > 0 &&
                allPlans.map((plan, index) => {
                  if (plan.type === 'grow' || plan.type === 'combine' || plan.type === 'retailer') {
                    const planIds = (substype || [])?.map(obj => obj.planId);
                    return (
                      <label
                        key={index}
                        className={`shadow-none border-0 btn subscription-offer p-0 w-100 font-14 bg-grey d-flex align-items-center  ${
                          planIds.includes(plan._id) && 'active'
                        }`}
                      >
                        <input
                          type="radio"
                          name="options"
                          id={plan._id}
                          autoComplete="off"
                          readOnly
                          checked={type === plan._id || plan._id === substype?.planId}
                          onClick={e => handleChange(e, plan)}
                          value={plan._id}
                        />
                        <div
                          className={`subs p-4 w-100 d-flex justify-content-center align-items-center text-white position-relative ${
                            plan._id === substype?.planId ? 'active text-dark-black' : 'border-grey'
                          }`}
                        >
                          <div className="slider-arrow-btn start-10" onClick={() => sliderRef.current.slickPrev()}>
                            <i className="fa-solid fa-arrow-left"></i>
                          </div>
                          <div className="slider-arrow-btn end-10" onClick={() => sliderRef.current.slickNext()}>
                            <i className="fa-solid fa-arrow-right"></i>
                          </div>
                          <p className="font-32-social font-sm-24 font-weight-700 border-bottom border-grey pb-3 text-capitalize">
                            {`$${plan.price}${plan?.interval ? ` / ${plan?.interval}` : ''}`}
                          </p>
                          <div className="px-3 pt-3">
                            <p className="font-18-social font-weight-700">{plan.name}</p>
                            {/* <p className="font-16-social font-weight-400">
                              {`SAVE ${plan.discount}`}
                            </p> */}
                          </div>
                        </div>
                      </label>
                    );
                  }
                })}
            </Slider>
          </div>
        </form>

        {(currentuserData.isPremium || isSelectAlreadySubscribed) && (
          <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-4 pt-3">
            <button className="green-btn w-max-content px-5" onClick={submitHandler}>
              Next
            </button>
          </div>
        )}
        {(currentuserData.isPremium || isSelectAlreadySubscribed) && (
          <p className="font-14 font-weight-400 text-light-grey mt-3 text-center">
            You have already have any subscription can continue to using app
          </p>
        )}
      </div>
    </>
  );
};

export default AuthSubscription;
