import React from 'react'

const LocationIcon = () => {
    return (
        <>
            <svg width={16} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M15.7747 5.88293C14.8282 1.82634 11.1955 0 8.00451 0C8.00451 0 8.00451 0 7.99549 0C4.81351 0 1.17181 1.81756 0.225333 5.87415C-0.829317 10.4049 2.01914 14.242 4.59717 16.6566C5.55267 17.5522 6.77859 18 8.00451 18C9.23042 18 10.4563 17.5522 11.4028 16.6566C13.9809 14.242 16.8293 10.4137 15.7747 5.88293Z" fill="#5D8B2F" />
                <path d="M8.00546 10.2817C9.57364 10.2817 10.8449 9.04339 10.8449 7.51585C10.8449 5.98832 9.57364 4.75 8.00546 4.75C6.43728 4.75 5.16602 5.98832 5.16602 7.51585C5.16602 9.04339 6.43728 10.2817 8.00546 10.2817Z" fill="#5D8B2F" />
            </svg>
        </>
    )
}

export default LocationIcon