import React from 'react'

const MenuBarWhiteIcon = () => {
    return (
        <>
            <svg width={38} height={32} viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x={3} y={8} width={32} height={4} rx={2} fill="#ffffff" />
                <rect x={3} y={20} width={32} height={4} rx={2} fill="#ffffff" />
            </svg>

        </>
    )
}

export default MenuBarWhiteIcon