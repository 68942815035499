import React, { useState } from "react";
import Add1 from "../../assets/Images/match/Add1";
import UploadIcon from "../../assets/Images/Upload";

import { useEffect } from "react";
import { PatchGrowndepotform, PostGrowndepotform } from "../../Api";
import { ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Growndepot = ({
  addNewhandler,
  isUpdateModel,
  updateModelData,
  setIsUpdating,
  setApiResponse,
  setdataSet,
}) => {
  const [file, setFile] = useState(null);
  const [productForms, setProductForms] = useState([
    {
      productType: "",
      name: "",
      price: "",
      photo: null,
      description: "",
    },
  ]);
  const [isFormValid, setIsFormValid] = useState(false);
  // const [arrayData, setArrayData] = useState([]);
  // const [isFormValid, setIsFormValid] = useState(false);
  // const [growndepot, setGrowndepot] = useState({
  //   productType: "",
  //   name: "",
  //   price: "",
  //   description: "",
  //   photo: "",
  // });
  // const [id, setId] = useState("");
  useEffect(() => {
    if (!isUpdateModel) {
      const savedData = JSON.parse(localStorage.getItem("growndepotdata"));
      if (savedData?.length > 0 && Array.isArray(savedData)) {
        console.log(savedData);
        setProductForms(savedData);
      } else {
        setFile(updateModelData?.photo);
        setProductForms([
          {
            productType: "",
            name: "",
            price: "",
            photo: null,
            description: "",
          },
        ]);
      }
    } else {
      setProductForms([
        {
          productType: updateModelData?.productType,
          name: updateModelData?.name,
          price: updateModelData?.price,
          photo: Array.isArray(updateModelData?.photo)
            ? updateModelData?.photo[0]
            : updateModelData?.photo,
          description: updateModelData?.description,
        },
      ]);
    }
  }, [isUpdateModel]);

  useEffect(() => {
    localStorage.setItem("growndepotdata", JSON.stringify(productForms));
  }, [productForms]);

  useEffect(() => {
    const areAllFormsValid = productForms.every((form) => {
      const { productType, name, price, description } = form;
      return productType && name && price && description;
    });

    setIsFormValid(areAllFormsValid);
  }, [productForms]);

  const formHandler = (e, index) => {
    const { name, value } = e.target;
    const updatedProductForms = [...productForms];
    updatedProductForms[index] = {
      ...updatedProductForms[index],
      [name]: value,
    };
    setProductForms(updatedProductForms);
  };

  const attachFile = (e, index) => {
    if (e.target.files) {
      let imageFile = e.target.files[0];
      const updatedProductForms = [...productForms];
      updatedProductForms[index] = {
        ...updatedProductForms[index],
        photo: imageFile,
      };
      setProductForms(updatedProductForms);
    }
  };

  const clearForm = () => {
    setProductForms([
      ...productForms,
      { productType: "", name: "", price: "", photo: null, description: "" },
    ]);
    setFile(null);
  };
  const removeForm = (indexToRemove) => {
    const updatedProductForms = productForms.filter(
      (_, index) => index !== indexToRemove
    );
    setProductForms(updatedProductForms);
  };
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    const currentUser = localStorage.getItem("userdata");
    const parsedUser = JSON.parse(currentUser);
    const userId = parsedUser._id;
    let data = new FormData();
    productForms.forEach((mapData, index) => {
      data.append("userId", userId);
      data.append("productType", mapData.productType);
      data.append("name", mapData.name);
      data.append("price", mapData.price);
      data.append("description", mapData.description);
      if (Array.isArray(mapData.photo)) {
        mapData.photo.forEach((file) => data.append(`photo-${index}`, file));
      } else {
        data.append(`photo-${index}`, mapData.photo);
      }
    });
    if (isUpdateModel) {
      await PatchGrowndepotform(data, updateModelData._id);
    } else {
      await PostGrowndepotform(data);
    }
    clearForm();
    addNewhandler();
    setProductForms([
      { productType: "", name: "", price: "", photo: null, description: "" },
    ]);
    localStorage.removeItem("growndepotdata");
    if (typeof setdataSet === "function" && setdataSet !== undefined) {
      setdataSet(true);
    }
    navigate("/myaccount");
  };

  // useEffect(() => {
  //   const currentUser = localStorage.getItem("userdata");
  //   const parsedUser = JSON.parse(currentUser);
  //   setGrowndepot((prevState) => ({
  //     ...prevState,
  //     userId: parsedUser._id,
  //   }));
  //   setId(parsedUser._id);

  //   const savedData = JSON.parse(localStorage.getItem("growndepotdata"));
  //   if (savedData && Array.isArray(savedData)) {
  //     setArrayData(savedData);
  //   }
  // }, []);
  // useEffect(() => {
  //   const { productType, name, price, description } = growndepot;
  //   if (productType && name && price && description) {
  //     setIsFormValid(true);
  //   } else {
  //     setIsFormValid(false);
  //   }
  // }, [growndepot]);

  // useEffect(() => {
  //   localStorage.setItem("growndepotdata", JSON.stringify(arrayData));
  // }, [arrayData]);

  // const formHandler = (e) => {
  //   const { name, value } = e.target;
  //   setGrowndepot((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  // const attachFile = (e) => {
  //   if (e.target.files) {
  //     let imageFile = e.target.files[0];
  //     setGrowndepot((prevState) => ({
  //       ...prevState,
  //       photo: Array.from(e.target.files),
  //     }));
  //     setFile(imageFile?.name);
  //   }
  //   const files = Array.from(e.target.files);
  // };

  // const clearForm = () => {
  //   console.log("Clearing the form");
  //   setGrowndepot({
  //     productType: "",
  //     name: "",
  //     price: "",
  //     description: "",
  //     photo: "",
  //   });
  //   setFile(null);
  //   setIsFormValid(false);
  // };
  // const addMore = () => {
  //   setArrayData((prev) => [...prev, growndepot]);
  //   clearForm();
  // };
  // const submitHandler = async (e) => {
  //   e.preventDefault();
  //   const updatedArrayData = [...arrayData, growndepot];
  //   let data = new FormData();
  //   updatedArrayData.forEach((mapData, index) => {
  //     data.append("userId", id);
  //     data.append("productType", mapData.productType);
  //     data.append("name", mapData.name);
  //     data.append("price", mapData.price);
  //     data.append("description", mapData.description);
  //     if (Array.isArray(mapData.photo)) {
  //       mapData.photo.forEach((file) => data.append(`photo-${index}`, file));
  //     } else {
  //       data.append(`photo-${index}`, mapData.photo);
  //     }
  //   });
  //   await PostGrowndepotform(data);
  //   clearForm();
  //   addNewhandler();
  //   setArrayData([]);
  //   localStorage.removeItem("growndepotdata");
  // };

  return (
    <>
      <h3 className="font-32 font-weight-600 allproduct-heading mx-4">
        Post an Accessory
      </h3>
      <form onSubmit={(e) => submitHandler(e)} className="overflow-auto px-4">
        <div className="addMore-form">
          {productForms?.map((form, index) => (
            <div key={index} className="addMore-form-border pt-5">
              {index !== 0 && (
                <div className="d-flex justify-content-end align-items-center mb-2">
                  <button
                    type="button"
                    onClick={() => removeForm(index)}
                    className="red-btn-outlines px-4 w-max-content"
                  >
                    Remove Form
                  </button>
                </div>
              )}

              <div className="d-flex flex-md-row flex-column align-items-center gap-4 justify-content-between mb-3">
                <div className="form-control h-auto p-0 bg-transparent border-0">
                  <label className="text-black mb-2 font-weight-600 font-18-100">
                    Product Type
                  </label>
                  <input
                    type="text"
                    className="auth-inputs"
                    placeholder="Enter Product Type"
                    name="productType"
                    required
                    onChange={(e) => formHandler(e, index)}
                    value={form.productType}
                  />
                </div>
                <div className="form-control h-auto p-0 bg-transparent border-0">
                  <label className="text-black mb-2 font-weight-600 font-18-100">
                    Product Name
                  </label>
                  <input
                    type="text"
                    className="auth-inputs"
                    placeholder="Enter Product Name"
                    name="name"
                    required
                    onChange={(e) => formHandler(e, index)}
                    value={form.name}
                  />
                </div>
              </div>

              <div className="d-flex flex-md-row flex-column align-items-center gap-4 justify-content-between mb-4">
                <div className="form-control h-auto p-0 bg-transparent border-0">
                  <label className="text-black mb-2 font-weight-600 font-18-100">
                    Product Price
                  </label>
                  <input
                    name="price"
                    type="number"
                    className="auth-inputs"
                    required
                    placeholder="$ Enter Product Price"
                    onChange={(e) => formHandler(e, index)}
                    value={form.price}
                  />
                </div>
              </div>

              <div className="form-control h-auto p-0 bg-transparent border-0 mb-1">
                <label className="text-Black mb-1 font-weight-600 font-18-100">
                  Product Description
                </label>
                <textarea
                  onChange={(e) => formHandler(e, index)}
                  className="auth-inputs-textarea"
                  placeholder="Enter Product description here..."
                  required
                  name="description"
                  value={form.description}
                />
              </div>

              <label className="text-black mb-2 font-weight-600 font-18-100">
                Upload Images
              </label>
              <div className="d-flex flex-md-row flex-column align-items-center gap-4 justify-content-between mb-4">
                <label className="upload-files cr-p">
                  <input
                    type="file"
                    className="d-none"
                    accept=".jpg, .jpeg, .png"
                    onChange={(e) => attachFile(e, index)}
                  />
                  <div className="d-flex justify-content-center align-items-center h-100 w-100 gap-2">
                    <UploadIcon />
                    <p className="font-16 font-weight-500">
                      {form.photo === null || form.photo === ""
                        ? "Choose File / Drag & Drop Here"
                        : form.photo.name
                        ? form.photo.name
                        : form.photo}
                    </p>
                  </div>
                </label>
              </div>
            </div>
          ))}
        </div>
        {!isUpdateModel && (
          <div className="d-flex flex-md-row flex-column align-items-center gap-4 justify-content-between mb-4">
            {productForms?.length < 10 ? (
              <button
                className="add-mores bg-transparent border-white text-black gap-2"
                type="button"
                onClick={() => clearForm()}
                disabled={productForms?.length >= 10}
              >
                <Add1 />
                Add More Accessories
              </button>
            ) : (
              <ButtonToolbar
                className="add-mores bg-transparent border-white text-black p-0"
                type="button"
              >
                <OverlayTrigger placement="top" overlay={tooltip}>
                  <div className="d-flex align-items-center gap-2 w-100 h-100 justify-content-center">
                    <Add1 />
                    Add More Accessories
                  </div>
                </OverlayTrigger>
              </ButtonToolbar>
            )}
          </div>
        )}
        <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-end  mt-3 px-4">
          <button
            className="green-btn-outlines custom-w min-width-208"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => {
              setProductForms([
                {
                  productType: "",
                  name: "",
                  price: "",
                  photo: null,
                  description: "",
                },
              ]);
              if (setIsUpdating) setIsUpdating(false);
            }}
          >
            Cancel
          </button>
          <button
            className={`green-btn custom-w min-width-208 height-56-important ${
              isFormValid ? "enabled-button" : "disabled-button"
            }`}
            type="submit"
            data-bs-dismiss="modal"
            disabled={!isFormValid}
          >
            {isUpdateModel ? "Update" : "Post"}
          </button>
        </div>
      </form>
    </>
  );
};

export default Growndepot;
const tooltip = (
  <Tooltip id="tooltip">You can't add more than 10 products at a time.</Tooltip>
);
