import React from "react";

const LikeIcon = () => {
  return (
    <>
      <svg
        width={22}
        height={21}
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.15723 9.75585L1.15723 17.8573M5.20796 9.27833V16.8653C5.20796 18.1437 6.24429 19.18 7.52266 19.18H16.6544C17.7422 19.18 18.6832 18.4226 18.9156 17.3599L20.5249 10.0032C20.6828 9.2814 20.1332 8.59849 19.3943 8.59849H15.0455C13.7671 8.59849 12.7307 7.56217 12.7307 6.28379V4.68352C12.7307 3.48503 12.2547 2.33564 11.4072 1.48818C10.8977 0.978637 10.0477 1.06995 9.65806 1.67611L5.57558 8.02663C5.33557 8.39999 5.20796 8.83448 5.20796 9.27833Z"
          stroke="#2D2B2B"
          strokeWidth="2.3147"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
};

export default LikeIcon;
