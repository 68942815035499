import React from 'react'

const StrainAvailableIcon = () => {
    return (
        <>
            <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M15.5145 2.13388L15.0147 1.84543L14.5149 2.13388C13.5003 2.7194 12.6525 3.52221 12.0594 4.48205C11.4655 5.44327 11.1484 6.52816 11.1484 7.6388C11.1484 8.74945 11.4655 9.83434 12.0594 10.7956C12.6525 11.7554 13.5003 12.5582 14.5149 13.1437L15.0147 13.4322L15.5145 13.1437C16.5291 12.5582 17.3769 11.7554 17.97 10.7956C18.5639 9.83434 18.881 8.74945 18.881 7.6388C18.881 6.52816 18.5639 5.44327 17.97 4.48205C17.3769 3.52221 16.5291 2.7194 15.5145 2.13388Z" stroke="white" strokeWidth={2} />
                <path d="M12.6624 14.5455L13.3131 14.2681L13.2683 13.5622C13.1976 12.449 12.805 11.3803 12.1414 10.4545C11.4789 9.53031 10.5698 8.78021 9.5091 8.26096C8.45574 7.74225 7.27993 7.45801 6.08303 7.42673C4.88566 7.39543 3.69339 7.61798 2.6083 8.07993L1.9424 8.36341L2.00358 9.08454C2.18715 11.2485 3.53952 13.2623 5.75313 14.3607C7.96034 15.4823 10.5566 15.4432 12.6624 14.5455Z" stroke="white" strokeWidth={2} />
                <path opacity="0.4" d="M9.54374 21.7355L9.54373 21.7355C7.31016 22.8548 4.74513 22.8194 2.6352 21.92L1.98153 21.6413L2.02964 20.9324C2.17869 18.736 3.58613 16.7565 5.77549 15.6419L9.54374 21.7355ZM9.54374 21.7355L9.54939 21.7326M9.54374 21.7355L9.54939 21.7326M9.54939 21.7326C11.7388 20.6181 13.1463 18.6386 13.2953 16.4422L13.3428 15.7426M9.54939 21.7326L13.3428 15.7426M13.3428 15.7426L12.7013 15.4596M13.3428 15.7426L12.7013 15.4596M12.7013 15.4596C11.6169 14.9811 10.4182 14.7497 9.21391 14.7812M12.7013 15.4596L9.21391 14.7812M9.21391 14.7812C8.00968 14.8126 6.82757 15.1062 5.77559 15.6419L9.21391 14.7812Z" stroke="white" strokeWidth={2} />
                <path d="M18.8815 22.3733L18.8815 22.3726C18.8793 21.2643 18.5611 20.1822 17.9671 19.2237C17.374 18.2665 16.5272 17.4662 15.5143 16.8826L15.016 16.5955L14.5172 16.8817C13.5025 17.4642 12.6541 18.2645 12.0604 19.2225C11.4659 20.1818 11.1484 21.2653 11.1484 22.3746C11.1484 23.484 11.4659 24.5674 12.0604 25.5267C12.6541 26.4847 13.5025 27.285 14.5172 27.8675L15.0145 28.1529L15.512 27.868C16.5279 27.2862 17.3772 26.4859 17.9712 25.5274C18.566 24.5676 18.883 23.4833 18.8815 22.3733Z" stroke="white" strokeWidth={2} />
                <path opacity="0.4" d="M17.3383 15.4539L16.6876 15.7313L16.7325 16.4372C16.8032 17.5504 17.1958 18.6191 17.8594 19.5449C18.5206 20.4674 19.4276 21.2164 20.4858 21.7356C22.6925 22.856 25.2877 22.8167 27.3929 21.9193L28.0436 21.6419L27.9988 20.936C27.9281 19.8228 27.5355 18.7541 26.8718 17.8283L26.0591 18.4109L26.8718 17.8283C26.2106 16.9058 25.3036 16.1568 24.2455 15.6376C22.0387 14.5172 19.4435 14.5565 17.3383 15.4539Z" stroke="white" strokeWidth={2} />
                <path d="M20.4844 8.26434L20.4844 8.26436C22.7179 7.1451 25.283 7.18046 27.3929 8.07989L28.0466 8.35854L27.9985 9.0675C27.8494 11.2639 26.442 13.2434 24.2526 14.3579L20.4844 8.26434ZM20.4844 8.26434L20.4798 8.26666M20.4844 8.26434L20.4798 8.26666M20.4798 8.26666C19.4344 8.79722 18.5388 9.54826 17.881 10.4659C17.2224 11.3847 16.824 12.441 16.7338 13.5439L16.6758 14.253M20.4798 8.26666L16.6758 14.253M16.6758 14.253L17.3268 14.5403M16.6758 14.253L17.3268 14.5403M17.3268 14.5403C18.4112 15.0188 19.6099 15.2502 20.8142 15.2187M17.3268 14.5403L20.8142 15.2187M20.8142 15.2187C22.0184 15.1872 23.2005 14.8936 24.2525 14.358L20.8142 15.2187Z" stroke="white" strokeWidth={2} />
            </svg>

        </>
    )
}

export default StrainAvailableIcon