import React from 'react'

const FooterLocationIcon = () => {
    return (
        <>
            <svg width={14} height={16} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M13.8028 5.22927C12.9747 1.62341 9.79606 0 7.00394 0C7.00394 0 7.00394 0 6.99606 0C4.21182 0 1.02534 1.61561 0.197166 5.22146C-0.725653 9.24878 1.76675 12.6595 4.02253 14.8059C4.85859 15.6019 5.93126 16 7.00394 16C8.07662 16 9.1493 15.6019 9.97747 14.8059C12.2333 12.6595 14.7257 9.25659 13.8028 5.22927Z" fill="white" />
                <path d="M7.00404 9.13973C8.3762 9.13973 9.48856 8.03901 9.48856 6.68119C9.48856 5.32338 8.3762 4.22266 7.00404 4.22266C5.63189 4.22266 4.51953 5.32338 4.51953 6.68119C4.51953 8.03901 5.63189 9.13973 7.00404 9.13973Z" fill="white" />
            </svg>
        </>
    )
}

export default FooterLocationIcon