import React, { useEffect, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import Axios from "../../axios/Axios";
import { useNavigate, Link } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import CardPaymentForm from "../../Components/Social App/CardPaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import FurthurCardPaymentForm from "../../Components/Social App/FurthurPlansCardPaymentForm ";
import { toast } from "react-toastify";
import { createSubscription } from "../../Api";
import ReactCardFlip from "react-card-flip";
import CrossBorderIcon from "../../assets/Images/CrossBorder";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const DashboardSubscription = () => {
  const navigate = useNavigate();
  const [flip, setFlip] = useState(false);
  const [planDescription, setplanDescription] = useState([]);
  const [modalCheck, setModalCheck] = useState(false);
  const [termsAndPrivacyCheck, settermsAndPrivacyCheck] = useState(false);
  const [freeModalCheck, setFreeModalCheck] = useState(true);

  const submitHandler = (e) => {
    e.preventDefault();
    navigate("");
  };

  const [type, setType] = useState("");
  const [substype, setsubsType] = useState("");
  const [messagePlanType, setMessagePlanType] = useState("");

  const [allPlans, setAllPlans] = useState([]);

  const [currentuserData, setcurrentuserData] = useState("");

  useEffect(() => {
    const currentUser = localStorage.getItem("userdata");
    let data = JSON.parse(currentUser);
    setcurrentuserData(data);
    let GetUserUrl = `${process.env.REACT_APP_API_URI}users/${data?._id}`;
    GetUser(GetUserUrl);
    Axios.get(
      `${process.env.REACT_APP_API_URI}subscription/user-subscription/${data?._id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user-token")}`,
        },
      }
    )
      .then((response) => {
        setsubsType(response.data.userSubscriptions);
      })
      .catch((error) => {
        console.log(error?.response?.data);
      });
    const url =
      data.userType === "retailer"
        ? `${process.env.REACT_APP_API_URI}plan/${data.retailerType}`
        : `${process.env.REACT_APP_API_URI}plan/`;
    Axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user-token")}`,
      },
    })
      .then((response) => {
        const filteredPlans = response.data.plans.filter(
          (plan) => plan.type === "grow" || plan.type === "combine"
        );
        const sorting = filteredPlans.sort((a, b) => a.price - b.price);
        setAllPlans(sorting);
        setplanDescription(sorting[0]);
        setType(sorting[0]._id);
      })
      .catch((error) => {
        console.log(error?.response?.data);
      });
  }, []);
  const GetUser = async (GetUserUrl) => {
    try {
      const fetchData = await Axios.get(GetUserUrl);
      localStorage.setItem(
        "userdata",
        JSON.stringify(fetchData?.data?.data?.doc)
      );
      setcurrentuserData(fetchData?.data?.data?.doc);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
  };
  const [messageModalShow, setMessageModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const route = "";

  const handleChange = (event, plan) => {
    if (plan?.free) {
      setFlip(false);
    }
    if (!plan?.free) {
      setFlip(true);
    }
    setplanDescription(plan);
    const planIds = substype?.map((obj) => obj.planId);
    const includesMatchingPlanId = planIds?.includes(event.target.value);
    if (!includesMatchingPlanId) {
      if (plan?.free) {
        // const route = "";
        // const data = {
        //   planId: event.target.value,
        //   userId: currentuserData._id,
        // };
        // createSubscription(data, route, navigate, setModalShow, setsubsType);
        setModalCheck(false);
        setFreeModalCheck(true);
      } else {
        setModalCheck(true);
        setFreeModalCheck(false);
        // setModalShow(true);
        setType(event.target.value);
      }
    } else {
      setModalCheck(false);
    }
  };

  useEffect(() => {
    const planIds = substype && substype?.map((obj) => obj.planId);
    const filteredPlans = allPlans.filter(
      (plan) => !planIds?.includes(plan._id)
    );
    setAllPlans(filteredPlans);
  }, [substype]);


  console.log(freeModalCheck);
  const handleFreeSubscription = () => {
    const route = "";
    const data = {
      planId: type,
      userId: currentuserData._id,
    };
    const planIds = substype?.map((obj) => obj.planId);
    const includesMatchingPlanId = planIds?.includes(type);
    if (!includesMatchingPlanId) {
      createSubscription(data, route, navigate, setModalShow, setsubsType);
    }
  };
  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="rounded-10 subscription-modal payment-modal"
        backdrop="static"
      >
        <Modal.Header className="px-4 justify-content-end">
          <button
            type="button"
            className="outline-0 border-0 bg-transparent"
            onClick={() => setModalShow(false)}
          >
            <CrossBorderIcon />
          </button>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="font-weight-bold mb-3"
          >
            Payment Method
          </Modal.Title>
          <div className="auth-input h-auto bg-white text-dark mb-3 p-3">
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between text-dark gap-3 align-items-center border-bottom border-grey pb-3 flex-wrap">
                <span className="font-16-social">Subscription Type</span>
                <h4 className="font-18-social">{planDescription.name}</h4>
              </div>
              <div className="d-flex justify-content-between text-dark gap-3 align-items-center pt-3">
                <span className="font-16-social">Total</span>
                <h4 className="font-18-social">${planDescription.price}</h4>
              </div>
            </div>
          </div>
          <h3 className="font-weight-bold mb-2 mt-3 font-18">
            Payment Details
          </h3>
          <Elements stripe={stripePromise}>
            <CardPaymentForm
              type={type}
              setModalShow={setModalShow}
              route={route}
              setsubsType={setsubsType}
            />
          </Elements>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        show={messageModalShow}
        onHide={() => setMessageModalShow(false)}
        className="rounded-10 subscription-modal"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="font-weight-bold"
          >
            Payment Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Elements stripe={stripePromise}>
            <FurthurCardPaymentForm
              type={messagePlanType}
              setModalShow={setMessageModalShow}
              route={route}
            />
          </Elements>
        </Modal.Body>
        <Modal.Footer className="border-0 mt-3">
          <Button
            onClick={() => setMessageModalShow(false)}
            className="green-btn-outline text-primary-green w-max-content px-3 close-btn"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="all-product-section dashboard-subs">
        <div className="container mx-auto">
          <h2 className="allproduct-heading mx-4">Recurring billing</h2>
          {/* <p className="font-18 text-dark-black mt-4 pt-2 px-4">
            Please strictly follow the community rules listed below. If you
            become aware of content with child sexual abuse imagery or human sex
            trafficking, please report it to us and to the appropriate
            authorities! Please strictly follow the community rules listed
            below. If you become aware of content with child sexual abuse
            imagery or human sex trafficking, please report it to us and to the
            appropriate authorities!
          </p> */}
          <form onSubmit={(e) => submitHandler(e)} className="ms-12 me-12 mt-5">
            <div className="row m-0 gap-md-0 gap-4">
              <div className="col-md-6">
                <h3 className="font-weight-700 font-24 mb-4">
                  Subscription benefits:
                </h3>
              </div>
              <div className="col-md-6 d-md-block d-none">
                <h3 className="font-weight-700 font-24 mb-4">Payment Plans:</h3>
              </div>
            </div>
            <div className="row m-0 gap-md-0 gap-4">
              <div className="col-md-6  rounded-4 p-4 d-flex flex-column h-100 border border-1 border-black bg-dark-brown text-grey">
                <ReactCardFlip
                  isFlipped={flip}
                  flipDirection="vertical"
                  containerClassName="h-100"
                  cardStyles={{ height: "100%" }}
                >
                  <div className=" rounded-4 p-4 d-flex flex-column h-100">
                    <p className="font-weight-700 font-18-social">
                       Grow and Share
                    </p>
                    <div className="auth-subscription mt-0">
                      <ul>
                        {(planDescription.description || []).map(
                          (data, index) => {
                            return (
                              <li key={index}>
                                <h4 className="font-16-social text-grey font-weight-400 line-height-20px">
                                  {data}
                                </h4>
                              </li>
                            );
                          }
                        )}
                      </ul>
                      <div className="auth-input bg-dark h-auto mt-4 text-dark p-3">
                        <div className="d-flex flex-column">
                          <div className="d-flex justify-content-between text-grey gap-3 align-items-center border-bottom border-grey pb-3 flex-wrap">
                            <span className="font-16-social">
                              Subscription Type
                            </span>
                            <h4 className="font-18-social">
                              {planDescription.name}
                            </h4>
                          </div>
                          <div className="d-flex justify-content-between text-grey gap-3 align-items-center pt-3">
                            <span className="font-16-social">Total</span>
                            <h4 className="font-18-social">
                              ${planDescription.price}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-4 p-4 d-flex flex-column h-100">
                    <p className="font-weight-700 font-18-social">
                      Plus Subscription Benefits: Grow and Share
                    </p>
                    <div className="auth-subscription mt-0">
                      <ul>
                        {(planDescription.description || []).map(
                          (data, index) => {
                            return (
                              <li key={index}>
                                <h4 className="font-16-social text-grey font-weight-400 line-height-20px">
                                  {data}
                                </h4>
                              </li>
                            );
                          }
                        )}
                      </ul>
                      <div className="auth-input bg-dark h-auto mt-4 text-dark p-3">
                        <div className="d-flex flex-column">
                          <div className="d-flex justify-content-between text-grey gap-3 align-items-center border-bottom border-grey pb-3 flex-wrap">
                            <span className="font-16-social">
                              Subscription Type
                            </span>
                            <h4 className="font-18-social">
                              {planDescription.name}
                            </h4>
                          </div>
                          <div className="d-flex justify-content-between text-grey gap-3 align-items-center pt-3">
                            <span className="font-16-social">Total</span>
                            <h4 className="font-18-social">
                              ${planDescription.price}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ReactCardFlip>
              </div>
              <div className="col-md-6">
                <div className="d-md-none d-block">
                  <h3 className="font-weight-700 font-24 mb-4">
                    Payment Plans:
                  </h3>
                </div>
                <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                  <Tab eventKey={1} title="Monthly Plan">
                    <div
                      className="btn-group btn-group-toggle c flex-column gap-4 w-100 mt-3"
                      data-toggle="buttons"
                    >
                      {allPlans?.length > 0 &&
                        allPlans?.map((plan, index) => {
                          if ((plan.type === "grow" || plan.type === "combine") && plan.interval === 'month') {
                            const planIds = (substype || [])?.map(
                              (obj) => obj.planId
                            );  
                            return (
                              <>
                                <label
                                  key={index}
                                  className={`btn subscription-offer h-max-content p-0 w-100 font-14 bg-grey d-flex align-items-center border-2 ${planIds?.includes(plan._id) && "active"
                                    }`}
                                >
                                  <input
                                    type="radio"
                                    name="options"
                                    id={plan._id}
                                    autoComplete="off"
                                    readOnly
                                    checked={
                                      type === plan._id ||
                                      plan._id === substype?.planId
                                    }
                                    onClick={(e) => handleChange(e, plan)}
                                    value={plan._id}
                                  />
                                  <div className=" px-3 py-4 w-100 d-flex flex-sm-row flex-column justify-content-center align-items-center text-dark-black ">
                                    <p className="font-24-social line-height-100 font-weight-700 border-sm-right-bottom border-grey pr-sm-3 text-capitalize">
                                      {`$${plan.price}${plan?.interval ? ` / ${plan?.interval}` : ""
                                        }`}
                                    </p>
                                    <div className="px-3">
                                      <p className="font-18-social font-weight-700">
                                        {plan.name}
                                      </p>
                                    </div>
                                  </div>
                                </label>
                              </>
                            );
                          }
                        })}
                    </div>
                  </Tab>
                  <Tab eventKey={2} title="Yearly Plan">
                    <div
                      className="btn-group btn-group-toggle c flex-column gap-4 w-100 mt-3"
                      data-toggle="buttons"
                    >
                      {allPlans?.length > 0 &&
                        allPlans?.map((plan, index) => {
                          if ((plan.type === "grow" || plan.type === "combine") && plan.interval === 'year') {
                            const planIds = (substype || [])?.map(
                              (obj) => obj.planId
                            );
                            return (
                              <>
                                <label
                                  key={index}
                                  className={`btn subscription-offer h-max-content p-0 w-100 font-14 bg-grey d-flex align-items-center border-2 ${planIds.includes(plan._id) && "active"
                                    }`}
                                >
                                  <input
                                    type="radio"
                                    name="options"
                                    id={plan._id}
                                    autoComplete="off"
                                    readOnly
                                    checked={
                                      type === plan._id ||
                                      plan._id === substype?.planId
                                    }
                                    onClick={(e) => handleChange(e, plan)}
                                    value={plan._id}
                                  />
                                  <div className=" px-3 py-4 w-100 d-flex flex-sm-row flex-column justify-content-center align-items-center text-dark-black ">
                                    <p className="font-24-social line-height-100 font-weight-700 border-sm-right-bottom border-grey pr-sm-3 text-capitalize">
                                      {`$${plan.price}${plan?.interval ? ` / ${plan?.interval}` : ""
                                        }`}
                                    </p>
                                    <div className="px-3">
                                      <p className="font-18-social font-weight-700">
                                        {plan.name}
                                      </p>
                                    </div>
                                  </div>
                                </label>
                              </>
                            );
                          }
                        })}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
            <div className="checkbox-container ps-12 mt-4">
              <input
                type="checkbox"
                id="head-checkbox"
                onChange={() => settermsAndPrivacyCheck(!termsAndPrivacyCheck)}
              />
              <label htmlFor="head-checkbox">
                {/* <span className="ps-2 font-16-100 font-weight-600">
                  I have read and understand the terms and conditions as well as
                  the privacy policy.
                </span> */}
                <span className="ps-2 font-16-100 text-dark font-weight-500">
                  I have read and understand the
                  <span className="text-primary-green font-weight-700 px-1">
                    <Link
                      className="text-primary-green font-weight-700"
                      to="/terms"
                    >
                      terms and conditions
                    </Link>
                  </span>
                  as well as the
                  <span className="text-primary-green font-weight-700">
                    <Link
                      className="text-primary-green font-weight-700"
                      to="/privacypolicy"
                    >
                      {" "}
                      Privacy policy.
                    </Link>
                  </span>
                </span>
              </label>
            </div>
            {modalCheck && termsAndPrivacyCheck && (
              <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-4 pt-3">
                <button
                  className="green-btn w-max-content px-5"
                  onClick={() => setModalShow(true)}
                  type="button"
                >
                  Subscribe Now
                </button>
              </div>
            )}
            {freeModalCheck && termsAndPrivacyCheck && (
              <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-4 pt-3">
                <button
                  className="green-btn w-max-content px-5"
                  onClick={() => handleFreeSubscription()}
                  type="button"
                >
                  Subscribe Now
                </button>
              </div>
            )}
            {/* {modalCheck && (
              <div className="d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center  mt-4 pt-3">
                <button
                  className="green-btn w-max-content px-5 mt-5"
                  onClick={() => setModalShow(true)}
                  type="button"
                >
                  Subscribe Now
                </button>
              </div>
            )} */}

            {/* <div className="pt-4">
              <h3 className="font-weight-700 font-24 mb-4 ms-12 me-12 pt-5 pt-md-0">
                Additional Direct Message Plans:
              </h3>
              <div
                className="row m-0 btn-group btn-group-toggle gap-md-0 gap-4 h-100 w-100"
                data-toggle="buttons"
              >
                {messagesPlans?.length > 0 &&
                  messagesPlans?.map((plan, index) => {
                    if (plan.pricingType === "message") {
                      return (
                        <div className="col-lg-4 col-md-6 col-12 mb-md-4 mb-lg-0">
                          <label
                            key={index}
                            className={`btn subscription-offer p-0 w-100 font-14 bg-grey d-flex align-items-center border-2 ${
                              plan._id === substype?.planId && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              id={plan._id}
                              autoComplete="off"
                              readOnly
                              checked={
                                type === plan._id ||
                                plan._id === substype?.planId
                              }
                              onChange={handleMessagesPlansChange}
                              value={plan._id}
                            />
                            <div className=" p-4 w-100 d-flex justify-content-center align-items-center text-dark-black ">
                              <p className="font-32-social font-weight-700 border-right border-grey pr-3">
                                {`$${plan.price}`}
                              </p>
                              <div className="px-3">
                                <p className="font-18-social font-weight-700">
                                  {plan.details}
                                </p>
                              </div>
                            </div>{" "}
                          </label>
                        </div>
                      );
                    }
                  })}
              </div>
            </div> */}

            {/* <div className="pt-4">
              <h3 className="font-weight-700 font-24 mb-4 ms-12 me-12 ">
                Additional Boosted Profile:
              </h3>
              <div
                className="row m-0 btn-group btn-group-toggle gap-md-0 gap-4 h-100 w-100"
                data-toggle="buttons"
              >
                {messagesPlans?.length > 0 &&
                  messagesPlans?.map((plan, index) => {
                    if (plan.pricingType === "boost") {
                      return (
                        <div className="col-lg-4 col-md-6 col-12 mb-md-4 mb-lg-0">
                          <label
                            key={index}
                            className={`btn subscription-offer p-0 w-100 font-14 bg-grey d-flex align-items-center border-2 ${
                              plan._id === substype?.planId && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              id={plan._id}
                              autoComplete="off"
                              readOnly
                              checked={
                                type === plan._id ||
                                plan._id === substype?.planId
                              }
                              onChange={handleMessagesPlansChange}
                              value={plan._id}
                            />
                            <div className=" p-4 w-100 d-flex justify-content-center align-items-center text-dark-black ">
                              <p className="font-32-social font-weight-700 border-right border-grey pr-3">
                                {`$${plan.price}`}
                              </p>
                              <div className="px-3">
                                <p className="font-18-social font-weight-700">
                                  {plan.details}
                                </p>
                              </div>
                            </div>{" "}
                          </label>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
            <div className="pt-4">
              <h3 className="font-weight-700 font-24 mb-4 ms-12 me-12">
                Additional Rewind Profile Plans:
              </h3>
              <div
                className="row m-0 btn-group btn-group-toggle gap-md-0 gap-4 h-100 w-100"
                data-toggle="buttons"
              >
                {messagesPlans?.length > 0 &&
                  messagesPlans?.map((plan, index) => {
                    if (plan.pricingType === "rewinds") {
                      return (
                        <div className="col-lg-4 col-md-6 col-12 mb-md-4 mb-lg-0">
                          <label
                            key={index}
                            className={`btn subscription-offer p-0 w-100 font-14 bg-grey d-flex align-items-center border-2 ${
                              plan._id === substype?.planId && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              id={plan._id}
                              autoComplete="off"
                              readOnly
                              checked={
                                type === plan._id ||
                                plan._id === substype?.planId
                              }
                              onChange={handleMessagesPlansChange}
                              value={plan._id}
                            />
                            <div className=" p-4 w-100 d-flex justify-content-center align-items-center text-dark-black ">
                              <p className="font-32-social font-weight-700 border-right border-grey pr-3">
                                {`$${plan.price}`}
                              </p>
                              <div className="px-3">
                                <p className="font-18-social font-weight-700">
                                  {plan.details}
                                </p>
                              </div>
                            </div>{" "}
                          </label>
                        </div>
                      );
                    }
                  })}
              </div>
            </div> */}
          </form>
        </div>
      </div>
    </>
  );
};

export default DashboardSubscription;
